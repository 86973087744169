<aside id="sidebar" class="sidebar personalMenu">
    <ul class="sidebar-nav" id="sidebar-nav">


      <li class="nav-item" id="toc_li_organizations" data-level="1" (click)="marcarMenu('toc_cb_organizations')">
         <input id="toc_cb_organizations" class="toc" type="checkbox">
         <label for="toc_cb_organizations">
             <a class="nav-link " routerLink="/monitoring">
                  <i class="bi bi-cast"></i> 
                  <span i18n>{{ 'sidebar.monitoring' | translate }}</span> 
             </a>
         </label>
      </li>

  

      <li class="nav-item" >
         <a class="nav-link collapsed" data-bs-target="#Stadistic-nav" data-bs-toggle="collapse" href="#"> 
            <i class="bi bi-graph-up"></i><span i18n>{{ 'sidebar.statistic' | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Stadistic-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li (click)="marcarMenu('toc_cb_stad_func')"> 
                  <input id="toc_cb_stad_func" class="toc" type="checkbox">   
                  <label for="toc_cb_stad_func">
                     <a routerLink="/stadistic_funcional"> <i class="bi bi-bar-chart"></i> <span i18n>{{ 'sidebar.functionally' | translate }}</span> </a>
                  </label>
            </li>
            <li (click)="marcarMenu('toc_cb_stad_state')"> 
                  <input id="toc_cb_stad_state" class="toc" type="checkbox">   
                  <label for="toc_cb_stad_state">
                     <a routerLink="/stadistic_state"> <i class="bi bi-pie-chart"></i> <span i18n>{{ 'sidebar.state' | translate }}</span> </a>
                  </label>
            </li>
         </ul>
      </li>





      <li class="nav-item" >
         <a class="nav-link collapsed" data-bs-target="#Support-nav" data-bs-toggle="collapse" href="#"> 
            <i class="bi bi-chat-square-dots"></i><span i18n>{{ 'sidebar.support' | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Support-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li (click)="marcarMenu('toc_cb_alert')"> 
                  <input id="toc_cb_alert" class="toc" type="checkbox">   
                  <label for="toc_cb_alert">
                     <a routerLink="/alerts"> <i class="bi bi-bell"></i> <span i18n>{{ 'sidebar.alerts' | translate }}</span> </a>
                  </label>
            </li>
            <li (click)="marcarMenu('toc_cb_incidences')"> 
                  <input id="toc_cb_incidences" class="toc" type="checkbox">   
                  <label for="toc_cb_incidences">
                     <a routerLink="/incidenceMonitoring"> <i class="bi bi-exclamation-triangle"></i> <span i18n>{{ 'sidebar.incidences' | translate }}</span> </a>
                  </label>
            </li>
         </ul>
      </li>

      <li class="nav-item" >
         <a class="nav-link collapsed" data-bs-target="#Config-nav" data-bs-toggle="collapse" href="#"> 
            <i class="bi bi-gear"></i><span i18n>{{ 'sidebar.configuration' | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Config-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
           <!-- <li (click)="marcarMenu('toc_cb_group')"> 
               <input id="toc_cb_group" class="toc" type="checkbox">   
               <label for="toc_cb_group">
                  <a routerLink="/groups"> <i class="bi bi-folder"></i><span i18n>Groups</span> </a>
               </label>
            </li>-->
            <li (click)="marcarMenu('toc_cb_aplications')"> 
               <input id="toc_cb_aplications" class="toc" type="checkbox">   
               <label for="toc_cb_aplications">
                  <a routerLink="/aplications"> <i class="bi bi-folder"></i> <span i18n>{{ 'sidebar.apps' | translate }}</span> </a>
               </label>
            </li>
            <li (click)="marcarMenu('toc_cb_param')"> 
               <input id="toc_cb_param" class="toc" type="checkbox">   
               <label for="toc_cb_param">
                  <a routerLink="/parameters"> <i class="bi bi-sliders"></i> <span i18n>{{ 'sidebar.parameters' | translate }}</span> </a>
               </label>
            </li>
            
            <li (click)="marcarMenu('toc_cb_profile')"> 
               <input id="toc_cb_profile" class="toc" type="checkbox">   
               <label for="toc_cb_profile">
                  <a routerLink="/profile"> <i class="bi bi-bar-chart-steps"></i> <span i18n>{{ 'sidebar.profiles' | translate }}</span> </a>
               </label>
            </li>
            
            <li (click)="marcarMenu('toc_cb_customAlet')"> 
               <input id="toc_cb_customAlet" class="toc" type="checkbox">   
               <label for="toc_cb_customAlet">
                  <a routerLink="/customAlert"> <i class="bi bi-exclamation-triangle"></i> <span i18n>{{ 'sidebar.customAlerts' | translate }}</span> </a>
               </label>
            </li>
            <li (click)="marcarMenu('toc_cb_pendin')"> 
               <input id="toc_cb_pendin" class="toc" type="checkbox">   
               <label for="toc_cb_pendin">
                  <a routerLink="/pendingTask"> <i class="bi bi-send"></i> <span i18n>{{ 'sidebar.pendingTasks' | translate }}</span> </a>
               </label>
            </li>
         </ul>
      </li>


      <li class="nav-item" >
         <a class="nav-link collapsed" data-bs-target="#Mantenimiento-nav" data-bs-toggle="collapse" href="#"> 
            <i class="bi bi-asterisk"></i><span i18n>{{ 'sidebar.maintenance' | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Mantenimiento-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li (click)="marcarMenu('toc_cb_file')"> 
               <input id="toc_cb_file" class="toc" type="checkbox">   
               <label for="toc_cb_file">
                  <a routerLink="/sendFile"> <i class="bi bi-file-earmark-arrow-up"></i><span i18n>{{ 'sidebar.sendFile' | translate }}</span> </a>
               </label>
            </li>
        
            <li (click)="marcarMenu('toc_cb_getfile')"> 
               <input id="toc_cb_getfile" class="toc" type="checkbox">   
               <label for="toc_cb_getfile">
                  <a routerLink="/getFile"> <i class="bi bi-file-earmark-lock"></i> <span i18n>{{ 'sidebar.getFile' | translate }}</span> </a>
               </label>
            </li>
           
      
            <li (click)="marcarMenu('toc_cb_pendingt')"> 
               <input id="toc_cb_pendingt" class="toc" type="checkbox">   
               <label for="toc_cb_pendingt">
                  <a routerLink="/pendingTask"> <i class="bi bi-send"></i> <span i18n>{{ 'sidebar.pendingTasks' | translate }}</span> </a>
               </label>
            </li>
         </ul>
      </li>
    

      <li class="nav-item">
         <a class="nav-link collapsed" data-bs-target="#Admin-nav" data-bs-toggle="collapse" href="#"> 
            <i class="bi bi-shield-shaded"></i><span i18n>{{ 'sidebar.admin' | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Admin-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
            
            <!-- Sección de Usuarios y Clientes visible para operadores y admins -->
            <li *ngIf="checkPermisosRol()">
               <input id="toc_cb_users" class="toc" type="checkbox">   
               <label for="toc_cb_users">
                  <a routerLink="/users"> <i class="bi bi-person"></i> <span i18n>{{ 'sidebar.users' | translate }}</span> </a>
               </label>
            </li>
            
            <li *ngIf="checkPermisosRol()">
               <input id="toc_cb_client" class="toc" type="checkbox">   
               <label for="toc_cb_client">
                  <a routerLink="/client"> <i class="bi bi-building"></i> <span i18n>{{ 'sidebar.clients' | translate }}</span> </a>
               </label>
            </li>

            <!-- Sección de Operadores solo visible para admin -->
            <li *ngIf="checkPermisosRol('admin')">
               <input id="toc_cb_operator" class="toc" type="checkbox">   
               <label for="toc_cb_operator">
                  <a routerLink="/operator"> <i class="bi bi-globe"></i> <span i18n>{{ 'sidebar.operators' | translate }}</span> </a>
               </label>
            </li>

         </ul>
      </li>


    </ul>
 </aside>
