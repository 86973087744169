import { Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogActions, ConfirmDialogData } from "src/app/components/confirm-dialog/confirm-dialog.model";
import { Groups } from "src/app/models/groups.model";
import { CommonService } from "src/app/services/common.service";
import { FormGroupsComponent } from "./form-groups/form-groups.component";
import { GroupsService } from "./groups.service";
import { UtilService } from "src/app/services/util.service";


const BaseColumns ={
  view: 'view',
  operator: 'operator',
  name: 'name',
  cod: 'cod'
}
const {operator , ...Columns} = BaseColumns;
const ColumnsAdmin= {...BaseColumns};  

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  displayedColumns =  Object.keys(Columns);  
  dataSource = new MatTableDataSource<Groups>();
  isAdmin: boolean = true;

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormGroupsComponent>;


  constructor(public dialog: MatDialog, 
    public services:GroupsService,
    public commonService : CommonService,
  private utilService: UtilService) {        }


  

  async lanzarLLamada(){

    let value = await this.services.find();
    
    console.log("-->"+JSON.stringify(value))
    if(value){
      this.dataSource = new MatTableDataSource<Groups>(value.data);
    }
  }


  async ngOnInit(): Promise<void> {
      this.isAdmin = await this.utilService.isAdmin();
      if (this.isAdmin) {
        this.displayedColumns = Object.keys(ColumnsAdmin);
      }
      this.lanzarLLamada();

  }

  refrescar() {
    this.lanzarLLamada();
  }


  



   onNew(){
    this.dialogRef = this.dialog.open(FormGroupsComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormGroupsComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      dialogData.titleI18n = $localize `Delete Group`;
      dialogData.textI18n = $localize `Are you sure you want to delete it?`;
      dialogData.textValue = row.name;
   
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.services.delete(row.id);
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



}




