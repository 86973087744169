<main id="main" class="main">
    <div class="pagetitle">
        <h1>{{ 'clients.title' | translate }}</h1>
        <div>
            <button mat-raised-button class="custom-add-button"  (click)="onNew()">
                <i class="bi bi-plus"></i> {{ 'clients.newClient' | translate }}
              </button>
        </div>
    </div>
        
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div id="tableDescription" hidden>{{ 'clients.tableDescription' | translate }}</div>

    <div class="mat-elevation-z8 tablaPersonal">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
         aria-describedby="tableDescription" matTableExporter #exporter="matTableExporter">

            <!-- id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.id' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'clients.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'clients.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.dateCreate}} </td>
            </ng-container>

            <!-- cod Column -->
            <ng-container matColumnDef="cod">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.cod' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
            </ng-container>

            <!-- operator Column -->
            <ng-container matColumnDef="operator">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'clients.operator' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.operator?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 10px;">
                    <div>
                        <button matTooltip="Edit" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
                            <i class="bi bi-pencil"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <button id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', {fileName:'Terminal'})">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>

        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</main>