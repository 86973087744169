import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { SelectMachineWindowComponent } from 'src/app/pages/comun/select-machine-window/select-machine-window.component';
import { convertFileToJson } from 'src/app/util/util';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';

@Component({
  standalone:true,
  imports:[ CommonModule, FormsModule, TranslateModule, MatIconModule, MatButtonModule ],
  selector: 'app-edit-machines',
  templateUrl: './edit-machines.component.html',
  styleUrls: ['./edit-machines.component.css']
})
export class EditMachinesComponent {
  machines:VendingMachineAtento[] = [];
  showDropZone:boolean = false;
  hasUploadAFile:boolean = false;
  hasSelectedFromPortal:boolean = false;
  constructor(private dialog:MatDialog, 
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile:Profile }, 
    public dialogRef: MatDialogRef<EditMachinesComponent>,
    private profileService:PerfilesService,
    private notifier:NotifierService){}

  ngOnInit(): void {}

  cancelChanges(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData();
    dialogData.enableCancel = false;
    dialogData.titleI18n = "Guardar cambios";
    dialogData.textI18n = "Esta por modificar el perfil, ¿está seguro?";

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM) this.updateProfile();
    });
  }

  private async getRelatedListVendingMachines(): Promise<VendingMachineAtento[]>{
    const filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
    const response  = await this.profileService.getVendingMachines(this.data.profile.id, filterAndPagBean);
    if(response && response.data) return response.data;
    return [];
  }

  public async updateProfile(){
    const profile = this.data.profile;
    const listVendingMachine = await this.getRelatedListVendingMachines();

    profile.listVendingMachine = [...listVendingMachine, ...this.getVendingMachine(this.machines)];
    profile.dateCreate = profile.dateCreate ? new Date(profile.dateCreate) : new Date;
    profile.dateUpdate = profile.dateUpdate ? new Date(profile.dateUpdate) : new Date;
    const response = await this.profileService.saveProfile(profile);
    if(response!.status >= 0 ) this.dialogRef.close({ result:1 });
  }

  getVendingMachine(vendingMachines:VendingMachineAtento[]){
    return vendingMachines.map((vm) => {
      const vendingMachine: VendingMachineAtento = new VendingMachineAtento;
      vendingMachine.id = vm.id ?? undefined;
      vendingMachine.idAtento = vm.idAtento ?? undefined;
      vendingMachine.manufacID = vm.manufacID ?? undefined;
      vendingMachine.modelNumber = vm.modelNumber ?? undefined;
      return vendingMachine;
    });
  }

  openSelectMachine(): void {
    const dialogRef = this.dialog.open(SelectMachineWindowComponent, {
      width: '90%', height: '95%', panelClass: 'custom-modalbox-big'
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.machines.push.apply(this.machines, response.data);
        this.showDropZone = false;
        this.hasSelectedFromPortal = true;
      }
    });
  }

  onFilesSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      files.forEach(async (file) => {
        const parseFileData:{ [key: string]: string; }[] = await convertFileToJson(file);
        parseFileData.forEach((fileData) => {
          const { terminal, fabricante, modelo } = fileData;
          const vendingMachine: VendingMachineAtento = new VendingMachineAtento;
          vendingMachine.idAtento = terminal;
          vendingMachine.manufacID = fabricante;
          vendingMachine.modelNumber = modelo;
          this.machines.push(vendingMachine);
        });
      });
      this.hasUploadAFile = true;
    } else {
      this.notifier.notify('error', 'Please upload a valid CSV file.');
    }
  }

  onFilesDropTerminals(event: any) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.onFilesSelected({ target: { files: [file] } });
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }
}
