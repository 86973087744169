<main id="main" class="main">
   <div class="pagetitle">
      <h1 i18n>{{'userProfile.title' | translate }}</h1>
   </div>
   <section class="section profile">
      <div class="row">
         <div class="col-xl-4">
            <div class="card">
               <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <span class="profilelogo big"> </span>

                  <h2>{{userProfile.name}}</h2>
                  <h3>{{userProfile.rolName}}</h3>

               </div>
            </div>
         </div>
         <div class="col-xl-8">
            <div class="card">
               <div class="card-body pt-3">
                  <ul class="nav nav-tabs nav-tabs-bordered">
                     <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab"
                           data-bs-target="#profile-overview" i18n>{{'userProfile.overview' | translate }}</button></li>
                     <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit"
                           i18n>{{'userProfile.editProfile' | translate }}</button></li>
                     <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab"
                           data-bs-target="#profile-change-password" i18n>{{'userProfile.changePassword' | translate }}</button></li>
                  </ul>
                  <div class="tab-content pt-2">

                     <div class="tab-pane fade show active profile-overview" style="margin-top: 20px;" id="profile-overview">
                        <div class="row mb-3">
                           <div class="col-lg-3 col-md-4 label" i18n>{{'userProfile.username' | translate }}</div>
                           <div class="col-lg-9 col-md-8">{{userProfile.nickName}}</div>
                        </div>
                        <div class="row mb-3">
                           <div class="col-lg-3 col-md-4 label" style="margin-top: 20px;" i18n>{{'userProfile.fullName' | translate }}</div>
                           <div class="col-lg-9 col-md-8">{{userProfile.name}}</div>
                        </div>
                        <div class="row mb-3">
                           <div class="col-lg-3 col-md-4 label" style="margin-top: 20px;" i18n>{{'userProfile.email' | translate }}</div>
                           <div class="col-lg-9 col-md-8">{{userProfile.email}}</div>
                        </div>
                        <div class="row mb-3">
                           <div class="col-lg-3 col-md-4 label" style="margin-top: 20px;" i18n>{{'userProfile.phone' | translate }}</div>
                           <div class="col-lg-9 col-md-8">{{userProfile.phone}}</div>
                        </div>
                        <div class="row mb-3">
                           <div class="col-lg-3 col-md-4 label" style="margin-top: 20px;" i18n>{{'userProfile.language' | translate }}</div>
                           <div class="col-lg-9 col-md-8">{{userProfile.lang}}</div>
                        </div>
                     </div>

                     <div class="tab-pane fade profile-edit pt-3" id="profile-edit">
                        <form [formGroup]="registerForm">
                           <div class="row mb-3">
                              <label for="firstname" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.fullName' | translate }}</label>
                              <div class="col-md-8 col-lg-9"> <input name="firstname" type="text" class="form-control"
                                    formControlName="firstname" id="firstname"></div>
                           </div>
                           <div class="row mb-3">
                              <label for="Email" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.email' | translate }}</label>
                              <div class="col-md-8 col-lg-9"> <input name="email" type="email" class="form-control"
                                    id="Email" formControlName="email"></div>
                           </div>
                           <div class="row mb-3">
                              <label for="Phone" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.phone' | translate }}</label>
                              <div class="col-md-8 col-lg-9"> <input name="phone" type="text" class="form-control"
                                    id="Phone" formControlName="phone"></div>
                           </div>
                           <div class="row mb-3">
                              <label for="Phone" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.language' | translate }}</label>
                              <div class="col-md-8 col-lg-9">
                                 <select class="form-select" name="lang" id="lang" formControlName="lang">
                                    <option *ngFor="let language of languages" [value]="language">{{language}}</option>
                                 </select>
                              </div>
                           </div>


                           <div class="text-center">
                              <button type="btn btn-aceptar" class="btn btn-primary" (click)="onFormSubmit()" i18n>{{'userProfile.saveChanges' | translate }}
                                 </button>
                           </div>
                        </form>
                     </div>
                     <div class="tab-pane fade pt-3" id="profile-settings">
                        <form>
                           <div class="row mb-3">
                              <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Email Notifications</label>
                              <div class="col-md-8 col-lg-9">
                                 <div class="form-check"> <input class="form-check-input" type="checkbox"
                                       id="changesMade" checked> <label class="form-check-label" for="changesMade">
                                       Changes made to your account </label></div>
                                 <div class="form-check"> <input class="form-check-input" type="checkbox"
                                       id="newProducts" checked> <label class="form-check-label" for="newProducts">
                                       Information on new products and services </label></div>
                                 <div class="form-check"> <input class="form-check-input" type="checkbox"
                                       id="proOffers"> <label class="form-check-label" for="proOffers"> Marketing and
                                       promo offers </label></div>
                                 <div class="form-check"> <input class="form-check-input" type="checkbox"
                                       id="securityNotify" checked disabled> <label class="form-check-label"
                                       for="securityNotify"> Security alerts </label></div>
                              </div>
                           </div>
                           <div class="text-center"> <button type="submit" class="btn btn-primary">{{'userProfile.saveChanges' | translate }}</button>
                           </div>
                        </form>
                     </div>
                     <div class="tab-pane fade pt-3" id="profile-change-password">

                        <form id="change-password" class="needs-validation" novalidate [formGroup]="registerFormPass">
                           <div class="row mb-3" ngDefaultControl>

                              <h5 class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.newPassword' | translate }} <span
                                    class="obligatorio">*</span></h5>
                              <div class="col-md-8 col-lg-9">
                                 <input formControlName="newpass" type="password" class="form-control"
                                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                    required>
                                 <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('required')" i18n>{{'userProfile.errors.requiredPassword' | translate }}</mat-error>
                                 <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('pattern')" i18n>
                                    {{'userProfile.errors.patternPassword' | translate }} </mat-error>
                                 <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('minlength')" i18n>{{'userProfile.errors.minLengthPassword' | translate }}</mat-error>
                                 <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('maxlength')" i18n>{{'userProfile.errors.maxLengthPassword' | translate }}</mat-error>
                              </div>
                           </div>
                           <div class="row mb-3" ngDefaultControl>
                              <h5 class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.confirmNewPassword' | translate }} <span
                                    class="obligatorio">*</span></h5>
                              <div class="col-md-8 col-lg-9">
                                 <input formControlName="repeatpass" type="password" class="form-control"
                                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                    required>
                                 <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('required')" i18n>{{'userProfile.errors.requiredPassword' | translate }}</mat-error>
                                 <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('pattern')"
                                    i18n>{{'userProfile.errors.patternPassword' | translate }} </mat-error>
                                 <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('minlength')"
                                    i18n>{{'userProfile.errors.minLengthPassword' | translate }}</mat-error>
                                 <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('maxlength')"
                                    i18n>{{'userProfile.errors.maxLengthPassword' | translate }}</mat-error>
                                 <mat-error *ngIf="passNocoincide" i18n>{{'userProfile.errors.maxLengthPassword' | translate }}</mat-error>
                              </div>
                           </div>

                           <div class="text-center"> <button class="btn btn-primary" type="submit"
                                 (click)="onFormChangePass()" i18n>{{'userProfile.errors.passwordMismatch' | translate }}</button></div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>