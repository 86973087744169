<main id="main" class="main">


  <div class="pagetitle">
    <h1 i18n>{{ 'customizableAlerts.title' | translate }}</h1>
    <div>
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>

      <button mat-raised-button class="custom-add-button"  (click)="onNew()">
        <i class="bi bi-plus"></i> {{ 'customizableAlerts.newAlert' | translate }}
      </button>
    </div>
  </div>


    <!-- Filtro de perfiles -->
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>



  <div id="tableDescription" style="display:none;" i18n>
    {{ 'customizableAlerts.tableDescription' | translate }}
  </div>

  <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" 
    matSort (matSortChange)="announceSortChange($event)"
    matTableExporter #exporter="matTableExporter">

      <!-- typeAlert Column -->
      <ng-container matColumnDef="typeAlert">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.typeAlertTitle' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.typeAlert === 20" i18n>{{ 'customizableAlerts.typeAlert.battery' | translate }}</span>
          <span *ngIf="element.typeAlert === 21" i18n>{{ 'customizableAlerts.typeAlert.hour' | translate }}</span>
          <span *ngIf="element.typeAlert === 22" i18n>{{ 'customizableAlerts.typeAlert.key' | translate }}</span>
          <span *ngIf="element.typeAlert === 23" i18n>{{ 'customizableAlerts.typeAlert.print' | translate }}</span>
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          <div>Description</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.typeAlert === 20" i18n>Load cycles {{element.operation}} {{element.value}}</span>
          <span *ngIf="element.typeAlert === 21" i18n>Active hours of screen {{element.operation}}  {{element.value}}</span>
          <span *ngIf="element.typeAlert === 22" i18n>Keystrokes {{element.operation}} {{element.value}}</span>
          <span *ngIf="element.typeAlert === 23" i18n>Printed Lines {{element.operation}} {{element.value}}</span>
        </td>
      </ng-container>


      <!-- priority Column -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.priorityTitle' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.priority === 0" i18n>{{ 'customizableAlerts.priority.none' | translate }}</span>
          <span *ngIf="element.priority === 1" i18n>{{ 'customizableAlerts.priority.low' | translate }}</span>
          <span *ngIf="element.priority === 2" i18n>{{ 'customizableAlerts.priority.medium' | translate }}</span>
          <span *ngIf="element.priority === 3" i18n>{{ 'customizableAlerts.priority.high' | translate }}</span>
        </td>
      </ng-container>


      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.date' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>

      <ng-container matColumnDef="operator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.operator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | operatorName}}</td>
      </ng-container>

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button" style="width: 10px;">
          <div>
            <button matTooltip="delete" (click)="onDelete(element)" mat-icon-button class="iconoTabla">
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', {fileName:'Alerts'})"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>


    <!-- Paginador -->
    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
  </div>

</main>