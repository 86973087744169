import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { UtilService } from 'src/app/services/util.service';
import { getBase64, parseBase64Data } from 'src/app/util/util';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { Pending } from 'src/app/models/pending.model';
import { Aplications } from 'src/app/models/aplications.model';



@Component({
  selector: 'app-send-file',
  templateUrl: './send-file.component.html',
  styleUrls: ['./send-file.component.css']
})
export class SendFileComponent implements OnInit, OnChanges {

  @Input() viewButtom = true;
  @Input() fileUp: FirmwareUpload = new FirmwareUpload();
  @Input() fileName = '';
  @Input() hora: Pending = new Pending();


  registerForm!: FormGroup;


  private notifier: NotifierService;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private utilService: UtilService,
    notifier: NotifierService, private formBuilder: FormBuilder, public dialog: MatDialog) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      timeIni: [null],
      timeEnd: [null],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileUp']) {
      this.resetFormState();
    }
  }

  resetFormState() {
    this.registerForm?.reset();
    this.fileName = '';
  }

  valuechange(e: any) {
    this.hora.horaIni = e.target.value;
  }
  updateValueEnd(e: any) {
    this.hora.horaDuration = e.target.value;
  }

  async onFileDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.fileName = file.name; // Asignar el nombre del archivo
        getBase64(file).then(data => {
          this.addFileToList(data as string, file.name);
        });
      }
    }
  }
  
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name; // Asignar el nombre del archivo
      getBase64(file).then(data => {
        this.addFileToList(data as string, file.name);
      });
    }
  }

  private addFileToList(data:string,fileName:string){
    const fileDescription: Aplications = new Aplications();
    fileDescription.name = fileName;
    fileDescription.base64 = parseBase64Data(data);
    this.fileUp.fileList = [fileDescription];
  }


 // Función para eliminar el archivo cargado
 removeFile(): void {
  this.fileUp.fileList = []; 
  this.fileName = ''; 
}

  async onFormSubmit(): Promise<void> {
    if (this.data?.obj) {
      let objBBDD: VendingMachineAtento
      objBBDD = this.data.obj
      //hay que mapear las fechas para que el BACK las entienda
      if (objBBDD.lastConect) { objBBDD.lastConect = new Date(objBBDD.lastConect); }
      if (objBBDD.lastUpdate) { objBBDD.lastUpdate = new Date(objBBDD.lastUpdate); }
      if (objBBDD.dateCreate) { objBBDD.dateCreate = new Date(objBBDD.dateCreate); }
      if (objBBDD.dateInstall) { objBBDD.dateInstall = new Date(objBBDD.dateInstall); }
      this.fileUp.machines = [objBBDD]
    }

    if (this.fileUp.fileList === undefined || this.fileUp.fileList.length == 0) {
      this.notifier.notify('error', 'The file is mandatory')
      return;
    }

    const data = this.applyFormats(this.registerForm);

    let horaIni = data['timeIni']
    let horaEnd = data['timeEnd']
    if (horaIni || horaEnd) {
      if (horaIni && horaEnd) {
        this.fileUp.horaIni = horaIni
        let hi = '2022-12-24 ' + horaIni + ':00'
        let hf = '2022-12-24 ' + horaEnd + ':00'
        // Crear instancias de moment
        const momentHi = moment(hi)
        const momentHf = moment(hf)
        let diferenciaEnMinutos = momentHf.diff(momentHi, "minutes")
        if (diferenciaEnMinutos < 0) {
          this.notifier.notify('error', 'Missing start time')
          return;
        }
        this.fileUp.duracion = diferenciaEnMinutos + ""
      } else if (horaIni) {
        this.fileUp.horaIni = horaIni
      } else {
        this.notifier.notify('error', 'Missing start time')
        return;
      }
    }

    this.fileUp.cod = "1"// se pone a piñon el codigo de 1 de FILE=1
    this.fileUp.destination = environment.pathTempfile

    let result = await this.utilService.sendFile(this.fileUp);
    if (result!.status >= 0) {
      this.notifier.notify('success', 'File uploaded successfully')
      this.fileName = '';
      this.fileUp.fileList = []
    }
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }


}
