import { Injectable } from '@angular/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class TerminalService {


  constructor(private merchantRest : RestService) {}
  
    
  
  async updateEditService(_body: VendingMachineAtento){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'saveVendingMachine/',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }




 


}