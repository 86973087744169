import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ConsoleRequest } from '../models/console.request.model';
import { Groups } from '../models/groups.model';
import { Operator } from '../models/operator.model';
import { AuthService } from '../auth/auth.service';
import { FirmwareUpload } from '../models/firmwareUpload.model';
import { Observable, of, from } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { GetFile } from '../models/getFile';

@Injectable({
  providedIn: 'root',
})
export class UtilService {

  private operators$: Observable<any>;
  private rols$: Observable<any>;


  constructor(
    private merchantRest : RestService, public commonService: CommonService, 
    private authService:AuthService) {
      this.operators$ = new Observable();
      this.rols$ = new Observable();
      this.initializeRols();
      this.initializeOperators();
    }


       
    private initializeOperators(): void {
      let _body: ConsoleRequest = new ConsoleRequest();
      let _operator: Operator = new Operator();
  
      let userSession = this.authService.currentUserValue();
      if (userSession?.operator) {
        _operator.idEntorno = userSession.entorno.id;
      }
      _body.data = _operator;
      this.operators$ = from(this.merchantRest.commonRestCallConsolaRest(_body, 'getListOperatorByClient/', true, true))
        .pipe(
          catchError(err => {
            console.error('Error fetching operators', err);
            return of([]);
          }),
          shareReplay(1)
        );
    }

  findOperators(): Observable<any> {
    return this.operators$;
  }


  

    async sendFile(_body:FirmwareUpload){
     
        let value = await this.merchantRest.commonRestCallConsolaRest(_body,'setFileToSend/',true,true )   
        .catch(err => {
          return null
        })

        return value;      
    }


    async get(id: number) {

      let _body: GetFile = new GetFile();
      _body.id = id
  
      let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'get/', true, true)
        .catch(err => {
          return null
        })
  
      return value;
    }


    async setGetFileAtento(file: GetFile) {

      let _body = file

      let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'setGetFileAtento/', true, true)
        .catch(err => {
          return null
        })
  
          return value;
    }

    async findGroups(){
      let _body: Groups = new Groups();
      let userSession = this.authService.currentUserValue();
      if (userSession?.entorno) {
          _body.idEntorno = userSession.entorno.id;
      }
      if (userSession?.operator) {
          _body.idOperator = userSession.operator.id;
      }
        let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListGroup/',true,true )   
        .catch(err => {
          return null
        })

        return value!.data;   
      
    }

    
    
    async updateFileMachine(_body:FirmwareUpload){
     
      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'setExcludeMachineFirmware/',true,true )   
      .catch(err => {
        return null
      })

      return value;      
  }


  private initializeRols(): void {
    this.rols$ = from(this.fetchRoles()).pipe(
      shareReplay(1),
      catchError(err => {
        console.error('Error fetching roles', err);
        return of([]); 
      })
    );
  }
  private async fetchRoles() {
    let _body: ConsoleRequest = new ConsoleRequest();
    let _operator: Operator = new Operator();
    _operator.idEntorno = this.authService.currentUserValue()?.operator?.idEntorno;
    _body.data = _operator;
    try {
        let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'getRolesYOperadores/', true, true);
        if (value && Array.isArray(value?.data?.roles)) {
            return value.data.roles; 
        } else {
            console.warn('Received null or improperly structured response:', value);
            return [];
        }
    } catch (err) {
        console.error('Failed to fetch roles:', err);
        throw err;
    }
}


  public findRol(): Observable<any> {
    return this.rols$;
  }

 
  public isAdmin(): boolean {
    const userSession = this.authService.currentUserValue();
    return userSession?.rol !== undefined && userSession.rol.id === 1;
  }
  
  public getOperatorId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.operator?.id;
  }

  public getOperator(): Operator | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.operator;
  }
}