import { FilterAndPagBean } from "./FilterAndPagBean";

export class StadisticBean {
	// the fields selected are needed to request the sensors charts
  	idVendingMachine: number | undefined;/////
	provincia: string | undefined;
	dateIni!:Date;////
	dateEnd!:Date;/////
	typeReport: number | undefined;;/////   2
	idOperator: number | undefined;;
	idClient: number | undefined;
	commerce: string | undefined;
	idGroup!: number;
	lastId: number | undefined;
	idCategory: number | undefined;
	idSubcategory: number | undefined;
	customId: string | undefined;
	statusVend: number | undefined;
	idEntorno: number | undefined;/////
	resolutionHour!: boolean;//// false
	
	filterAndPagBean!:FilterAndPagBean;

}



