<div class="pagetitle">
    <h2>{{ 'profiles.terminals' | translate }}</h2>
</div>

<app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
<div class="related-machines">
    <div id="tableDescription" style="display:none;">{{ 'vendingMachine.tableDescription' | translate }}</div>
    <table class="table related-machines-table" aria-labelledby="tableDescription">
        <thead class="related-machines-thead">
            <tr>
            <th scope="col">{{ 'vendingMachine.id' | translate }}</th>
            <th scope="col">{{ 'vendingMachine.customId' | translate }}</th>
            <th scope="col">{{ 'vendingMachine.idAtento' | translate }}</th>
            <th scope="col">{{ 'vendingMachine.modelNumber' | translate }}</th>
            <th scope="col"></th>
            </tr>
        </thead>
        <tbody class="related-machines-tbody">
            <tr *ngFor="let vm of machines">
                <td class="related-machines-cells">{{ vm.id }}</td>
                <td class="related-machines-cells">{{ vm.customId }}</td>
                <td class="related-machines-cells">{{ vm.idAtento }}</td>
                <td class="related-machines-cells">{{ vm.modelNumber }}</td>
                <td class="related-machines-cells">
                    <button mat-icon-button (click)="delete(vm)">
                        <mat-icon mat-list-icon class="delete-icon">delete</mat-icon>
                    </button>   
                </td>
            </tr>
        </tbody>
    </table>
    <!-- Paginador -->
    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>

<div class="w-100 d-flex justify-content-between mt-3">
    <button mat-stroked-button (click)="cancelChanges()">
        {{ 'common.cancel' | translate }}
    </button>
    <button mat-stroked-button (click)="submitChanges()">
        {{ 'common.submit' | translate }}
    </button>
</div>