<main id="main" class="main">
  <div class="pagetitle">
    <h1>{{ 'alerts.title' | translate }}</h1>
    <button mat-raised-button class="custom-white-button" (click)="refrescar()">
      <i class="bi bi-arrow-clockwise"></i>  {{'common.refresh' | translate }}
    </button>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden> {{'alerts.tableDescription' | translate }} </div>

  <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription"  matSort (matSortChange)="announceSortChange($event)" matTableExporter
      #exporter="matTableExporter">

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button" style="  width: 110px;">   
          <div>
              <button matTooltip="view"  (click)="onView(element)" mat-icon-button  class="iconoTabla" >
                <i class="bi bi-eye"></i>
              </button>                  
          </div>
        </td>
      </ng-container>

      <!-- terminal Column -->
      <ng-container matColumnDef="terminal">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div>{{ 'alerts.nameMachineID' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.nameMachineID}}">
          {{element.nameMachineID}} </td>
      </ng-container>
      <!-- cod Column -->
      <ng-container matColumnDef="cod">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'alerts.cod' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
      </ng-container>


      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header> {{ 'alerts.description' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.description}}">
          {{element.description}} </td>
      </ng-container>

      <!-- priority Column -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header> {{ 'alerts.priority' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.priority === 0" i18n>-</span>
          <span *ngIf="element.priority === 1" i18n>{{ 'alert.priorityLow' | translate }}</span>
          <span *ngIf="element.priority === 2" i18n>{{ 'alert.priorityMedium' | translate }}</span>
          <span *ngIf="element.priority === 3" i18n>{{ 'alert.priorityHigh' | translate }}</span>
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header> {{ 'alerts.status' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.status === 0" i18n>{{ 'alerts.statusFinalized' | translate }}</span>
          <span *ngIf="element.status === 1" i18n>{{ 'alerts.statusActive' | translate }}</span>
          <span *ngIf="element.status === 2" i18n>{{ 'alerts.statusUndefined' | translate }}</span>
        </td>
      </ng-container>

      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header> {{ 'alerts.dateCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>



      <ng-container matColumnDef="operator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef>
          <div> {{ 'alerts.operator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | operatorName}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', {fileName:'Alerts'})"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent()" 
      showFirstLastButtons 
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>