import { Users } from "./users.model";

export class Incidence {
	id!: number;
	idVM!: number;
	customID: string | undefined;
	idOperator!: number;
	userCreate!: Users;
	userDelete!: Users;	
	description: string | undefined;
	resolution: string | undefined;
	dateCreate: Date | undefined;
	dateDelete: Date | undefined;
	type!: number;
	activo:boolean = false;
}


