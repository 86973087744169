<div class="pagetitle">
  <h1>{{ 'pendingTasks.title' | translate }}</h1>   
  <div>
         <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>
  </div>
</div>

<app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

<div id="tableDescription" hidden>{{ 'pendingTasks.tableDescription' | translate }}</div>

<div class="mat-elevation-z8 tablaPersonal">
  <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">
    <!-- nameMachine Column -->
    <ng-container matColumnDef="nameMachine">
      <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div>{{ 'pendingTasks.nameMachine' | translate }}</div></th>       
      <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.nameMachine}}"> {{element.nameMachine}} </td>
    </ng-container> 
    <!-- typeSend Column -->
    <ng-container matColumnDef="typeSend">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.typeSend' | translate }}</div></th>       
      <td mat-cell  *matCellDef="let element" > 
        <span *ngIf="element.typeSend === '10'">File</span>
        <span *ngIf="element.typeSend === '1'">Firmware</span>
      </td>
    </ng-container>
    <!-- sendJson Column -->
    <ng-container matColumnDef="sendJson">
        <th mat-header-cell *matHeaderCellDef  class="tamano_columna_max"><div mat-sort-header>{{ 'pendingTasks.sendJson' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.sendJson}}"> {{element.sendJson}} </td>
    </ng-container>
    <!-- dateCreate Column -->
    <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef><div>{{ 'pendingTasks.dateCreate' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
    </ng-container>
    <!-- retries Column -->
    <ng-container matColumnDef="retries">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.retries' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element"> {{element.retries}}</td>
    </ng-container>
      <!-- codError Column -->
    <ng-container matColumnDef="codError">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.codError' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element"> {{element.codError}}</td>
    </ng-container>
    <!-- commerce Column -->
    <ng-container matColumnDef="setMachine">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.setMachine' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element"> 
            <i   *ngIf="element.setMachine" class="bi bi-check"></i>            
        </td>
    </ng-container>
    <!-- horaIni Column -->
    <ng-container matColumnDef="horaIni">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.horaIni' | translate }}</div></th>       
      <td mat-cell *matCellDef="let element"> {{element.horaIni}}</td>
  </ng-container>
    <!-- numBlocks Column -->
    <ng-container matColumnDef="numBlocks">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.numBlocks' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element">
            <div style="position: relative">
                <mat-progress-bar mode="determinate" [value]="verProcentaje(element)"></mat-progress-bar>
                {{element.lastBlock}} /  {{element.numBlocks}}
            </div>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="button" style="  width: 110px;">   
        <div>
          <div *ngIf="canOpenModal" >
            <button matTooltip="view"  (click)="onView(element)" mat-icon-button  class="iconoTabla" >
              <i class="bi bi-eye"></i>
            </button>       
          </div>
          <button *ngIf="element.retries>10" matTooltip="reset"  (click)="onReset(element)" mat-icon-button class="iconoTabla" >
            <i class="bi bi-arrow-clockwise"></i>
          </button>  
          <button matTooltip="delete"  (click)="onDelete(element)" mat-icon-button class="iconoTabla" >
            <i class="bi bi-trash"></i>
          </button>  
          
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <button id="export" class="icoDownload" mat-icon-button (click)="exporter.exportTable('xlsx', {fileName:'Pending'})"><i class="bi bi-file-earmark-arrow-down"></i> </button>
  
  <mat-paginator [length]="pageLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()"
            showFirstLastButtons 
            aria-label="Select page of periodic elements">
  </mat-paginator>
</div>