<main id="main" class="main">
  <div class="pagetitle">
    <h1>{{ 'users.title' | translate }}</h1>
    <div>
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>

      <button mat-raised-button class="custom-add-button"  (click)="onNew()">
        <i class="bi bi-plus"></i> {{ 'users.newUser' | translate }}
      </button>
    </div>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" style="display:none;">{{ 'users.tableDescription' | translate }}</div>

  <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'users.status' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <img *ngIf="!element.dateDelete" class="icoTabla" src="assets/img/bola-verde.png" alt="">
          <img *ngIf="element.dateDelete" class="icoTabla" src="assets/img/bola-roja.png" alt="">
        </td>
      </ng-container>

      <!-- nickName Column -->
      <ng-container matColumnDef="nickName">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'users.nickName' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.nickName}} </td>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header>{{ 'users.name' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.name}}"> {{element.name}}
        </td>
      </ng-container>
      <!-- identifier Column -->
      <ng-container matColumnDef="identifier">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'users.identification' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.identification}} </td>
      </ng-container>
      <!-- rol_name Column -->
      <ng-container matColumnDef="rol_name">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'users.rolName' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.rol?.name}} </td>
      </ng-container>

      <!-- operator_name Column -->
      <ng-container matColumnDef="operator_name" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div>{{ 'users.operator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.operator?.name}}">
          {{element.operator?.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button" style=" width: 90px;">
          <div>
            <button *ngIf="!element.dateDelete" matTooltip="delete" (click)="onDelete(element)" mat-icon-button
              class="iconoTabla">
              <i class="bi bi-trash"></i>
            </button>
            <button matTooltip="reset password" (click)="onResetPassword(element)" mat-icon-button class="iconoTabla">
              <i class="bi bi-key"></i>
            </button>
            <button matTooltip="Edit" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
              <i class="bi bi-pencil"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', {fileName:'Terminal'})"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent($event)" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>