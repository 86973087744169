import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder,  FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { FormCreateActions, pedingWarnigAlert } from 'src/app/util/constants';
import { TerminalService } from './terminal.service';
import { NotifierService } from 'angular-notifier';
import { CommonService, toastPayload } from 'src/app/services/common.service';
import { IndividualConfig } from 'ngx-toastr';
import { DateOnTimeZonePipe } from 'src/app/pages/comun/pipes/date-on-timezone.pipe';

@Component({
  selector: 'app-info-terminal',
  templateUrl: './info-terminal.component.html',
  styleUrls: ['./info-terminal.component.css'],
  providers:[DateOnTimeZonePipe]
})
export class InfoTerminalComponent implements OnInit {
	
  private notifier: NotifierService;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private formBuilder: FormBuilder, notifier: NotifierService ,
  private services: TerminalService,
  public dialogRef: MatDialogRef<InfoTerminalComponent>,
  public dialog: MatDialog,
  private commonService: CommonService,
  private dateOnTimezone: DateOnTimeZonePipe
  ) { 
    this.notifier = notifier;
  }

  registerForm!: FormGroup;
  objBBDD:VendingMachineAtento = new VendingMachineAtento();

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;  


  async ngOnInit(): Promise<void> {

    let nameField, serialNrAtentoField ,serialNumberField, swRevVMField, manufacIDField,  modelField,
    locationField,  ipField,  identificadorField,  macBTField,  swRevField,  imeiField,  commerceField,
    orderCommerceField,  puntoVentaField, dateCreateField, simField, hasPending;


    if(this.data?.obj){
      this.objBBDD = this.data.obj;

      nameField=this.objBBDD.name
      serialNrAtentoField=this.objBBDD.serialNrAtento
      serialNumberField=this.objBBDD.serialNumber
      swRevVMField=this.objBBDD.swRev
      manufacIDField=this.objBBDD.manufacID
      modelField=this.objBBDD.modelNumber
      locationField=this.objBBDD.location
      ipField=this.objBBDD.ip
      identificadorField=this.objBBDD.customId
      macBTField=this.objBBDD.macBT
      swRevField=this.objBBDD.softwareRevision
      imeiField=this.objBBDD.imei
      commerceField=this.objBBDD.commerce
      orderCommerceField=this.objBBDD.orderCommerce
      puntoVentaField=this.objBBDD.puntoVenta
      dateCreateField=this.objBBDD.dateCreate
      simField=this.objBBDD.sim
      hasPending = this.objBBDD.hasPending
      
    }

    if(hasPending) {
      const toastData: toastPayload = {
        message: pedingWarnigAlert.text,
        title: pedingWarnigAlert.type,
        ic: {
          timeOut: pedingWarnigAlert.timeout,
          closeButton: true,
          positionClass: pedingWarnigAlert.position
      } as IndividualConfig,
        type: pedingWarnigAlert.type,  
      };
      this.commonService.showWarning(toastData);
    }   
    
    const dateWithTimeZone =  dateCreateField ? this.dateOnTimezone.transform(dateCreateField+"", true): "";
    this.registerForm = this.formBuilder.group({
        //editables    
          nameField:[nameField],
          locationField:[locationField],
          puntoVentaField:[puntoVentaField],
        //solo lectura
          serialNrAtentoField:[{value: serialNrAtentoField, disabled:true}],
          serialNumberField:[{value: serialNumberField, disabled:true}],
          swRevVMField:[{value: swRevVMField, disabled:true}],
          manufacIDField:[{value: manufacIDField, disabled:true}],
          modelField:[{value: modelField, disabled:true}],
          ipField:[{value: ipField, disabled:true}],
          identificadorField:[{value: identificadorField, disabled:true}],
          macBTField:[{value: macBTField, disabled:true}],
          swRevField:[{value: swRevField, disabled:true}],
          imeiField:[{value: imeiField, disabled:true}],
          commerceField:[{value: commerceField, disabled:true}],
          orderCommerceField:[{value: orderCommerceField, disabled:true}],
          dateCreateField:[{value:dateWithTimeZone, disabled:true}],
          simField:[{value: simField, disabled:true}]
   });


  }


  onFormSubmit(): void {
    console.log(this.registerForm);
    
    

    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);


    let objNew : VendingMachineAtento = new VendingMachineAtento();
    if(this.objBBDD.id){
      objNew=this.objBBDD

      //hay que mapear las fechas para que el BACK las entienda
          if(this.objBBDD.lastConect){  objNew.lastConect = new Date(this.objBBDD.lastConect);  }
          if(this.objBBDD.lastUpdate){  objNew.lastUpdate = new Date(this.objBBDD.lastUpdate);  }
          if(this.objBBDD.dateCreate){  objNew.dateCreate = new Date(this.objBBDD.dateCreate);  }
          if(this.objBBDD.dateInstall){ objNew.dateInstall = new Date(this.objBBDD.dateInstall);}
    }


    objNew.name=data['nameField'] 
    objNew.puntoVenta=data['puntoVentaField'] 
    objNew.location=data['locationField']  
    


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Update Terminal info`;
    dialogData.textI18n = $localize `Are you sure you want to edit it?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');

         let result = await this.services.updateEditService(objNew)
         if(result){
           this.notifier.notify('success', 'Terminal updated successfully') 
           this.dialogRef.close(FormCreateActions.SAVED);
         }

      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }





}
