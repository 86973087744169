import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotifierService } from 'angular-notifier';
import { Aplications } from 'src/app/models/aplications.model';
import { AppsService } from '../aplications.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { FormCreateActions } from 'src/app/util/constants';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-edit-app',
  templateUrl: './edit-app.component.html',
  styleUrls: ['./edit-app.component.css']
})
export class EditAppComponent implements OnInit {
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  @Input() dataSource = new MatTableDataSource<Aplications>();
  isLinear: BooleanInput;
  selectedFile: File | null = null;
  showDropZone = false;
  isFileSelected: boolean | undefined;
  versions: Aplications[] = [];
  filterList: FilterableValue[] = [];
  currentVersion: string | null = null; // Para la versión actual
  packageName: string = '';
  isAddingVersion = false;


  isOpen: { [key: string]: boolean } = {
    details: true,
    dates: true,
    versiones: true,
  };
  confirmDialogRef: any;

  readOnly:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { app:Aplications, readOnly:boolean },
    private appService: AppsService,

  ) {
  }

  ngOnInit(): void {
    this.readOnly = !!this.data.readOnly;

    // Primer formulario para los datos de la aplicación
    this.firstFormGroup = this.formBuilder.group({
      name: [{value:'', disabled:this.readOnly}, Validators.required],
      description: [{value:'', disabled:this.readOnly}, Validators.required],
      packageName: [{ value: '', disabled: true }, Validators.required],
      dateCreate: [{ value: this.data.app.dateCreate, disabled: true }],
      dateUpdate: [{ value: this.data.app.dateUpdate, disabled: true }],
    });

    // Segundo formulario para gestionar los archivos y la versión
    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required],
      fileBase64: [''],
      version: ['']
    });

    // Verifica que los datos estén disponibles
    if (this.data) {
      this.loadFormData(this.data.app); // Carga los datos en el formulario
      this.currentVersion = this.data.app.version; // Inicializa currentVersion
    } else {
      this.notifier.notify('error', 'No se encontraron datos para cargar.');
    }

    this.filterList.push(new FilterableValue("pakage", this.data.app.pakage, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));

    const request = new FilterAndPagBean(
      null,
      null,
      this.filterList,
      0,
      0,
      0
    );

    // Llama al servicio para obtener los datos del backend
    this.appService.find(request).then((response) => {
      console.log('Respuesta del backend:', response);
      if (response && response.status === 0) {
        let dataAppVersion: Aplications[] = response.data;

        if (dataAppVersion && dataAppVersion.length > 0) {
          // Ordenar dataAppVersion por dateCreate (o el campo que elijas) en orden descendente
          dataAppVersion.sort((a, b) => new Date(b.dateCreate).getTime() - new Date(a.dateCreate).getTime());

          // Tomar el primer elemento, que ahora es el más reciente
          const appData = dataAppVersion[0];

          this.loadFormData(appData);
          this.dataSource.data = dataAppVersion;

          // Llenar la lista de versiones
          this.versions = dataAppVersion.map((app) => app);
          // Establecer la última versión como currentVersion
          this.currentVersion = this.versions[0].version; // La primera en la lista ahora es la más reciente
          this.firstFormGroup.markAsDirty();
        } else {
          this.notifier.notify('error', 'No se encontraron versiones de la aplicación');
        }
      } else {
        this.notifier.notify('error', `Error al cargar datos`);
      }
    }).catch((error) => {
      this.notifier.notify('error', `Error al cargar datos: ${error.message}`);
    });
  }


  // Lógica para iniciar el proceso de agregar una nueva versión
  startNewVersion() {
    this.isAddingVersion = true;
    this.showDropZone = true; // Mostrar la zona de carga de archivos
  }

  onSave(): void {
    // Validamos ambos formularios
    if (this.firstFormGroup.valid && (this.isAddingVersion || this.versions.length > 0)) {

      // Crea el objeto FirmwareUpload para enviar al backend
      let objNew: FirmwareUpload = new FirmwareUpload();
      objNew.id = this.data.app.id;

      const { name, description } = this.firstFormGroup.value;

      // Creamos el objeto Aplications combinando los datos de ambos formularios
      const appNew: Aplications = new Aplications;
      appNew.name = name;
      appNew.description = description;
      appNew.dateCreate = this.data.app.dateCreate;
      appNew.dateUpdate = new Date();
      appNew.pakage = this.data.app.pakage;
      appNew.version = this.data.app.version;
      appNew.base64 = this.data.app.base64;
      appNew.fileName = this.data.app.fileName;


      let lstAppNew: Aplications[] = [appNew];
      objNew.fileList = lstAppNew;

      this.saveToBackend(objNew);

    } else {
      this.notifier.notify('warning', 'Por favor, completa todos los campos requeridos');
    }
  }

  saveToBackend(objNew: FirmwareUpload): void {
    this.appService.addUpdate(objNew).then(() => {
      this.dialogRef.close(FormCreateActions.SAVED);
      this.notifier.notify('success', 'Actualizado correctamente');

    }).catch(error => {
      this.notifier.notify('error', `Error al actualizar: ${error.message}`);
      this.dialogRef.close(FormCreateActions.EXIT);
    });
  }

  // Método para cargar los datos en el formulario
  loadFormData(data: Aplications): void {
    const today = new Date().toISOString().substring(0, 10);
    this.firstFormGroup.patchValue({
      name: data.name,
      description: data.description,
      packageName: data.pakage,
      dateCreate: data.dateCreate,
      dateUpdate: today,
    });
    this.packageName = data.pakage;
  }

  // Método que se llama cuando se abre el diálogo de edición
  openEditDialog(selectedApp: Aplications): void {
    this.data.app = selectedApp; // Asigna el registro seleccionado
    this.loadFormData(this.data.app); // Carga los datos en el formulario
    this.dialog.open(EditAppComponent, {
      data: this.data // Pasa los datos al diálogo
    });
  }

  extractVersion(fileName: string): string {
    const versionPattern = /(\d+\.\d+\.\d+[-\w]*)/; // para encontrar la versión
    const match = fileName.match(versionPattern);
    return match ? match[0] : ''; // Devuelve la versión si se encuentra, sino devuelve una cadena vacía
  }

  addVersion(): void {
    this.showDropZone = true;
  }

  removeVersion(id: number): void {
    // Crear datos para el diálogo de confirmación
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize`Eliminar versión`;
    dialogData.textI18n = $localize`¿Estás seguro de que quieres eliminar esta versión?`;

    // Abrir el diálogo de confirmación
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    // Manejar el resultado después de que el diálogo se cierre
    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        // Si el usuario confirma la acción, eliminar la versión
        console.log('Confirmado para eliminar la versión');

        // Lógica para eliminar la versión
        const index = this.versions.findIndex(version => version.id === id);  // Encuentra el índice de la versión por el ID
        if (index >= 0) {
          this.versions.splice(index, 1);  // Elimina la versión de la lista en la UI
          this.dataSource.data = [...this.versions]; // Actualiza dataSource para reflejar el cambio

          // Llamar al servicio para eliminar en el backend
          let val: any;
          val = await this.appService.delete(id);

          if (val!.status >= 0) {
            this.notifier.notify('success', 'Versión eliminada correctamente');
          } else {
            this.notifier.notify('error', 'Error al eliminar la versión');
          }
        } else {
          this.notifier.notify('error', 'Versión no encontrada');
        }
      }
    });
  }


  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        // Elimina el encabezado `data:` y el MIME type, dejando solo el base64
        const base64String = result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  onDelete(id: number): void {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar?');

    if (confirmDelete) {
      this.appService.delete(id).then(() => {
        this.dialogRef.close(FormCreateActions.SAVED);
        this.notifier.notify('success', 'Eliminada con éxito');
      }).catch(error => {
        this.notifier.notify('error', `Error al eliminar: ${error.message}`);
        this.dialogRef.close(FormCreateActions.EXIT);
      });
    }
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }
  // Método que se llama cuando se selecciona un archivo
  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.selectedFile = file;
      const base64String = await this.convertFileToBase64(file);

      // Actualizar el formulario con los datos del archivo
      this.secondFormGroup.patchValue({
        uploadFiles: file,
        fileBase64: base64String,
        version: this.extractVersion(file.name)
      });


      this.firstFormGroup.patchValue({
        fileName: file.name
      });

      this.isFileSelected = true;  // Bandera para marcar que se seleccionó un archivo
      this.showDropZone = false;   // Ocultar la zona de arrastre
    }
  }

  // Método para manejar el drop de archivos
  async onFileDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.selectedFile = file;
      const base64String = await this.convertFileToBase64(file);

      // Actualizamos los datos en el formulario
      this.secondFormGroup.patchValue({
        uploadFiles: file,
        fileBase64: base64String,
        version: this.extractVersion(file.name)
      });
      this.isFileSelected = true;
      this.showDropZone = false;
    }
  }

  // Método que maneja la subida y confirmación de la nueva versión
  async onNewVersion(): Promise<void> {
    // Recuperar el archivo en base64 (debe estar presente para subir)
    const { fileBase64, version } = this.secondFormGroup.value;

    // Validar que se haya seleccionado un archivo para subir
    if (!fileBase64) {
      this.notifier.notify('warning', 'Por favor, seleccione un archivo para subir');
      return;
    }

    const { name, description, pakage } = this.data.app;

    // Crear el objeto de aplicación (Aplications) basado en la nueva versión
    const appNew: Aplications = new Aplications;
    appNew.name = name;
    appNew.description = description;
    appNew.pakage = pakage;
    appNew.version = version.trim();
    appNew.base64 = fileBase64;
    appNew.fileName = this.selectedFile ? this.selectedFile.name : '';

    // Crear un objeto FirmwareUpload para hacer la actualización
    let objNew: FirmwareUpload = new FirmwareUpload();
    objNew.fileList = [appNew]; // Añadimos la nueva versión a la lista de archivos

    try {
      // Verificar si ya existe una versión de la aplicación
      const existingVersionIndex = this.versions.findIndex( versionApp =>
        versionApp.version === version && versionApp.pakage === this.data.app.pakage && versionApp.id === this.data.app.id
      );

      if (existingVersionIndex >= 0) {
        // Si existe, reemplazar el registro de la versión existente
        this.versions[existingVersionIndex] = appNew;
      } else {
        // Si no existe, añadirla al inicio
        this.versions.unshift(appNew);
      }

      // Actualizamos en el backend la nueva versión
      await this.appService.addUpdate(objNew);

      // Actualizar dataSource para reflejar el cambio
      this.dataSource.data = [...this.versions];
      this.notifier.notify('success', '¡Nueva versión añadida correctamente!');

      // Reiniciar los estados y limpiar el formulario
      this.isAddingVersion = false;
      this.showDropZone = false;
      this.selectedFile = null; // Limpiar archivo seleccionado

    } catch (error) {
      this.notifier.notify('error', `Error al actualizar`);
    }
  }


  isValidFileType(file: File): boolean {
    return true;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  // Método para borrar el archivo seleccionado
  deleteFile(): void {
    this.selectedFile = null;
    this.secondFormGroup.get('uploadFiles')?.reset();
    this.packageName = '';
  }
  onCheckboxChange(event: MatCheckboxChange): void {
    this.isFileSelected = event.checked;
  }

  formatFileSize(size: number): string {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
