<main id="main" class="main">
  <div class="pagetitle">
    <h1>{{'stadisticsState.title' | translate }}</h1>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
  <div class="mat-elevation-z8 zonaStadisticas">
      <div class="grafica" id="_loadCycle">
          <h1>{{'stadisticsState.loadCycle' | translate }}
          </h1>
          <div id="loadCycle">
              <apx-chart
              [series]="chartOptions_loadCycle.series"
              [chart]="chartOptions_loadCycle.chart"
              [plotOptions]="chartOptions_loadCycle.plotOptions"
              [labels]="chartOptions_loadCycle.labels"
              [fill]="chartOptions_loadCycle.fill"
            ></apx-chart>
          </div>
      </div>

      <div class="grafica" id="_hourActive">
          <h1>{{'stadisticsState.hoursActive' | translate }}
          </h1>
          <div id="hourActive">
              <apx-chart
              [series]="chartOptions_hourActive.series"
              [chart]="chartOptions_hourActive.chart"
              [plotOptions]="chartOptions_hourActive.plotOptions"
              [labels]="chartOptions_hourActive.labels"
              [fill]="chartOptions_hourActive.fill"
            ></apx-chart>
          </div>
      </div>

      <div class="grafica" id="_key">
          <h1>{{'stadisticsState.keystrokes' | translate }}
          </h1>
          <div id="key">
              <apx-chart
              [series]="chartOptions_key.series"
              [chart]="chartOptions_key.chart"
              [plotOptions]="chartOptions_key.plotOptions"
              [labels]="chartOptions_key.labels"
              [fill]="chartOptions_key.fill"
            ></apx-chart>
          </div>
      </div>

      <div class="grafica" id="_printer">
          <h1>{{'stadisticsState.printerLines' | translate }}
          </h1>
          <div id="printer">
              <apx-chart
              [series]="chartOptions_printer.series"
              [chart]="chartOptions_printer.chart"
              [plotOptions]="chartOptions_printer.plotOptions"
              [labels]="chartOptions_printer.labels"
              [fill]="chartOptions_printer.fill"
            ></apx-chart>
          </div>
      </div>
  </div>
</main>