<div class="pagetitle">
  <h1>{{ 'clients.title' | translate }}</h1>
</div>
<section class="section">
  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'clients.name' | translate }}<span class="obligatorio"></span></mat-label>
            <input matInput class="form-field" formControlName="nombre" type="text" required>
            <mat-error *ngIf="registerForm.get('nombre')?.hasError('required')">{{ 'error.required.field' | translate
              }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'clients.cod' | translate }}<span class="obligatorio"></span></mat-label>
            <input matInput class="form-field" formControlName="cod" type="text">
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- Dropdown para seleccionar operadores, visible solo para ADMIN -->
    <div class="row" *ngIf="isAdmin">
      <div class="col-lg-6">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'clients.operator' | translate }}<span class="obligatorio"></span></mat-label>
            <mat-select formControlName="operator">
              <mat-option *ngFor="let operator of operators" [value]="operator.name">
                {{ operator.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'clients.commerceList' | translate }}<span class="obligatorio"></span></mat-label>
            <div class="button-add">
            <input matInput formControlName="commerce" type="text" class="cajaTexto">
          
              <button mat-button (click)="addCommerce()">{{ 'common.add' | translate }}</button>
            </div>
          </mat-form-field>

          <mat-list style="max-height: 270px; overflow: auto;">
            <mat-list-item *ngFor="let link of listCommerce">
              <button mat-icon-button (click)="delete(link)" matListItemMeta> <mat-icon>delete_outline</mat-icon>
              </button>
              <p>{{ link }}</p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>

    <div class="button-group">
      <button mat-button (click)="formExit()">{{ 'common.cancel' | translate }}</button>
      <button mat-button (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
    </div>
  </form>
</section>