import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { MonitoringService } from './monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import moment from 'moment';
import { TabsComponent } from './tabs/tabs.component';
import { Coordinates } from 'src/app/models/coordinates.model';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';

const BaseColumns = {
  view: 'view',
  customId: 'customId',
  manufacID: 'manufacID',
  modelNumber: 'modelNumber',
  nameOperator: 'nameOperator',
  serialNrAtento: 'serialNrAtento',
  softwareRevision: 'softwareRevision',
  commerce: 'commerce',
  puntoVenta: 'puntoVenta',
  lastUpdate: 'lastUpdate',
  coverage: 'coverageLevel',
  pending: 'hasPendingDownload',
};

const { nameOperator, ...Columns } = BaseColumns;
const ColumnsAdmin = { ...BaseColumns };

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'customId', type:'string' },
  { field:'commerce', type:'string' },
  { field:'softwareRevision', type:'string' },
  { field:'modelNumber', type:'string' },
  { field:'manufacID', type:'string' },
  { field:'puntoVenta', type:'string'},
]

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css'],
})

export class MonitoringComponent implements OnInit, AfterViewInit {

  displayedColumns = Object.keys(Columns);
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `monitoring.${column.field}`, column.type, column.options);
    return fielterField;
  })

  dataSource = new MatTableDataSource<VendingMachineAtento>();
  isAdmin: boolean = true;
  pageLength = 0;
  inactiveTerminals = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.customId + "";
  direccion: string | null = "DESC";
  showMap = false;

  filterList: FilterableValue[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private dialogRef!: MatDialogRef<TabsComponent>;


  coordinates: Coordinates[] = [];
  
  constructor(private servicios: MonitoringService,
    private utilServicios: UtilService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public translate:TranslateService
  ) {}


  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  private removeFilterableValue(column:string) {
    const toRemoveIndex = this.filterList.findIndex((filter) => filter.column === column);
    this.filterList.splice(toRemoveIndex,1);
  }
  
  
  async lanzarLLamada() {
    const request = new UsersConsoleRequest;

    const idOperatorFilter = this.getFilterableValue('idOperator');
    if (idOperatorFilter) {
      request.idOperator = idOperatorFilter.value as number;
      this.removeFilterableValue('idOperator');
    }
    
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize};
    const startPage = pageIndex*pageSize;
    const endPage=startPage+pageSize;
    
    request.filterAndPagBean =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      startPage,
      endPage,
      pageIndex
    );

    let value = await this.servicios.findWithCoordinates(request);
    let pagCount = await this.servicios.countTotal(request);
    if (value) {
      this.dataSource = new MatTableDataSource<VendingMachineAtento>(value.data);
      this.coordinates = this.mapDataToCoordinate(value.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
        this.inactiveTerminals = this.pageLength - (pagCount.data.active !== undefined ? pagCount.data.active : 0);
      }
    }
  }

  private getTooltipItems(vm:VendingMachineAtento): { label:string, value:string }[]{
    const tooltipList = [];
    tooltipList.push({ label:this.translate.instant('monitoring.manufacID'), value:vm.manufacID ?? '' });
    tooltipList.push({ label:this.translate.instant('monitoring.modelNumber'), value:vm.modelNumber ?? '' });
    tooltipList.push({ label:this.translate.instant('monitoring.serialNroAtento'), value:vm.serialNrAtento ?? '' });
    tooltipList.push({ label:this.translate.instant('monitoring.softwareRevision'), value:vm.softwareRevision  ?? ''});
    tooltipList.push({ label:this.translate.instant('monitoring.commerce'), value:vm.commerce  ?? ''});
    return tooltipList;
  }

  private mapDataToCoordinate(vmList:VendingMachineAtento[]):Coordinates[]{
    const coordinates:Coordinates[] = [];
    vmList.forEach((vm)=>{
      if(!vm.latitude || !vm.longitude) return;
      coordinates.push(<Coordinates>{
        label: vm.customId,
        type: 'pointer',
        lat: vm.latitude,
        lng: vm.longitude,
        tooltip: this.getTooltipItems(vm)
      })
    });
    return coordinates;
  }

  async cargar() {
    //operadores
    this.utilServicios.findOperators().subscribe({
      next: (operators) => {
        const operationsOptions = operators.data.map((op:Operator)=>{ return { label:op.name, value:op.id }});
        this.filterColumns.push(new FilterField('idOperator', 'monitoring.nameOperator', 'string', operationsOptions));
      }});
  }

  async ngOnInit(): Promise<void> {
    this.isAdmin = await this.utilServicios.isAdmin();
    if (this.isAdmin) {
      this.displayedColumns = Object.keys(ColumnsAdmin);
      await this.cargar();
    }
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isVmOff(row: VendingMachineAtento) {
    let val = false

    if (row.lastUpdate) {
      let parts = row.lastUpdate.toString().split('-');

      let mydate = parts[1] + "-" + parts[0] + "-" + parts[2];
      let fecha1 = moment(new Date(mydate)); // 1st argument - string, 2nd argument - format        
      let fecha2 = moment();

      let diff_h = fecha2.diff(fecha1, 'h'); // Diff in hours

      if (diff_h >= (environment.hoursDesactivo)) { val = true }
    }

    return val;
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  pageEvent() {;
    this.lanzarLLamada();
  }

  resetPaginatorState(){
    this.paginator.firstPage();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".")
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }

    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  toggleMap() {
    this.showMap = !this.showMap;
  }
  onView(row: any) {

    this.dialogRef = this.dialog.open(TabsComponent, {
      width: '90%', height: '95%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });

  }

}
