import { Component, Inject, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Rol } from 'src/app/models/rol.model';
import { Users } from 'src/app/models/users.model';
import { FormCreateActions } from 'src/app/util/constants';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-form-users',
  templateUrl: './form-users.component.html',
  styleUrls: ['./form-users.component.css']
})
export class FormUsersComponent implements OnInit {

  registerForm!: FormGroup;

  selectedRol:Rol = new Rol();
  listaRol:Rol[] = [];
  selectedOpe:Operator = new Operator();
  listaOpe:Operator[] = [];
  isEdit: boolean = false;
  idUser: number | undefined = undefined;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private popupDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  isAdmin: boolean = false;
  private notifier: NotifierService;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,   notifier: NotifierService ,  private services: UsersService,
    private utilServicios : UtilService,private authService:AuthService,
    public dialogRef: MatDialogRef<FormUsersComponent>,
    public dialog: MatDialog) { 
      this.notifier = notifier;
  }


  async cargar() {    

    // Operadores
    this.utilServicios.findOperators().subscribe({
      next: (operators) => {
        this.listaOpe = operators.data;
      },
      error: (err) => {
        console.error('Failed to load operators:', err);
      },
      complete: () => console.log('Operator loading completed.')
    });
  
    // Roles
    this.utilServicios.findRol().subscribe({
      next: (listaRolTemp: Rol[]) => {
        this.listaRol = [];  // Inicializa la lista de roles
        const currentUserRoleId = this.authService.currentUserValue().rol?.id;
  
        listaRolTemp.forEach((element: Rol) => {
          // Si el usuario es Admin, muestra todos los roles
          if (currentUserRoleId === 1) {
            this.listaRol.push(element);  // Añade todos los roles
          } 
          // Si el usuario no es Admin, excluye ADMIN y OPERATOR
          else if (element.id !== 1 && element.id !== 2) {
            // Aquí se supone que ADMIN tiene id = 1 y OPERATOR tiene id = 2
            this.listaRol.push(element);  // Añade solo los roles diferentes a ADMIN y OPERATOR
          }
        });
      },
      error: (error) => {
        console.error('Error al cargar roles:', error);
        this.listaRol = [];  // Maneja el error adecuadamente
      }
    });
  }
  


  async ngOnInit(): Promise<void> {

    const currentUserRoleId = this.authService.currentUserValue().rol?.id;
    this.isAdmin = currentUserRoleId === 1;  // Asumiendo que 1 es Admin
    
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required, 
        Validators.maxLength(45),Validators.pattern(/^[A-ZÀ-ÿa-z\s\-.]*$/)])] ,
      email: ["", Validators.compose([Validators.required, 
        Validators.email, 
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
      ])]      ,
      username: ["", Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(255),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")
      ])],
      identifier: [""] ,
      rolMarcado:[null, Validators.compose([Validators.required])],
      opeMarcado:[null]
    });

   await this.cargar();

   let nombre;
   let nick;
   let email;
   let rolBBDD=null;
   let operatorBBDD=null;
   let identifier=null;
   let password=null;

    if(this.data?.obj){
      this.isEdit = true;
      let usersBBDD:Users
      usersBBDD = this.data.obj;

      if(usersBBDD.rol && this.listaRol){
        this.listaRol.forEach(element => {
              if(element.id==usersBBDD.rol?.id){
                rolBBDD=element
              };
          });
      }
      if(usersBBDD.operator && this.listaOpe){
        this.listaOpe.forEach(element => {
              if(element.id==usersBBDD.operator?.id){
                operatorBBDD=element               
              };
          });
      }
      this.idUser = usersBBDD.id ?? undefined;
      nombre=usersBBDD.name;
      nick=usersBBDD.nickName;
      email=usersBBDD.email;
      identifier=usersBBDD.identification;
      password = usersBBDD.pass;
    }

    
    this.registerForm = this.formBuilder.group({
      name: [nombre, Validators.compose([Validators.required,Validators.pattern(/^[A-ZÀ-ÿa-z\s\-.]*$/)])]      ,
      username: [nick, Validators.compose([Validators.required,Validators.pattern(/^[A-ZÀ-ÿa-z\s\-.]*$/)])]      ,
      email: [email, Validators.compose([Validators.email])],     
      identifier:[identifier],
      rolMarcado:[rolBBDD, Validators.compose([Validators.required])],
      opeMarcado:[operatorBBDD],
      password: [password]
     });

  }




  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let userNew : Users = new Users();

    if(this.isEdit){
      userNew.id = this.idUser;
      userNew.pass = data['password'];
    }
    userNew.name=data['name']  
    userNew.nickName=data['username']   
    userNew.email=data['email']   
    userNew.identification=data['identifier']  
    
    let rolNew:Rol
    let opeNew:Operator
    rolNew=data['rolMarcado']
    opeNew=data['opeMarcado']

    if(rolNew.id!=1 && (!opeNew || opeNew==undefined) ){
      this.notifier.notify('error', 'if the role is different from ADMIN, it must indicate an operator') 
      return;
    }

    userNew.rol=rolNew
    userNew.operator=opeNew

    let userSession = this.authService.currentUserValue();    
    userNew.entorno= userSession.entorno


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Create user`;
    dialogData.textI18n = $localize `Are you sure you want to create this person?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val = await this.services.addUpdate(userNew)
          
          if(val!.status>=0){
            this.notifier.notify('success', 'User create successfully') 
            
            const popUpData = new ConfirmDialogData(); 
            popUpData.titleI18n = $localize `Access User`; 
            popUpData.textI18n = 'User:  '+val?.data.nickName +" - Pass:  "+val?.data.pass;
            popUpData.visibleButtom=false
            this.popupDialogRef= this.dialog.open(ConfirmDialogComponent, {
              width: '400px', panelClass: 'custom-modalbox',
              data: popUpData
            });
  
            this.dialogRef.close(FormCreateActions.SAVED);
          }

      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




