<main id="main" class="main">

  <div class="pagetitle">
    <h1>{{ 'monitoring.title' | translate }}</h1>
    <div>
      <!-- Botón Refrescar -->
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>

      <!-- Botón Map  -->
      <button mat-raised-button class="custom-add-button" (click)="toggleMap()">
        <i class="bi bi-geo-alt"></i> {{ 'monitoring.map' | translate }}
      </button>
    </div>
  </div>

  <div class="map-container" *ngIf="showMap">
    <app-map [coordinates]="coordinates"></app-map>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden>{{ 'monitoring.tableDescription' | translate }}</div>

  <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table class="mat-table" [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">


      <!-- id Column -->
      <ng-container matColumnDef="customId">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header> {{ 'monitoring.customId' | translate }} </div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.customId}} </td>
      </ng-container>
      <!-- Manufacture Column -->
      <ng-container matColumnDef="manufacID">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.manufacID' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.manufacID}}</td>
      </ng-container>
      <!-- modelNumber Column -->
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.modelNumber' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.modelNumber}} ({{element.swRev}})</td>
      </ng-container>
      <!-- nameOperator Column -->
      <ng-container matColumnDef="nameOperator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.nameOperator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.nameOperator}}</td>
      </ng-container>
      <!-- serialNrAtento Column -->
      <ng-container matColumnDef="serialNrAtento">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.serialNroAtento' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.serialNrAtento}}</td>
      </ng-container>
      <!-- softwareRevision Column -->
      <ng-container matColumnDef="softwareRevision">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.softwareRevision' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.softwareRevision}}</td>
      </ng-container>
      <!-- commerce Column -->
      <ng-container matColumnDef="commerce">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.commerce' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.commerce}} ({{element.orderCommerce}})</td>
      </ng-container>
      <!-- puntoVenta Column -->
      <ng-container matColumnDef="puntoVenta">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.puntoVenta' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.puntoVenta}}</td>
      </ng-container>
      <!-- lastUpdate Column -->
      <ng-container matColumnDef="lastUpdate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.lastUpdate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.lastUpdate | dateOnTimeZone | diasDesdeFecha }}</td>
      </ng-container>
      <!-- coverage Column -->
      <ng-container matColumnDef="coverage">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.coverageLevel' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.coverageLevel}}% {{element.coverageType}}</td>
      </ng-container>



      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button">
          <div>
            <button matTooltip="view" (click)="onView(element)" mat-icon-button class="iconoTabla">
              <i class="bi bi-eye"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="pending">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.pending' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="button">
          <div *ngIf="element.hasPending" class="cl-donwload">
            <button matTooltip="Pending file download" mat-icon-button class="iconoTabla ">
              <i class="bi bi-cloud-arrow-down"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'row-OFF': (isVmOff(row)) , 'row-pending': (row.hasPending === true) }"></tr>
    </table>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', {fileName:'Terminal'})"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>
    <span class="text-red"> Inactives: {{ inactiveTerminals }} </span>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>

  </div>

</main>