export interface DynamicColumns {
    [key:string]:string | boolean;
  }

interface RelatedExtraI extends DynamicColumns{
    numSerie:string;
    usado:boolean;
}

export class RelatedExtra implements RelatedExtraI{
    [key: string]: string | boolean;
    numSerie:string;
    usado:boolean;
    constructor(_numSerie:string, _usado:boolean, _jsonData:object){
        this.numSerie = _numSerie;
        this.usado = _usado;
        Object.assign(this, _jsonData);;
    }
}

export interface ParamExtra {
    numSerie:string;
    usado:boolean;
    json:string;
}