import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { SearchMachineBean } from 'src/app/models/searchMachineBean.model';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class MonitoringService {


  
  constructor(private merchantRest : RestService,
    private authService:AuthService) {}
  



  async getMachine(_id: number){
    let _body:VendingMachineAtento = new VendingMachineAtento();
    _body.idVM=_id
          
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getMachine/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async findWithCoordinates(_body: UsersConsoleRequest){
    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }   

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListVendingMachineCoordenadas/',true,true)   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async find(_body: UsersConsoleRequest){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }   
    //getListVendingMachineCoordenadas
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListMachine/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async countTotal(_body: UsersConsoleRequest){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id

      }
    }   
    _body.filterAndPagBean= new FilterAndPagBean(_body.filterAndPagBean?.orderSentido,_body.filterAndPagBean?.order,_body.filterAndPagBean?.filterGeneric,null,null,null);

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListVendingMachineCount/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
  



  
  async serviciosSearchMachineBean(_body: SearchMachineBean){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.operatorID=userSession.operator?.id
      if(userSession.client){
        _body.clientID=userSession.client?.id
      }
    }
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getSearchMachine/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async countSearchMachineBean(_body: SearchMachineBean){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.operatorID=userSession.operator?.id
      if(userSession.client){
        _body.clientID=userSession.client?.id
      }
    }

    _body.filterAndPagBean= new FilterAndPagBean(_body.filterAndPagBean?.orderSentido,_body.filterAndPagBean?.order,_body.filterAndPagBean?.filterGeneric,null,null,null);

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getCountSearchMachine/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


}