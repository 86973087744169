<div class="pagetitle">
    <h1 i18n>Incidence</h1>
</div>
<section class="section">
    <form [formGroup]="registerForm">

        <div class="details-container">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label> Select incidence type<span class="obligatorio"></span></mat-label>
                    <mat-select formControlName="type" [value]="typeSelect">
                        <mat-option value="NULL"> </mat-option>
                        <mat-option value="0">Other</mat-option>
                        <mat-option value="1">Suggestion</mat-option>
                        <mat-option value="2">Telemetry failure</mat-option>
                        <mat-option value="3">Terminal Breakdown</mat-option>
                        <mat-option value="4">Non-active terminal</mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.get('type')?.hasError('required')" i18n>required</mat-error>

                </mat-form-field>
            </div>


            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>Terminal id<span class="obligatorio"></span></mat-label>
                    <input matInput class="form-field" formControlName="numTer" type="text" required>
                    <mat-error *ngIf="registerForm.get('numTer')?.hasError('required')" i18n>required</mat-error>
                </mat-form-field>
            </div>



            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>Operator<span class="obligatorio"></span></mat-label>
                    <mat-select formControlName="operator">
                        <mat-option value="NULL"> </mat-option>
                        <mat-option *ngFor="let operator of operatorList" [value]="operator.id">
                            {{ operator.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.get('operator')?.hasError('required')" i18n>required</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Comment<span class="obligatorio"></span></mat-label>
                        <textarea formControlName="commet" rows="7" matInput placeholder=""></textarea>
                        <mat-error *ngIf="registerForm.get('commet')?.hasError('required')" i18n>required</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isCloseIncidence">
            <div class="col-lg-12">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Comment<span class="obligatorio"></span></mat-label>
                        <textarea formControlName="resolution" rows="7" matInput placeholder=""></textarea>
                        <mat-error *ngIf="registerForm.get('resolution')?.hasError('required')"
                            i18n>required</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>




        <div class="button-group">
            <button mat-button (click)="formExit()">Cancel</button>
            <button mat-button (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
        </div>




    </form>
</section>