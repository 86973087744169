import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FILE_TYPES, FormCreateActions } from 'src/app/util/constants';
import { ParamService } from '../param.service';
import { Parameters } from 'src/app/models/parameters.model';
import { parseBase64Data } from 'src/app/util/util';

@Component({
  selector: 'app-new-param',
  templateUrl: './new-param.component.html',
  styleUrls: ['./new-param.component.css']
})
export class NewParamComponent implements OnInit {
  isLinear = true;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  selectedFile: File | null = null;
  isCloseParam:boolean = false;
  showDropZone = false;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  @Input() dataSource = new MatTableDataSource<Parameters>();
  uploadProgress = 0;
  fileTypes = FILE_TYPES;
  parameters: Parameters[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private service: ParamService,
    public dialog: MatDialog,
    public dialogNewParam: MatDialogRef<NewParamComponent>,
  ) {}

  async ngOnInit(): Promise<void> {

    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      version: ['', Validators.required],
      storageType:['', Validators.required]
    });

    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required],
      fileBase64: [''],
      path:['', Validators.required]
    });

    await this.loadStorageType();
  }

  private async loadStorageType(): Promise<void> {
    const result = await this.service.find({} as any); 
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  
  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const base64String = parseBase64Data(result);
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
        const file = input.files[0];
        this.selectedFile = file;
        const base64String = await this.convertFileToBase64(file);
        this.secondFormGroup.patchValue({
            uploadFiles: this.selectedFile,
            fileBase64: base64String,
        });
        this.secondFormGroup.get('uploadFiles')?.markAsTouched();
        this.showDropZone = false;
    }
  }
  
  
  async onFileDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0];
        this.selectedFile = file;
        const base64String = await this.convertFileToBase64(file);
        this.secondFormGroup.patchValue({
            uploadFiles: this.selectedFile,
            fileBase64: base64String,
        });
        this.secondFormGroup.get('uploadFiles')?.markAsTouched();
        this.showDropZone = false;
    }
}

  
  
  isValidFile(file: File): boolean {
    return true; // Permitir cualquier tipo de archivo
}


  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.secondFormGroup.get('uploadFiles')?.reset();
    this.uploadProgress = 0; 
  }

  uploadFile(file: File): void {
    const uploadInterval = setInterval(() => {
      this.uploadProgress += 10; 
      if (this.uploadProgress >= 100) {
        clearInterval(uploadInterval);
      }
    }, 500);
  }

  onFormSubmit(): void {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || !this.selectedFile) {
      return;
    }

    const formData = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.value,
      selectedFile: this.selectedFile?.name,
      fileBase64: this.secondFormGroup.get('fileBase64')?.value

    };

    let appNew : Parameters = new Parameters
    appNew.name = formData.name; 
    appNew.description = formData.description; 
    appNew.version = formData.version; 
    appNew.base64 = formData.fileBase64;
    appNew.fileName = formData.selectedFile; 
    appNew.storageType = formData.storageType;
    appNew.path = formData.path;


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Nuevo Parámetro`;
    dialogData.textI18n = $localize `¿Estás seguro de que quieres añadir un nuevo parámetro??`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val:any;

          val = await this.service.addUpdate(appNew)          

          if(val!.status>=0){
            this.dialogNewParam.close(FormCreateActions.SAVED);
          }

      }
    });

  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogNewParam.close(FormCreateActions.EXIT);
  }
  

}
