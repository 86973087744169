<div class="pagetitle">
    <h1>{{ 'profiles.new' | translate }}</h1>
</div>

<mat-stepper [linear]="isLinear" #stepper>

    <!-- Details Step -->
    <mat-step [stepControl]="detailsFormGroup">
        <form [formGroup]="detailsFormGroup">
            <div class="pagetitle">
                <h2>{{ 'profiles.details' | translate }}</h2>
            </div>
            <ng-template matStepLabel>{{ 'profiles.details' | translate }}</ng-template>
            <div class="details-container">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'profiles.name' | translate }} <span class="obligatorio"></span></mat-label>
                        <input matInput formControlName="name" placeholder="{{ 'profiles.placeholder.name' | translate }}" required>
                        <mat-error *ngIf="detailsFormGroup.get('name')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'profiles.description' | translate }}<span class="obligatorio"></span></mat-label>
                        <textarea matInput formControlName="description" rows="4" placeholder="{{ 'profiles.placeholder.description' | translate }}"
                            required></textarea>
                        <mat-error *ngIf="detailsFormGroup.get('description')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="button-group">
                <button mat-button matStepperNext [disabled]="detailsFormGroup.invalid">{{ 'common.next' | translate }}</button>
            </div>
        </form>
    </mat-step>

    <!-- Terminals Step -->
    <mat-step [stepControl]="terminalsFormGroup">
        <form [formGroup]="terminalsFormGroup">
            <div class="pagetitle">
                <h2>{{ 'profiles.terminals' | translate }}</h2>
            </div>
            
            <ng-template matStepLabel>{{ 'profiles.terminals' | translate }}</ng-template>

            <div class="terminals-container">
                <div class="card-terminals">
                    <div class="file-upload-container">
                        <label class="upload-label">{{ 'profiles.selectLabel' | translate }}</label>

                        <!-- Buttons to trigger file input -->
                        <div class="file-upload">
                            <button mat-stroked-button (click)="showDropZone = !showDropZone;" [disabled]="hasSelectedFromPortal">
                                {{ 'common.uploadFiles' | translate }}
                            </button>
                            <button mat-stroked-button (click)="openWindows()" [disabled]="hasUploadAFile">
                                <i class="bi bi-search"></i>
                                {{ 'profiles.selectFromPortal' | translate }}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Drag-and-drop zone -->
                <div *ngIf="showDropZone">
                    <label class="upload-label">{{ 'common.uploadFiles' | translate }}</label>
                    <!-- Hidden file input -->
                    <input type="file" class="file-upload" (change)="onFilesSelected($event)" #fileUpload hidden accept=".csv" multiple>
                    
                    <div class="drop-zone" (drop)="onFilesDropTerminals($event)" (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
                        <div class="drop-zone-icon">
                            <mat-icon>cloud_upload</mat-icon>
                            <p>{{ 'common.selectFiles' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div *ngIf="dataSource.data.length">
                    <p>{{ 'profiles.selectedTerminals' | translate }}: {{ dataSource.data.length }}</p>
                </div>
            </div>

            <div class="button-group">
                <button mat-button matStepperPrevious>
                    {{ 'common.back' | translate }}
                </button>
                <button mat-button matStepperNext [disabled]="terminalsFormGroup.invalid">
                    {{ 'common.next' | translate }}
                </button>
            </div>
        </form>
    </mat-step>

    <!-- Apps Step -->
    <mat-step [stepControl]="appsFormGroup">
        <form [formGroup]="appsFormGroup">
            <div class="pagetitle">
                <h2>{{ 'profiles.aplications' | translate }}</h2>
            </div>
            <ng-template matStepLabel>{{ 'profiles.aplications' | translate }}</ng-template>

            <div class="apps-container">
                <!-- Select for Apps and Versions -->
                <div class="app-version-row">
                    <mat-form-field appearance="outline" class="select-app-field">
                        <mat-label>{{ 'profiles.selectApps' | translate }}</mat-label>
                        <mat-select (selectionChange)="onAppSelect($event)" formControlName="selectedApp">
                            <mat-option *ngFor="let app of appsBBDD" [value]="app">
                                {{ app.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="select-version-field">
                        <mat-label>{{ 'profiles.selectVersions' | translate }}</mat-label>
                        <mat-select (selectionChange)="onVersionSelect($event)" formControlName="selectedVersion">
                            <mat-option *ngFor="let app of versionBBDD" [value]="app">
                                {{ app.version }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Parameters and File Upload Area -->
                <div class="parameter-file-row" *ngFor="let selectedApp of resultListApp; let i = index">
                    <app-new-perfil-app [data]="selectedApp" (remove)="removeApp($event)" [parameters]="parameters"></app-new-perfil-app>
                </div>
            </div>

            <div class="button-group">
                <button mat-button matStepperPrevious>
                    {{ 'common.back' | translate }}
                </button>
                <button mat-button matStepperNext
                    [disabled]="appsFormGroup.invalid || resultListApp.length === 0 || !isAppSelectionValid()">
                    {{ 'common.next' | translate }}
                </button>
            </div>
        </form>
    </mat-step>

    <!-- Distance Step -->
    <mat-step [stepControl]="distanceFormGroup">
        <form [formGroup]="distanceFormGroup">
            <div class="pagetitle">
                <h2>{{ 'profiles.distance' | translate }}</h2>
            </div>
            <ng-template matStepLabel>{{ 'profiles.distance' | translate }}</ng-template>
            <div class="distance-container">
                <!-- Slide toggle on top -->
                <mat-slide-toggle formControlName="enableLocation" class="distance-toggle">
                    {{ 'profiles.distanceLocation' | translate }}
                </mat-slide-toggle>
                <!-- Flex container for Distance and Units -->
                
                <div class="d-flex my-3">
                    <!-- Distance -->
                    <div class="w-50 mx-2">
                    <label class="title-xs">{{ 'profiles.distance' | translate }}</label>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="distanceLocation" type="number" placeholder="Enter distance">
                    </mat-form-field>
                    </div>
        
                    <!-- Distance Unit -->
                    <div class="w-50 mx-2">
                    <label class="title-xs">{{ 'profiles.units' | translate }}</label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="distanceUnit">
                        <mat-option value="km">{{'common.kilometers' | translate }}</mat-option>
                        <mat-option value="m">{{'common.meters' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="button-group">
                <button mat-button matStepperPrevious>
                    {{ 'common.back' | translate }}
                </button>
                <button mat-button (click)="onFinalSubmit()" [disabled]="distanceFormGroup.invalid">
                    {{ 'common.submit' | translate }}
                </button>
            </div>
        </form>
    </mat-step>

    <!-- Icon overrides -->
    <ng-template matStepperIcon="number">
        <mat-icon>radio_button_unchecked</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="checked">
        <mat-icon>check_circle</mat-icon>
    </ng-template>

</mat-stepper>