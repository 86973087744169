import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder,  FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Groups } from 'src/app/models/groups.model';
import { FormCreateActions } from 'src/app/util/constants';
import { GroupsService } from '../groups.service';
import { Operator } from 'src/app/models/operator.model';
import { NotifierService } from 'angular-notifier';
import { UtilService } from 'src/app/services/util.service';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';

@Component({
  selector: 'app-form-groups',
  templateUrl: './form-groups.component.html',
  styleUrls: ['./form-groups.component.css']
})
export class FormGroupsComponent implements OnInit {
  registerForm: FormGroup;
  desactivado:boolean = false;  

  groupBBDD:Groups = new Groups

  listCommerceSelect:VendingMachineAtento[] | undefined;
  listaTerminal:VendingMachineAtento[] | undefined;
  listaOpe:Operator[] = [];
  selectedOpe:Operator = new Operator();

  private notifier: NotifierService;

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: GroupsService,  notifier: NotifierService , 
    private utilServicios : UtilService,   
    private terminalService: MonitoringService,
    public dialogRef: MatDialogRef<FormGroupsComponent>,
    public dialog: MatDialog) { 
      this.notifier = notifier;
      
        const defaultOperator = this.utilServicios.getOperator(); 
        const opeMarcadoControl = this.utilServicios.isAdmin() ? 
        [null, Validators.required] :
        [{ value: defaultOperator, disabled: true }, Validators.required];

this.registerForm = this.formBuilder.group({
name: ["", Validators.required],
cod: [""],
opeMarcado: opeMarcadoControl
});
  }


  async cargar(){
     //operadores
     this.utilServicios.findOperators().subscribe({
      next: (operators) => {
        this.listaOpe = operators.data;
      },
      error: (err) => {
        console.error('Failed to load operators:', err);
      },
      complete: () => console.log('Operator loading completed.')
    }); 
    
  }

  compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }


  async ngOnInit(): Promise<void> {
  


   await this.cargar();
    let nombre,  cod;
    let operatorBBDD=null;
    if(this.data?.obj){
      //si entra es EDICION
      this.desactivado=true;

      this.groupBBDD = await this.services.getItem(this.data.obj.id);
      if(this.groupBBDD){
          
          this.listCommerceSelect =  this.groupBBDD.listVendingMachine
          
          if(this.groupBBDD.idOperator){
                this.listaOpe.forEach(element => {
                  if(element.id==this.groupBBDD.idOperator){
                    operatorBBDD=element               
                  };
              });
          }

          if(operatorBBDD){
            this.selectOrg(operatorBBDD)
          }

          nombre=this.groupBBDD.name;
          cod=this.groupBBDD.cod;
          this.registerForm = this.formBuilder.group({
            name: [nombre, Validators.compose([Validators.required] )],
            cod: [cod],
            opeMarcado:[{value: operatorBBDD, disabled:this.desactivado}, Validators.compose([Validators.required])],
           
          });
      }
    }

    
    


  }
  async selectOrg(orgSelect:Operator){
    //Terminal
      let rfOrg: UsersConsoleRequest = new UsersConsoleRequest;   
      if(orgSelect)   {
        rfOrg.idOperator = orgSelect.id
      } 
      let srOrg = await this.terminalService.find(rfOrg);

      this.listaTerminal = srOrg?.data 

  }

  async selectTerminal(terSelect:VendingMachineAtento){
      console.log(terSelect); 
      
      if(!this.listCommerceSelect){
          this.listCommerceSelect=[]
      }
    


      if (this.check(this.listCommerceSelect,terSelect)) { 
        this.notifier.notify('error', 'This commerce already exists') 
        return;
      }

      this.listCommerceSelect.push(terSelect)
  }


  check(arr:VendingMachineAtento[], el:VendingMachineAtento) {
    return arr.some( element => element.customId === el.customId)
  }

  deleteVMSelect(text:VendingMachineAtento): void {

    if(this.listCommerceSelect){
      const index = this.listCommerceSelect.indexOf(text);
      if (index > -1) { // only splice array when item is found
        this.listCommerceSelect.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
  }
  

  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let groupsNew : Groups = new Groups();

    groupsNew.name=data['name']  
    groupsNew.cod=data['cod']  
    
    let orgNew:Operator

    orgNew=data['opeMarcado']
    if(orgNew){
        groupsNew.idOperator=orgNew.id
        groupsNew.idEntorno=orgNew.idEntorno
    }

    if(this.groupBBDD?.id) {
      groupsNew.id=this.groupBBDD.id     
      groupsNew.idOperator=this.groupBBDD.idOperator
      groupsNew.idEntorno=this.groupBBDD.idEntorno
    }

    if(this.listCommerceSelect){
      this.listCommerceSelect.forEach(e => {
          delete e.lastUpdate;
          delete e.lastConect;
          delete e.dateCreate;
          delete e.dateInstall;
      });
    }

//alert(JSON.stringify(groupsNew))
    groupsNew.listVendingMachine=this.listCommerceSelect


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Create Group`;
    dialogData.textI18n = $localize `Are you sure you want to create it?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          
          let val:any;
          val = await this.services.addUpdate(groupsNew)        

          if(val!.status>=0){
            this.notifier.notify('success', 'Group successfully')              
            this.dialogRef.close(FormCreateActions.SAVED);
          }

      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




