<div class="pagetitle">
    <h2>{{ 'profiles.aplications' | translate }}</h2>
</div>

<div class="apps-container">
    <!-- Select for Apps and Versions -->
    <div class="d-flex">
        <mat-form-field appearance="outline" class="select-app-field mx-2">
            <mat-label>{{ 'profiles.selectApps' | translate }}</mat-label>
            <mat-select (selectionChange)="onAppSelect($event)">
                <mat-option *ngFor="let app of appsBBDD" [value]="app">
                    {{ app.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="select-version-field mx-2">
            <mat-label>{{ 'profiles.selectVersions' | translate }}</mat-label>
            <mat-select (selectionChange)="onVersionSelect($event)">
                <mat-option *ngFor="let app of versionBBDD" [value]="app">
                    {{ app.version }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Parameters and File Upload Area -->
    <div class="parameter-file-row" *ngFor="let selectedApp of resultListApp; let i = index">
        <app-new-perfil-app [data]="selectedApp" (remove)="removeApp($event)" [parameters]="parameters"></app-new-perfil-app>
    </div>
</div>

<div class="d-flex justify-content-between mt-3">
    <button mat-stroked-button (click)="cancelChanges()">
        {{ 'common.cancel' | translate }}
    </button>
    <button mat-stroked-button (click)="submitChanges()" [disabled]="!resultListApp.length">
        {{ 'common.submit' | translate }}
    </button>
</div>