<div class="pagetitle">
    <h1>{{ 'newAlert.title' | translate }}</h1>      
</div>
<section class="section">
    <form [formGroup]="registerForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.operator' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select [compareWith]="compareFn" formControlName="opeMarcado">
                            <mat-option *ngFor="let op of listaOpe" [value]="op">
                                {{ op.name }}
                            </mat-option>
                        </mat-select>    
                        <mat-error *ngIf="registerForm.get('opeMarcado')?.hasError('required')" i18n>{{ 'common.required' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.type' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="type">
                            <mat-option value="20">{{ 'newAlert.typeList.battery' | translate }}</mat-option>
                            <mat-option value="21">{{ 'newAlert.typeList.hour' | translate }}</mat-option>
                            <mat-option value="22">{{ 'newAlert.typeList.key' | translate }}</mat-option>
                            <mat-option value="23">{{ 'newAlert.typeList.print' | translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('type')?.hasError('required')" i18n>{{ 'common.required' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.operation' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="operacion">
                            <mat-option value=">">{{ 'newAlert.operationType.greater' | translate }}</mat-option>
                            <mat-option value="<">{{ 'newAlert.operationType.less' | translate }}</mat-option>
                            <mat-option value="=">{{ 'newAlert.operationType.equal' | translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('operacion')?.hasError('required')" i18n>{{ 'common.required' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.value' | translate }}</mat-label>
                        <input formControlName="value" matInput type="text">
                        <mat-error *ngIf="registerForm.get('value')?.hasError('required')" i18n>{{ 'common.valueRequired' | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('value')?.hasError('pattern')" i18n>{{ 'common.valuePattern' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.priority' | translate }}</mat-label>
                        <mat-select formControlName="priority">
                            <mat-option value="3">{{ 'newAlert.priorityType.high' | translate }}</mat-option>
                            <mat-option value="2">{{ 'newAlert.priorityType.medium' | translate }}</mat-option>
                            <mat-option value="1">{{ 'newAlert.priorityType.low' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="button-group">
            <button mat-button (click)="formExit()">{{ 'common.cancel' | translate }}</button>
            <button mat-button (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button> 
        </div>
    </form>
</section>