<main id="main" class="main">
  <div class="pagetitle">
    <h1>{{ 'incidences.title' | translate }}</h1>
    <div>
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i>  {{'common.refresh' | translate }}
      </button>

       <button mat-raised-button class="custom-add-button"  (click)="onNew()">
        <i class="bi bi-plus"></i> {{ 'incidences.newIncidence' | translate }} 
      </button>
    </div>
  </div>
  
  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden> {{'alerts.tableDescription' | translate }} </div>

  <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table matTableExporter [dataSource]="dataSource" aria-describedby="tableDescription" matSort (matSortChange)="announceSortChange($event)"
      #exporter="matTableExporter">

      <!-- customID Column -->
      <ng-container matColumnDef="customID">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header > {{ 'incidences.customID' | translate }} </div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.customID}}">
          {{element.customID}} </td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header >{{ 'incidences.type' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.type === 0" >Other</span>
          <span *ngIf="element.type === 1" >Suggestion</span>
          <span *ngIf="element.type === 2" >Telemetry failure</span>
          <span *ngIf="element.type === 3" >Terminal Breakdown</span>
          <span *ngIf="element.type === 4" >Non-active terminal</span>
        </td>
      </ng-container>
      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header >{{ 'incidences.dateCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>
      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header > {{ 'incidences.description' | translate }} </div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.description}}">
          {{element.description}} </td>
      </ng-container>
      <!-- userCreate Column -->
      <ng-container matColumnDef="userCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'incidences.userCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.userCreate?.name}}</td>
      </ng-container>
      <!-- resolution Column -->
      <ng-container matColumnDef="resolution">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header >{{ 'incidences.resolution' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.resolution}}">
          {{element.resolution}} </td>
      </ng-container>
      <!-- dateDelete Column -->
      <ng-container matColumnDef="dateDelete">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'incidences.dateDelete' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateDelete | dateOnTimeZone:true }}</td>
      </ng-container>
      <!-- userDelete Column -->
      <ng-container matColumnDef="userDelete">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'incidences.userDelete' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.userDelete?.name}}</td>
      </ng-container>


      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button" style=" width: 90px;">
          <div>
            <button *ngIf="!element.dateDelete" matTooltip="Close" (click)="onClose(element)" mat-icon-button
              class="iconoTabla">
              <i class="bi bi-gear"></i>
            </button>
            <button *ngIf="!element.dateDelete" matTooltip="Edit" (click)="onEdit(element)" mat-icon-button
              class="iconoTabla">
              <i class="bi bi-pencil"></i>
            </button>
            <button matTooltip="view terminal" (click)="onView(element)" mat-icon-button class="iconoTabla">
              <i class="bi bi-calculator"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', {fileName:'Pending'})"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent()" 
      showFirstLastButtons 
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>