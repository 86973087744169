import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Client } from 'src/app/models/client.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class ClientService {
  
  constructor(
    private merchantRest : RestService, private authService:AuthService,
    public commonService: CommonService) {
    }
    

    async find(_body:UsersConsoleRequest ){

      let userSession = this.authService.currentUserValue();
      _body.idEntorno=userSession!.entorno!.id
      if(userSession.operator){
        _body.id=userSession!.operator!.id
      }
  
      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListClient/',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }
  

    async countTotal( _body:UsersConsoleRequest ){
           
      let userSession = this.authService.currentUserValue();
      _body.idEntorno=userSession!.entorno!.id
      if(userSession.operator){
        _body.id=userSession!.operator!.id
      }

      _body.filterAndPagBean= new FilterAndPagBean(_body.filterAndPagBean?.orderSentido,_body.filterAndPagBean?.order,_body.filterAndPagBean?.filterGeneric,null,null,null);

      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'countClient/',true,true)   
        .catch(err => {
          return null
        })
        
      return value;   
    }


    async getItem(id:number ){

      let _body: Client = new Client;
      _body.id=id

      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getClient/',true,true )   
        .catch(err => {
          return null
        })
        
      return value?.data;   
    }
  


    async addUpdate(_body:Client){
           
      let userSession = this.authService.currentUserValue();
      _body.idEntorno=userSession!.entorno!.id

      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addClient/',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }

   

}