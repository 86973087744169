export function promiseTimeout(ms: number, promise: Promise<any>) {
    let id:any
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
        id = setTimeout(() => {
            clearTimeout(id);
            reject('Timed out in ' + ms + 'ms.')
        }, ms)
    })

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ]).then((result) => {
        clearTimeout(id)
        // We also need to pass the result back
        return result
    })
}

export function base64DecToArr(str: string): Uint8Array {
    return Uint8Array.from(atob(str), c => c.charCodeAt(0))
}

export function getBase64(file:File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

export function parseBase64Data(data:string){
    let splittedData = data.split(",");
    if(splittedData.length === 2) {
        return splittedData[1]+"";
    } else {
        splittedData = data.split("base64");
        return splittedData[1]+"";
    }
}

export function downloadFile(fileName:string, file:string){
    const fileBlob = new Blob([base64DecToArr(file)]);
    const fileURL = URL.createObjectURL(fileBlob);
    const downloadButton:HTMLAnchorElement = document.createElement('a');
    downloadButton.href=  fileURL;
    downloadButton.setAttribute('download', fileName);
    downloadButton.click();
}

const CSV_CELLS_SEPARATOR = /,|;\W*/g;

interface DefaultObject { [key: string]: string; };
function setRowAsHeader(row: string, headers: string[]): void {
    row.split(CSV_CELLS_SEPARATOR).forEach((cell) => {
        headers.push(cell);
    });
}

function setRowAsCells(row: string, headers: string[], result: any[]): void {
    let newObj:DefaultObject = {};
    let splittedRow = row.split(CSV_CELLS_SEPARATOR);
    if(splittedRow.length < headers.length) return;
    splittedRow.forEach((cell, i) => {
        newObj[headers[i]] = cell ?? "";
    });
    result.push(newObj);
}

export function parseCSVStringDataToArray(fileText: string) {
    const rows = fileText.split('\r\n');
    let headers: string[] = [];
    let newArray: DefaultObject[] = [];
    rows.forEach((row: string, index: number) => {
        const validationEmpty = row.replace(CSV_CELLS_SEPARATOR,"");
        if(!validationEmpty) return;
        if (index === 0) {
            setRowAsHeader(row, headers);
        } else {
            setRowAsCells(row, headers, newArray)
        }
    });
    return newArray;
}

// Método para convertir archivos en JSON
export function convertFileToJson(file: File): Promise<DefaultObject[]> {
return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
    const result = reader.result as string;
    const parsedResult = parseCSVStringDataToArray(result);
    resolve(parsedResult);
    };
    reader.onerror = error => reject(error);
});
}

export function isStringBlank(str: string | null | undefined): boolean {
    return !str || str.trim().length === 0;
}

export function parseStringToUTCDate(value:string): Date | string{
    if(!value) return "";
    const [ date, time ] = value.split(" ");
    const [ day, month, year ] = date.split("-");
    const [ hour, min, secs ] = time.split(":");
    const utcDate = Date.UTC(Number(year), Number(month)-1, Number(day), Number(hour), Number(min), Number(secs)); 

    return new Date(utcDate); 
}

export class ByteUtil {
    private static readonly decoder = new TextDecoder("utf-8")
    private static readonly encoder = new TextEncoder()

    static bytesToStr(buf: BufferSource) {
        return this.decoder.decode(buf)
    }

    static strToBytes(str: string) {
        return this.encoder.encode(str);
    }

    /* Little Endian es el protocolo por defecto con el Touchless, pero en algunos sitios como la actualización
    de firmware se hace siguiendo el protocolo XMODEM-1k que usa Big Endian. Por eso permitimos por parametro la configuracion
    */
    static toBytesInt32(num: number, littleEndian = true) {
        let arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
        let view = new DataView(arr);
        view.setUint32(0, num, littleEndian); // byteOffset = 0; litteEndian = true
        return new Uint8Array(arr);
    }

    static toBytesInt16(num: number, littleEndian = true) {
        let arr = new ArrayBuffer(2); // an Int16 takes 2 bytes
        let view = new DataView(arr);
        view.setUint16(0, num, littleEndian); // byteOffset = 0; litteEndian = true
        return new Uint8Array(arr);
    }

    static toBytesInt8(num: number) {
        let arr = new ArrayBuffer(1); // an Int16 takes 1 byte
        let view = new DataView(arr);
        view.setUint8(0, num); // byteOffset = 0
        return new Uint8Array(arr);
    }

    static bytesToHex(buffer: Uint8Array): string { // buffer is an ArrayBuffer
        return Array.prototype.map.call(buffer, x => ('00' + x.toString(16)).toUpperCase().slice(-2)).join('');
    }

    static toUInt8Array(value: string){
        let result = [];
        for(let i = 0; i < value.length; i+=2)
        {
            result.push(parseInt(value.substring(i, i + 2), 16));
        }
        return Uint8Array.from(result)
    }
 
}