import { Component, AfterViewInit, Input } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { Coordinates } from 'src/app/models/coordinates.model';


const DefaultIcon = L.icon({
  iconUrl: 'assets/img/leaflet/alfiler-de-mapa.png',
  iconRetinaUrl: 'assets/img/leaflet/alfiler-de-mapa.png',
  shadowUrl: 'assets/img/leaflet/marker-shadow.png',
  iconSize: [32, 37],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
const EvseIcon = L.icon({
  iconUrl: 'assets/img/leaflet/estacion-electrica.png',
  iconRetinaUrl: 'assets/img/leaflet/estacion-electrica.png',
  shadowUrl: 'assets/img/leaflet/marker-shadow.png',
  iconSize: [32, 37],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const LocationIcon = L.icon({
  iconUrl: 'assets/img/leaflet/marcador-de-posicion.png',
  iconRetinaUrl: 'assets/img/leaflet/marcador-de-posicion.png',
  shadowUrl: 'assets/img/leaflet/marker-shadow.png',
  iconSize: [32, 37],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit {
  private map: L.Map | undefined;
  private circles: L.Circle[] = [];
  private zoomThreshold = 10;

  @Input() coordinates: Coordinates[] = [];

  constructor() { }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [40, -4],  // Centrando entre España y Portugal
      zoom: 7,
      minZoom: 3,
      maxBounds: [[-85, -180], [85, 180]],
      maxBoundsViscosity: 1.0,
      worldCopyJump: false,
      attributionControl: false
    });

    L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      maxZoom: 19,
      attribution: '',
    }).addTo(this.map);

    this.addMarkers();

    // Escuchar el evento de cambio de zoom
    this.map.on('zoomend', () => {
      this.handleZoomLimit();
    });
  }

  private handleZoomLimit(): void {
    const currentZoom = this.map!.getZoom();
    if (currentZoom < 3) {
      this.map!.setZoom(3);
    }
  }

  private addMarkers(): void {
    const markers = L.markerClusterGroup({
      iconCreateFunction: (cluster) => {
        return L.divIcon({
          html: `<img src="${DefaultIcon.options.iconUrl}" style="width: 32px; height: 37px;">`,
          className: 'custom-cluster-icon',
          iconSize: [32, 37],
          iconAnchor: [12, 41]
        });
      }
    });

    this.coordinates.forEach(coord => {
      let icon;
      switch (coord.type) {
        case 'evse':
          icon = EvseIcon;
          break;
        case 'location':
          icon = LocationIcon;
          break;
        default:
          icon = DefaultIcon;
          break;
      }

      const marker = L.marker([coord.lat, coord.lng], { icon: icon });

      marker.bindPopup(`
        <b>${coord.label}</b><br>
        ${this.getTooltipItems(coord)}
      `);

      markers.addLayer(marker);

    });

    this.map!.addLayer(markers);
  }

  private getTooltipItems(coor:Coordinates): string {
    let result = '';
    if(coor.tooltip){
      coor.tooltip.forEach((item)=>{
        result += `<u>${item.label}:</u> ${item.value} <br>`;
      });
    }
    return result;
  }

}