import { coveragePeriod } from "./coveragePeriod.model";
import { DataPeriod } from "./dataPeriod.model";

export class StadisticSensor {
		listCoverage!: coveragePeriod[]
		listData!: DataPeriod[]
}



