export class Operator {

  id: number | undefined;
  name!: string ;
  cod!: string;
	idEntorno: number | undefined;

}


