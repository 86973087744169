import { FilterAndPagBean } from "./FilterAndPagBean";

export class Pending {

    id!: number;
    dateCreate: Date | undefined;
    idOperator: number | undefined;
	idClient: number | undefined;
	idEntorno: number | undefined;
    sendJson: string | undefined;
    retries: number | undefined;

	filterAndPagBean: FilterAndPagBean | undefined;

	idVM: number | undefined;
	typeSend: string | undefined;
	idUser: number | undefined;
	codError: number | undefined;
	idFile: number | undefined;
	idOriginal: number | undefined;
	horaIni: string | undefined;
	horaDuration: string | undefined;
	setMachine:boolean = false;

	lastBlock: number | undefined;
	numBlocks: number | undefined;
	dateLastBlock: Date | undefined;
	
	nameMachine: string | undefined;
}


