import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Users } from 'src/app/models/users.model';
import { UsersProfileService } from './users-profile.service';
import { NotifierService } from 'angular-notifier';
import { LangTypes, LanguageService } from 'src/app/services/language.service';


class UserProfile {
  name?:string;
  email?:string;
  phone?:string;
  lang?:LangTypes;
  rolName?:string;
  nickName?:string;
}

@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.css']
})
export class UsersProfileComponent implements OnInit {
  registerForm!: FormGroup;
  registerFormPass!: FormGroup;
  userSession?: Users;
  passNocoincide:boolean = false;
  languages: LangTypes[];
  userProfile:UserProfile = new UserProfile;
    
  constructor(private authService: AuthService, 
    private notifier: NotifierService, 
    private formBuilder:FormBuilder,
    private usersProfileService: UsersProfileService,
    private languageService:LanguageService) { 
      this.userSession = this.authService.currentUserValue();
      this.languages = this.languageService.availableLangs;
  }

  ngOnInit(): void {
    if (this.userSession) {
      this.userProfile = this.setUserProfile(this.userSession);
    }
    
    this.registerForm = this.formBuilder.group({
      firstname: [this.userProfile.name, Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern("^[A-ZÀ-ÿa-z\\s\\-.]*$")])],    
      email: [this.userProfile.email, Validators.compose([Validators.required,
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")])],     
      phone: [this.userProfile.phone],
      lang:[this.userProfile.lang]
    });

    this.registerFormPass = this.formBuilder.group({      
      newpass: ["", Validators.compose([Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{8,20}$/))])],
      repeatpass: ["", Validators.compose([Validators.required, 
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{8,20}$/))])]
    });
  }

  setUserProfile(user:Users): UserProfile {
    const userProfile:UserProfile = new UserProfile;
    userProfile.name = user.name;
    userProfile.email = user.email;
    userProfile.phone = user.phone;
    userProfile.lang = user.lang as LangTypes;
    userProfile.nickName = user.nickName;
    userProfile.rolName = user.rol?.name;
    return userProfile;
  }

  async onFormChangePass(){
    this.passNocoincide=false
    console.log(this.registerFormPass);
    
    if (this.registerFormPass.invalid) {
      return;
    }
   
    const data = this.applyFormats(this.registerFormPass);
    let newpass=data['newpass']
    let repeatpass=data['repeatpass']

      if(newpass!=repeatpass){
        this.passNocoincide=true
        return;
      }

      if(this.userSession){      
        this.userSession.pass=newpass   
        
        let result = await this.usersProfileService.editProfile(this.userSession)
        if(result!.status>=0){
          this.notifier.notify('success', 'Firmware uploaded successfully')  
        }   
      }     

  }





  async onFormSubmit(){

    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }   

    const data = this.applyFormats(this.registerForm);
    let firstname=data['firstname']
    let email=data['email']
    let phone=data['phone']
    let lang=data['lang']


    if(this.userSession){      
      this.userSession.name=firstname
      this.userSession.email=email
      this.userSession.phone=phone
      this.userSession.lang=lang      
      
      let result = await this.usersProfileService.editProfile(this.userSession)
      if(result!.status>=0){
        this.notifier.notify('success', 'Firmware uploaded successfully');
        this.languageService.setLanguage(this.userSession.lang as LangTypes);
        this.userProfile = this.setUserProfile(this.userSession); 
      }   
    }
  }


  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
