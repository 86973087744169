import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef, MatLegacyDialog as MatDialog, } from '@angular/material/legacy-dialog';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { TranslateModule } from '@ngx-translate/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { environment } from 'src/environments/environment';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { PerfilesService } from '../perfiles.service';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Profile } from 'src/app/models/profiles.model';

const FILTER_COLUMNS:{ field:string, type: FieldType}[] = [
  { field:'id', type:'long' },
  { field:'idAtento', type:'long' },
  { field:'modelNumber', type:'long' },
]

@Component({
  standalone:true,
  imports:[CommonModule, 
    TranslateModule, 
    MatLegacyListModule, 
    MatLegacyButtonModule, 
    MatIconModule, 
    MatPaginatorModule,
    FilterComponent
  ],
  selector: 'app-list-related-machines',
  templateUrl: './list-related-machines.component.html',
  styleUrls: ['./list-related-machines.component.css']
})
export class ListRelatedMachinesComponent implements OnInit {
  machines:VendingMachineAtento[] = [];
  availableMachines:VendingMachineAtento[] = [];
  
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `vendingMachine.${column.field}`, column.type);
    return fielterField;
  });
  filterList: FilterableValue[] = [];
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data:{ profile:Profile },
    private dialogRef:MatLegacyDialogRef<ListRelatedMachinesComponent>,
    private dialog:MatDialog,
    private monitoringService:MonitoringService,
    private profileService:PerfilesService
  ){}
 
  private async loadProfileMachines(){
    const filterAndPagBean = this.getFilterAndPagBean();
    const result = await this.profileService.getVendingMachines(this.data.profile.id, filterAndPagBean);
    if (result?.status === 0){
      this.machines = this.mapMachines(result.data);
      this.pageLength = this.machines.length;
    }
  }

  private async loadAvailableMachines(): Promise<void> {
    const result = await this.monitoringService.find({} as any);
    if (result?.status === 0) this.availableMachines = result.data;
  }

  async ngOnInit(): Promise<void> {
    await this.loadAvailableMachines();
    this.loadProfileMachines();
  }

  private mapMachines(vendingMachines:VendingMachineAtento[]):VendingMachineAtento[]{
    return vendingMachines.map((vm)=>{
      const machineDetail = this.availableMachines.find((availableVM:VendingMachineAtento)=>{ return availableVM.id === vm.id });
        return {...vm, customId: machineDetail?.customId ?? '' };
    })
  }

  getFilterAndPagBean(): FilterAndPagBean {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return new FilterAndPagBean(null,null,this.filterList,startPage,endPage,pageIndex);
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.loadProfileMachines();
  }

  public delete(item:VendingMachineAtento){
    const itemIndex = this.machines.findIndex((machine)=>{ return JSON.stringify(machine) === JSON.stringify(item) });
    if(itemIndex >= 0) this.machines.splice(itemIndex, 1);
    this.pageLength = this.machines.length;
  }

  public pageEvent(){
    this.loadProfileMachines();
  }

  public cancelChanges(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData;
    dialogData.enableCancel = false;
    dialogData.titleI18n = "Guardar cambios";
    dialogData.textI18n = "Esta por modificar el perfil, ¿está seguro?";

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM) this.updateProfile();
    });
  }

  public async updateProfile(){
    const profile = this.data.profile;
    profile.listVendingMachine = [...this.machines];
    profile.dateCreate = profile.dateCreate ? new Date(profile.dateCreate) : new Date;
    profile.dateUpdate = profile.dateUpdate ? new Date(profile.dateUpdate) : new Date;
    const response = await this.profileService.saveProfile(profile);
    if(response!.status >= 0 ) this.dialogRef.close({ result:1 });
  }
}

