<div class="pagetitle">
    <h2>{{ 'profiles.terminals' | translate }}</h2>
</div>

<div class="terminals-container">
    <div class="card-terminals">
        <div class="file-upload-container">
            <label class="upload-label">{{ 'profiles.selectLabel' | translate }}</label>
            <!-- Buttons to trigger file input -->
            <div class="d-flex justify-content-around my-3">
                <button mat-stroked-button class="w-100 mx-3" (click)="showDropZone = !showDropZone;" [disabled]="hasSelectedFromPortal">
                    {{ 'common.uploadFiles' | translate }}
                </button>
                <button mat-stroked-button class="w-100 mx-3" (click)="openSelectMachine()" [disabled]="hasUploadAFile">
                    <i class="bi bi-search"></i>
                    {{ 'profiles.selectFromPortal' | translate }}
                </button>
            </div>
        </div>
    </div>

    <!-- Drag-and-drop zone -->
    <div class="mx-4" *ngIf="showDropZone">
        <label class="upload-label">{{ 'common.uploadFiles' | translate }}</label>
        <!-- Hidden file input -->
        <input type="file" class="file-upload" (change)="onFilesSelected($event)" #fileUpload hidden accept=".csv" multiple>
        
        <div class="drop-zone" (drop)="onFilesDropTerminals($event)" (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
            <div class="drop-zone-icon">
                <mat-icon>cloud_upload</mat-icon>
                <p>{{ 'common.selectFiles' | translate }}</p>
            </div>
        </div>
    </div>

    <div *ngIf="machines.length">
        <p>{{ 'profiles.selectedTerminals' | translate }}: {{ machines.length }}</p>
    </div>
</div>

<div class="d-flex justify-content-between mt-3">
    <button mat-stroked-button (click)="cancelChanges()">
        {{ 'common.cancel' | translate }}
    </button>
    <button mat-stroked-button (click)="submitChanges()" [disabled]="!machines.length">
        {{ 'common.submit' | translate }}
    </button>
</div>