<div class="pagetitle">
    <h1>{{ 'newApp.title' | translate }}</h1>
</div>

<mat-stepper [linear]="isLinear" #stepper>

    <!-- Paso 1: Detalles -->
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>{{ 'newApp.step1' | translate }}</ng-template>

            <div class="details-container">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newApp.name' | translate }} <span class="obligatorio"></span></mat-label>
                        <input matInput formControlName="name" placeholder="{{ 'newApp.namePlaceholder' | translate }}" required>
                        <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">
                            {{ 'newApp.nameRequired' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group-inline">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'newApp.packageName' | translate }} <span class="obligatorio"></span></mat-label>
                            <input matInput formControlName="packageName" placeholder="{{ 'newApp.packagePlaceholder' | translate }}" required>
                            <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('required')">
                                {{ 'newApp.packageRequired' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newApp.description' | translate }} <span class="obligatorio"></span></mat-label>
                        <textarea matInput formControlName="description" rows="4" placeholder="{{ 'newApp.descriptionPlaceholder' | translate }}" required></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="button-group">
                <button mat-button matStepperNext>{{ 'common.next' | translate }}</button>
            </div>
        </form>
    </mat-step>

    <!-- Paso 2: Archivos -->
    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>{{ 'newApp.step2' | translate }}</ng-template>

            <div class="terminals-container">
                <!-- Zona de carga de archivos -->
                <div class="file-upload-container">
                    <div class="drop-zone" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
                        <div class="drop-zone-icon">
                            <mat-icon>cloud_upload</mat-icon>
                            <p>{{ 'newApp.fileUploadInstruction' | translate }}</p>
                        </div>
                    </div>
                    <input type="file" class="file-upload" (change)="onFileSelected($event)" #fileUpload
                        style="display: none;" accept="*/*" multiple>
                </div>

                <!-- Nombre del archivo seleccionado y botón de eliminar -->
                <div *ngIf="selectedFile" class="file-name-container">
                    <p>{{ 'common.selectedFile' | translate }}: {{ selectedFile.name }}</p>
                    <button mat-icon-button class="delete-button" (click)="deleteFile()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <!-- Campo "Versión" -->
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newApp.version' | translate }}</mat-label>
                        <input matInput formControlName="version" placeholder="{{ 'newApp.versionPlaceholder' | translate }}" />
                    </mat-form-field>
                </div>
            </div>

            <!-- Botones para navegación -->
            <div class="button-group">
                <button mat-button matStepperPrevious>{{ 'common.back' | translate }}</button>
                <button mat-button (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
            </div>
        </form>
    </mat-step>

</mat-stepper>
