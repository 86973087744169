import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Aplications } from 'src/app/models/aplications.model';
import { AppsService } from '../aplications.service';
import { FormCreateActions } from 'src/app/util/constants';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectMachineWindowComponent } from 'src/app/pages/comun/select-machine-window/select-machine-window.component';

@Component({
  selector: 'app-new-app',
  templateUrl: './new-app.component.html',
  styleUrls: ['./new-app.component.css']
})
export class NewAppComponent implements OnInit {
  isLinear = true;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  selectedFile: File | null = null;
  showDropZone = false;
  private dialogRef!: MatDialogRef<SelectMachineWindowComponent>;
  @Input() dataSource = new MatTableDataSource<Aplications>();
  uploadProgress = 0;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  isCloseParam: boolean = false;
  dialogNewParam: any;
  packageName: string = '';



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: AppsService,
    public dialog: MatDialog,
    public dialogNewApp: MatDialogRef<NewAppComponent>,
  ) { }

  async ngOnInit(): Promise<void> {

    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      packageName: ['', Validators.required],
      
    });

    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required],
      fileBase64: [''],
      version: ['']
    });
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  async onFileDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0];
        this.selectedFile = file;
        const base64String = await this.convertFileToBase64(file);
        this.secondFormGroup.patchValue({
            uploadFiles: this.selectedFile,
            fileBase64: base64String,
            version: this.extractVersion(file.name) // Extraer y establecer la versión
        });
        this.secondFormGroup.get('uploadFiles')?.markAsTouched();
        this.showDropZone = false;
    }
}

  isValidFile(file: File): boolean {
    return true; // Permitir cualquier tipo de archivo
}


async onFileSelected(event: Event): Promise<void> {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.selectedFile = file;
      const base64String = await this.convertFileToBase64(file);
      this.secondFormGroup.patchValue({
          uploadFiles: this.selectedFile,
          fileBase64: base64String,
          version: this.extractVersion(file.name) // Extraer y establecer la versión
      });
      this.secondFormGroup.get('uploadFiles')?.markAsTouched();
      this.showDropZone = false;
  }
}

private extractVersion(fileName: string): string {
  const versionPattern = /(\d+\.\d+\.\d+[-\w]*)/; // para encontrar la versión
  const match = fileName.match(versionPattern);
  return match ? match[0] : ''; // Devuelve la versión si se encuentra, sino devuelve una cadena vacía
}

  
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.secondFormGroup.get('uploadFiles')?.reset();
    this.packageName = '';
  }


  async onFormSubmit(): Promise<void> {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || !this.selectedFile) {
      return;
    }

    const formData = {
      ...this.firstFormGroup.getRawValue(), // Incluye los valores deshabilitados
      ...this.secondFormGroup.value,
      selectedFile: this.selectedFile?.name,
      fileBase64: this.secondFormGroup.get('fileBase64')?.value,
      version: this.secondFormGroup.get('version')?.value
    };

    let objNew: FirmwareUpload = new FirmwareUpload();
    let lstAppNew: Aplications[] = [];
    let appNew: Aplications = new Aplications();
    appNew.name = formData.name;
    appNew.description = formData.description;
    appNew.dateUpdate = formData.dateUpdate;
    appNew.fileName = formData.selectedFile;
    appNew.pakage = formData.packageName; 
    
    appNew.version = formData.version;
    appNew.base64 = formData.fileBase64;
    lstAppNew[0] = appNew;
    objNew.fileList = lstAppNew;

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize`Nueva app`;
    dialogData.textI18n = $localize`¿Estás seguro de que quieres añadir una nueva app?`;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        console.log('Form SAVE action');

        let val: any;
        val = await this.service.addUpdate(objNew);

        if (val!.status >= 0) {
          this.dialogNewApp.close(FormCreateActions.SAVED);
        }
      }
    });

    console.log('Final Data:', objNew);
  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogNewApp.close(FormCreateActions.EXIT);
  }
}
