import { Component, Inject, OnInit ,ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  isAdmin: boolean = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private utilService: UtilService) { }

  tituloTerminal!:string | undefined 

  tabIndex = 0 ;

  changeTab(event: { index: number; }){
    console.log(event.index)
    this.tabIndex = event.index;
  }


  ngOnInit(): void {
    if(this.data?.obj){
        this.tituloTerminal =this.data.obj.customId
    }
    this.isAdmin = this.utilService.isAdmin();
  }

}
