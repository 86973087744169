<section class="section" style="width: 99%;">
    <form [formGroup]="registerForm">

        <div class="pagetitle">
            <h1 i18n> {{ 'information.title' | translate }}</h1>
        </div>

        <fieldset class="edit-container bordered">
            <legend class="details-header">
                <label class="title-small">{{ 'information.titleH3' | translate }}</label>
            </legend>
            <div class="edit-content">

                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.instalation' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="dateCreateField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterInstalationDate' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.terminalNum' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="identificadorField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterTerminalNum' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.serialNum' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="serialNumberField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterSerialNum' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.manufacture' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="manufacIDField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterManufacture' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.model' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="modelField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterModel' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.swVersion' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="swRevVMField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterSwVersion' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.commerce' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="commerceField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterCommerce' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.terminalOr' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="orderCommerceField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterTerminalOrder' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.alias' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="nameField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterAlias' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.salePoint' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="puntoVentaField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterSalePoint' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.adress' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="locationField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterAddress' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

        <div class="pagetitle">
            <h1 i18n> <br>{{ 'information.titleTerm' | translate }} </h1>
        </div>

        <fieldset class="edit-container bordered">
            <legend class="details-header">
                <label class="title-small">{{ 'information.titleH3Term' | translate }}</label>
            </legend>
            <div class="edit-content">

                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.serialNumber' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="serialNrAtentoField" matInput type="text"
                                    placeholder="{{ 'information.placeholders.enterSerialNumber' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.imei' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="imeiField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterImei' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.swVersion' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="swRevField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterSwVersion' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.ipAddress' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="ipField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterIpAddress' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.macBT' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="macBTField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterMacBT' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label class="title-xs">{{ 'information.sim' | translate }}</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="simField" matInput type="text" 
                                    placeholder="{{ 'information.placeholders.enterSim' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

        <div class="button-group">
            <!-- <button class="btn-cancelar" (click)="formExit()" i18n>{{ 'common.cancel' | translate }}</button>-->
            <button class="btn btn-aceptar" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
        </div>
    </form>
</section>
