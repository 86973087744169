import { Component, OnInit,Inject,ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { Pending } from 'src/app/models/pending.model';
import { environment } from 'src/environments/environment';
import { PendingService } from './pending.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { NumberInput } from '@angular/cdk/coercion';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { TabsComponent } from '../../views/monitoring/tabs/tabs.component';
import { MonitoringService } from '../../views/monitoring/monitoring.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';


enum Columns {
  view = 'view',
  nameMachine = 'nameMachine',
  typeSend = 'typeSend', 
  sendJson = 'sendJson',
  dateCreate = 'dateCreate',
  retries= 'retries',
  codError = 'codError',
  setMachine = 'setMachine',
  horaIni='horaIni',
  numBlocks = 'numBlocks',
}


const TYPE_OPTIONS:FilterOption[] = [
  { value:"10", label: "File" },
  { value:"1", label: "Firmware" },
]

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'nameMachine', type:'string' },
  { field:'typeSend', type:'string', options:TYPE_OPTIONS},
  { field:'dateCreate', type:'date' },
  { field:'retries', type:'number' },
  { field:'setMachine', type:'string' },
  { field:'numBlocks', type:'number'},
]

@Component({
  selector: 'app-pending-tasks',
  templateUrl: './pending-tasks.component.html',
  styleUrls: ['./pending-tasks.component.css']
})
export class PendingTasksComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);
  
  dataSource = new MatTableDataSource<Pending>();
  canOpenModal = true;
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId:string | null = Columns.dateCreate+"" ;
  direccion:string | null ="DESC";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private dialogRef!: MatDialogRef<TabsComponent>;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  // Filters config
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `pendingTasks.${column.field}`, column.type, column.options);
    return fielterField;
  })
  filterList: FilterableValue[] = [];
  inactiveTerminals = 0;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private notifier: NotifierService, 
    private servicios : PendingService,
    public dialog: MatDialog,
    public commonService : CommonService,
    private monitoringService : MonitoringService) { 
       this.canOpenModal = !data || Object.keys(data).length === 0;  

  }

  async lanzarLLamada(){
    let idVM = null;
    if(this.data?.obj){
      idVM = this.data.obj.id;
    }

    const paginatorState = this.getPaginatorState();
    const request =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    let value = await this.servicios.find(idVM , request);
    let pagCount = await this.servicios.countTotal(idVM , request);
    console.log(JSON.stringify(value))
    if(value){
      this.dataSource = new MatTableDataSource<Pending>(value.data);
      if(pagCount){
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }


  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  verProcentaje(row:Pending) {
    let val: NumberInput = 0
    if(row.lastBlock && row.numBlocks){
      val = row.lastBlock * 100 / row.numBlocks
    }
    return val;
  }

  refrescar() {
    this.lanzarLLamada();
  }

  async onDelete(row:Pending) {
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize `Delete File`;
    dialogData.textI18n = $localize `Are you sure you want to delete it?`;
  
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {        
            let result= await this.servicios.delete(row.id)

            if(result!.status>=0){
              this.notifier.notify('success', 'File deleted successfully') 
              this.lanzarLLamada();
            }

      } else if (result === ConfirmDialogActions.CANCEL) {
          console.log('CANCEL recived from dialog window');
      }
    });   
  }

  async onReset(row:Pending) {
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize `Reset Task`;
    dialogData.textI18n = $localize `Are you sure you want to reset it?`;
 
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {        
            let result= await this.servicios.resetPendingMachine(row.id)

            if(result!.status>=0){
              this.notifier.notify('success', 'Pending reset successfully') 
              this.lanzarLLamada();
            }

      } else if (result === ConfirmDialogActions.CANCEL) {
          console.log('CANCEL recived from dialog window');
      }
    });    
  }

  async getMachine(row: any): Promise<any> {
    return await this.monitoringService.getMachine(row.idVM);
  }

  onView(row: any): void {
    this.getMachine(row).then(data => {
      this.dialogRef = this.dialog.open(TabsComponent, {
        width: '90%',
        height: '95%',
        panelClass: 'custom-modalbox-big',
        data: { obj: data.data }
      });

      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          console.log('CONFIRM received from dialog window');
        } else if (result === 0) {
          console.log('CANCEL received from dialog window');
        }
      });
    }).catch(error => {
      console.error('Error fetching data: ', error);
    });
  }

}
