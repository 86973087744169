<main id="main" class="main">
  <div class="pagetitle">
    <h1>{{ 'stadisticsFunctionally.title' | translate }}</h1>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
  <div class="mat-elevation-z8 zonaStadisticas">
    <div class="grafica" id="_ticketPinting">
      <h1>{{ 'stadisticsFunctionally.ticketPrinting' | translate }}
        <button (click)="redimensionar('_ticketPinting')" class="botonExpander">
          <i class="bi bi-arrows-angle-expand expand"></i>
          <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
        </button>
      </h1>
      <div id="ticketPinting">
        <apx-chart [series]="chartOptions_ticketPinting.series" [chart]="chartOptions_ticketPinting.chart"
          [labels]="chartOptions_ticketPinting.labels" [responsive]="chartOptions_ticketPinting.responsive"></apx-chart>
      </div>
    </div>

    <div class="grafica" id="_reader">
      <h1>{{ 'stadisticsFunctionally.reader' | translate }}
        <button (click)="redimensionar('_reader')" class="botonExpander">
          <i class="bi bi-arrows-angle-expand expand"></i>
          <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
        </button>
      </h1>
      <div id="reader">
        <apx-chart [series]="chartOptions_reader.series" [chart]="chartOptions_reader.chart"
          [stroke]="chartOptions_reader.stroke" [dataLabels]="chartOptions_reader.dataLabels"
          [plotOptions]="chartOptions_reader.plotOptions" [xaxis]="chartOptions_reader.xaxis"
          [colors]="chartOptions_reader.colors" [fill]="chartOptions_reader.fill" [yaxis]="chartOptions_reader.yaxis"
          [legend]="chartOptions_reader.legend"></apx-chart>
      </div>
    </div>

    <div class="grafica" id="_hostComm">
      <h1>{{ 'stadisticsFunctionally.host' | translate }}
        <button (click)="redimensionar('_hostComm')" class="botonExpander">
          <i class="bi bi-arrows-angle-expand expand"></i>
          <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
        </button>
      </h1>
      <div id="hostComm">
        <apx-chart [series]="chartOptions_hostComm.series" [chart]="chartOptions_hostComm.chart"
          [xaxis]="chartOptions_hostComm.xaxis" [dataLabels]="chartOptions_hostComm.dataLabels"
          [yaxis]="chartOptions_hostComm.yaxis" [colors]="chartOptions_hostComm.colors"
          [legend]="chartOptions_hostComm.legend" [fill]="chartOptions_hostComm.fill"></apx-chart>
      </div>
    </div>

    <div class="grafica" id="_consuption">
      <h1>{{ 'stadisticsFunctionally.consuption' | translate }}
        <button (click)="redimensionar('_consuption')" class="botonExpander">
          <i class="bi bi-arrows-angle-expand expand"></i>
          <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
        </button>
      </h1>
      <div id="consuption">
        <apx-chart [series]="chartOptions_consuption.series" [chart]="chartOptions_consuption.chart"
          [xaxis]="chartOptions_consuption.xaxis" [dataLabels]="chartOptions_consuption.dataLabels"
          [yaxis]="chartOptions_consuption.yaxis" [colors]="chartOptions_consuption.colors"
          [legend]="chartOptions_consuption.legend" [fill]="chartOptions_consuption.fill"></apx-chart>
      </div>
    </div>

    <div class="grafica" id="_aveTime">
      <h1>{{ 'stadisticsFunctionally.averageOperationTime' | translate }}
        <button (click)="redimensionar('_aveTime')" class="botonExpander">
          <i class="bi bi-arrows-angle-expand expand"></i>
          <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
        </button>
      </h1>
      <div id="aveTime">
        <apx-chart [series]="chartOptions_aveTime.series" [chart]="chartOptions_aveTime.chart"
          [xaxis]="chartOptions_aveTime.xaxis" [dataLabels]="chartOptions_aveTime.dataLabels"
          [grid]="chartOptions_aveTime.grid" [stroke]="chartOptions_aveTime.stroke"
          [title]="chartOptions_aveTime.title"></apx-chart>
      </div>
    </div>

    <div class="grafica" id="_accepted">
      <h1>{{ 'stadisticsFunctionally.acceptedTransactions' | translate }}
        <button (click)="redimensionar('_accepted')" class="botonExpander">
          <i class="bi bi-arrows-angle-expand expand"></i>
          <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
        </button>
      </h1>
      <div id="accepted">
        <apx-chart [series]="chartOptions_accepted.series" [chart]="chartOptions_accepted.chart"
          [labels]="chartOptions_accepted.labels"
           [colors]="chartOptions_accepted.colors"
          [responsive]="chartOptions_accepted.responsive"></apx-chart>
      </div>
    </div>

    <div class="grafica" id="_transaction">
      <h1>{{ 'stadisticsFunctionally.transactions' | translate }}
        <button (click)="redimensionar('_transaction')" class="botonExpander">
          <i class="bi bi-arrows-angle-expand expand"></i>
          <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
        </button>
      </h1>
      <div id="transaction">
        <apx-chart [series]="chartOptions_transaction.series"
         [chart]="chartOptions_transaction.chart"
          [xaxis]="chartOptions_transaction.xaxis" 
          [dataLabels]="chartOptions_transaction.dataLabels"
          [grid]="chartOptions_transaction.grid"           
          [colors]="chartOptions_transaction.colors"
          [stroke]="chartOptions_transaction.stroke"
          [title]="chartOptions_transaction.title"></apx-chart>
      </div>
    </div>
  </div>
</main>