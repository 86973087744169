import { FilterAndPagBean } from "./FilterAndPagBean";

export class Client {

  id: number | undefined;
  name!: string ;
  cod!: string;
	idEntorno: number | undefined;
  listCommerce!:string[];
  operator!: string;
  filterAndPagBean: FilterAndPagBean | undefined;

}


