import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent {
  userProfile: object | undefined;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService) {

  }


  login() {
    this.authService.login();
  }

}
