

export enum TIPE_CARD {
    TIPE_CHIP_OK= "EMV ok",
    TIPE_CLESS_OK="Cless ok",
    TIPE_KEY_OK="Manual ok",
    TIPE_BAND_OK="Stripe ok",
    TIPE_CHIP_KO="EMV err",
    TIPE_CLESS_KO="Cless err",
    TIPE_KEY_KO="Manual err",
    TIPE_BAND_KO="Stripe err"
}

export enum COLOR_TIPE_CARD {
  TIPE_CHIP_OK= "#B8860B",
  TIPE_CLESS_OK="#556B2F",
  TIPE_KEY_OK="#00008B",
  TIPE_BAND_OK="#2F4F4F",
  TIPE_CHIP_KO="#DAA520",
  TIPE_CLESS_KO="#808000",
  TIPE_KEY_KO="#ADD8E6",
  TIPE_BAND_KO="#778899"
}



export enum COMM_REPORT {
  COMM_OK= "Primary Correct",
  COMM_KO="Primary Error",
  SEC_COMM_OK="Second Correct",
  SEC_COMM_KO="Second Error",
}


export enum TICKET {
  PRINT= "Printed",
  MAIL="By e-mail",
  SMS="By SMS",
  NO="Without",
}
