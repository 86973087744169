import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SelectMachineWindowComponent } from 'src/app/pages/comun/select-machine-window/select-machine-window.component';
import { Aplications } from 'src/app/models/aplications.model';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamService } from '../../param/param.service';
import { AppsService } from '../../aplications/aplications.service';
import { FormCreateActions } from 'src/app/util/constants';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData, ConfirmDialogActions } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { PerfilApp, PerfilAppSelected } from 'src/app/models/perfilApp.model';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';
import { AuthService } from 'src/app/auth/auth.service';
import { convertFileToJson } from 'src/app/util/util';

interface DefaultObject {
  [key: string]: string;
}

@Component({
  selector: 'app-new-perfiles',
  templateUrl: './new-perfiles.component.html',
  styleUrls: ['./new-perfiles.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class NewPerfilesComponent implements OnInit {
  @Input() dataSource = new MatTableDataSource<VendingMachineAtento>();
  isLinear = true;
  showDropZone = false;
  hasUploadAFile:boolean = false;
  hasSelectedFromPortal:boolean = false;
  dialogRef!: MatDialogRef<SelectMachineWindowComponent>;

  // Form groups
  detailsFormGroup!: FormGroup;
  terminalsFormGroup!: FormGroup;
  appsFormGroup!: FormGroup;
  distanceFormGroup!: FormGroup;

  // Variables for apps and parameters
  appsBBDD: Aplications[] = [];
  versionBBDD: Aplications[] = [];
  resultListApp: PerfilAppSelected[] = [];

  parameters: Parameters[] = [];
  selectedFiles: File[] = [];
  selectedAppFiles: File[] = [];
  selectedParameters: Parameters | null = null;
  currentFile: File | null = null;
  selectedAppsList: any[] = [];
  snackBar: any;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  file!: File;
  currentParameter: any = null;
  displayedColumns: any[] = [];
  filterList: FilterableValue[] = [];


  ;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog, private authService: AuthService,
    public dialogNewProfile: MatDialogRef<NewPerfilesComponent>,
    private appsService: AppsService,
    private paramService: ParamService,
    private perfilService: PerfilesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.initForms();
    await this.loadAvailableApps();
    await this.loadParameters();
  }

  private initForms(): void {

    this.detailsFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.terminalsFormGroup = this.formBuilder.group({
      uploadFiles: [Validators.required],
      fileBase64: ['']
    });

    this.appsFormGroup = this.formBuilder.group({
      selectedApp: ['', Validators.required],
      selectedVersion: ['', Validators.required],
      selectedParameter: [''],
      uploadFiles: [[]]
    });

    this.distanceFormGroup = this.formBuilder.group({
      enableLocation: [true, Validators.required],
      distanceLocation: [0, Validators.required],
      distanceUnit: ['m', Validators.required]
    });
  }

  private async loadAvailableApps(): Promise<void> {
    // Limpiar la lista de aplicaciones antes de cargar nuevas
    this.appsBBDD = [];
  
    // Obtenemos la sesión del usuario actual y agregamos el filtro de 'idOperator' si existe
    let userSession = this.authService.currentUserValue();
    if (userSession?.operator) {
      this.filterList.push(new FilterableValue("idOperator", userSession.operator.id + "", "long", OPERATION_FILTER.EQUALS));
    }
    this.filterList.push(new FilterableValue("isLast", "true", "boolean", OPERATION_FILTER.EQUALS));
  
    // Crear el objeto request de tipo FilterAndPagBean sin paginación
    const request = new FilterAndPagBean(null,null,this.filterList,0,0,0);
  
    // llamada a la API para obtener los datos de las aplicaciones
    const result = await this.appsService.find(request);
  
    // Si la llamada fue exitosa, almacenamos los datos obtenidos
    if (result?.status === 0) {
      this.appsBBDD = result.data;
    }
  }
  

  private async loadParameters(): Promise<void> {
    const result = await this.paramService.find({} as any);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  async onAppSelect(event: any): Promise<void> {
    let app = event.value;
  
    // Limpiar versiones y parámetros anteriores antes de cargar nuevos datos
    this.versionBBDD = [];
    this.selectedParameters = null; // Limpiar parámetros seleccionados anteriores

    // Limpiar la lista de filtros antes de agregar uno nuevo
    this.filterList = []; // Aquí limpiamos la lista para evitar concatenaciones indeseadas

    this.filterList.push(new FilterableValue("pakage", app.pakage, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
  
    // Crear el objeto request de tipo FilterAndPagBean sin paginación
    const request = new FilterAndPagBean(null,null,this.filterList,0,0,0);
  
    // Realizamos la llamada a la API para obtener las versiones de la app filtrada
    const result = await this.appsService.find(request);
  
    // Si la llamada es exitosa, almacenamos los datos obtenidos
    if (result?.status === 0) {
      this.versionBBDD = result.data;
    }
}

  

  onVersionSelect(event: any) {
    let version = event.value;

    // Comprobar si version.id ya está en resultListApp
    const exists = this.resultListApp.some(perfil => perfil.pakage === version.pakage);
    if(exists){
      this.notifier.notify('warning', 'No es posible agregar 2 veces una aplicación');
      return
    }     

    let perfilApp: PerfilAppSelected = new PerfilAppSelected();
    perfilApp.idApp = version.id;
    perfilApp.appName = version.name;
    perfilApp.pakage = version.pakage;
    perfilApp.version = version.version;
    this.resultListApp.push(perfilApp);  
  }


  // Función para verificar si al menos uno (archivo o parámetro) está seleccionado
  checkIfCanProceed(appSelect: PerfilAppSelected): void {
    const perfilApp = this.resultListApp.find(pa => pa.idApp === appSelect.idApp);

    if (perfilApp && (perfilApp.idParam || perfilApp.paramExtra)) {
      this.enableNextStep();
    } else {
      this.disableNextStep();
    }
  }

  // Función para habilitar el siguiente paso
  enableNextStep(): void {
    this.appsFormGroup.get('selectedApp')?.setErrors(null);
  }

  // Función para deshabilitar el siguiente paso
  disableNextStep(): void {
    this.appsFormGroup.get('selectedApp')?.setErrors({ 'required': true }); // Bloquear el paso
  }

  isAppSelectionValid(): boolean {
    // Recorre las aplicaciones seleccionadas y verifica si al menos una tiene un parámetro o archivo
    for (let app of this.resultListApp) {
      if (!app.idParam && !app.paramExtra) {
        // Si ninguna de las condiciones se cumple, retorna false
        return false;
      }
    }
    // Si todas las aplicaciones tienen un parámetro o archivo, retorna true
    return true;
  }

  removeApp(app: PerfilApp): void {
    this.resultListApp = this.resultListApp.filter(a => a.idApp !== app.idApp);
  }

  openWindows(): void {
    this.dialogRef = this.dialog.open(SelectMachineWindowComponent, {
      width: '90%', height: '95%', panelClass: 'custom-modalbox-big'
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result === 1) {
        console.log('CONFIRM received from dialog window');
        let dataSourceTemp = new MatTableDataSource<VendingMachineAtento>(result.data);
        if (dataSourceTemp) {
          const objects: VendingMachineAtento[] = dataSourceTemp.data;
          if (objects) {
            this.dataSource.data = [...this.dataSource.data, ...objects];
          }
        }

        this.showDropZone = false;
        this.hasSelectedFromPortal = true;
      } else {
        console.log('CANCEL received from dialog window');
      }
    });
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  onFilesSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      files.filter(file => this.isValidExcelFile(file)).forEach(async (file) => {
        let filesData: DefaultObject[] = await convertFileToJson(file);
        filesData.forEach((fileData) => {
          const { terminal, fabricante, modelo } = fileData;
          const vendingMachine: VendingMachineAtento = new VendingMachineAtento;
          vendingMachine.idAtento = terminal;
          vendingMachine.manufacID = fabricante;
          vendingMachine.modelNumber = modelo;
          this.dataSource.data.push(vendingMachine);
        });
      });
      this.hasUploadAFile = true;
    } else {
      this.notifier.notify('error', 'Please upload a valid CSV file.');
    }
  }

  onFilesDropTerminals(event: any) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.onFilesSelected({ target: { files: [file] } });
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  isValidExcelFile(file: File): boolean {
    const allowedExtensions = ['csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }
  removeFile(file: File): void {
    this.selectedFiles = this.selectedFiles.filter(f => f !== file);
    this.appsFormGroup.patchValue({ uploadFiles: this.selectedFiles });
  }


  deleteFile(): void {
    this.selectedFiles = [];
    this.terminalsFormGroup.get('uploadFiles')?.reset();
  }

  onFinalSubmit(): void {
    if (this.detailsFormGroup.invalid || this.terminalsFormGroup.invalid || this.appsFormGroup.invalid || this.distanceFormGroup.invalid) {
      return;
    }

    const formData = {
      ...this.detailsFormGroup.value,
      ...this.terminalsFormGroup.value,
      ...this.appsFormGroup.value,
      ...this.distanceFormGroup.value,
      selectedFile: this.selectedFiles.map(file => file.name)
    };

    let objNew: Profile = new Profile();
    objNew.name = formData.name;
    objNew.description = formData.description;
    objNew.listVendingMachine = [];
    objNew.enableLocation = formData.enableLocation;
    objNew.distanceLocation = formData.distanceLocation;
    objNew.distanceUnit = formData.distanceUnit;
    objNew.dateCreate = new Date();
    objNew.dateUpdate = new Date();

    if (this.dataSource?.data && this.dataSource.data.length > 0) {
      objNew.listVendingMachine = this.dataSource.data.map((vm) => {
        const vendingMachine: VendingMachineAtento = new VendingMachineAtento;
        vendingMachine.id = vm.id ?? undefined;
        vendingMachine.idAtento = vm.idAtento ?? undefined;
        vendingMachine.manufacID = vm.manufacID ?? undefined;
        vendingMachine.modelNumber = vm.modelNumber ?? undefined;
        return vendingMachine;
      });
    }

    objNew.listApp = this.resultListApp.map(app => app.mapToPerfilApp());

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize`Confirm Profile Creation`;
    dialogData.textI18n = $localize`Are you sure you want to create a new profile?`;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let response: any;
        response = await this.perfilService.saveProfile(objNew);
        if (response?.status >= 0) {
          this.dialogNewProfile.close(FormCreateActions.SAVED);
        }
      }
    });
  }

}
