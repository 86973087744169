import { Component, ViewChild, OnInit } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexMarkers,
  ApexXAxis,
  ApexPlotOptions,
  ApexTitleSubtitle,
} from "ng-apexcharts";

import { SensorService } from './sensores.service';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { StadisticSensor } from 'src/app/models/stadisticSensor.model';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  labels: string[];
  stroke: any;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis,
  tooltip: ApexTooltip,
  plotOptions: ApexPlotOptions;
  colors: string[]
};

@Component({
  selector: 'app-sensores',
  templateUrl: './sensores.component.html',
  styleUrls: ['./sensores.component.css'],
})
export class SensoresComponent implements OnInit {

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { series: [], chart: { type: 'line' } };

  @ViewChild("chart2") chart2!: ChartComponent;
  public chartOptions2: Partial<ChartOptions2> | any = { series: [], chart: { type: 'line' } };

  constructor(private sensorService: SensorService) {}

  ngOnInit(): void {
    this.loadSensorData();
  }

  async loadSensorData() {
    const stadisticBean = new StadisticBean();
    const sensorData: StadisticSensor = await this.sensorService.getSensors(stadisticBean);
    
    
    this.initializeChartOptions(sensorData);
    this.initializeChartOptions2(sensorData);
  }

  initializeChartOptions(sensorData: StadisticSensor): void {
    this.chartOptions = {
      series: [
        {
          name: "TX Data",
          type: "line",
          data: sensorData.listData.map(item => [item.period!.getTime(), item.txData ?? 0])
        },
        {
          name: "RX Data",
          type: "line",
          data: sensorData.listData.map(item => [item.period!.getTime(), item.rxData ?? 0])
        },
        {
          name: "Total Data",
          type: "area",
          data: sensorData.listData.map(item => [item.period!.getTime(), item.dataTotal ?? 0])
        }
      ],
      chart: {
        height: 350,
        type: "line",
        stacked: false
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth"
      },
      plotOptions: {
        bar: {
          columnWidth: "50%"
        }
      },
      fill: {
        opacity: [0.85, 0.25, 0.85],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      labels: sensorData.listData.map(item => new Date(item.period!).toLocaleDateString()),
      markers: {
        size: 0
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        title: {
          text: "Consumo (MG)"
        },
        labels: {
          formatter: function (value: number) {
            return `${value.toFixed(0)}MG`;
          }
        },
        min: 0
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y: number) {
            if (typeof y !== "undefined") {
              return `${y.toFixed(0)} MG`;
            }
            return y;
          }
        }
      }
    };
  }
  
  initializeChartOptions2(sensorData: StadisticSensor): void {
    const boxPlotData = sensorData.listCoverage.map(item => ({
      x: item.period!.getTime(),
      y: [
        item.min ?? 0,
        item.min_2g ?? 0,
        item.average ?? 0,
        item.max_4g ?? 0,
        item.max ?? 0
      ]
    }));
  
    const coverageSeries = [
      {
        name: '3G',
        type: 'line',
        data: sensorData.listCoverage.map(item => [item.period!.getTime(), item.average_4g ?? 0])
      },
      {
        name: '2G',
        type: 'line',
        data: sensorData.listCoverage.map(item => [item.period!.getTime(), item.average_2g ?? 0])
      },
      {
        name: '4G',
        type: 'line',
        data: sensorData.listCoverage.map(item => [item.period!.getTime(), item.average_4g ?? 0])
      }
    ];
  
    this.chartOptions2 = {
      series: coverageSeries,
      chart: {
        height: 350,
        type: "line"
      },
      colors: ['#008FFB', '#FEB019', '#FF4560'],
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: function (val: string | number | Date) {
            return new Date(val).toLocaleDateString();
          }
        }
      },
      yaxis: {
        title: {
          text: "Cobertura (%)"
        },
        labels: {
          formatter: function (value: number) {
            return `${value.toFixed(0)}%`;
          }
        },
        min: 0,
        max: 100
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y: number) {
            if (typeof y !== "undefined") {
              return `${y.toFixed(0)}%`;
            }
            return y;
          }
        }
      }
    };
  }
  
}
