<main id="main" class="main">
    <div class="pagetitle">
        <h1>{{ 'historyUser.title' | translate }}</h1>
        <button mat-raised-button class="custom-white-button" (click)="refrescar()">
            <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
          </button>
    
    </div>

    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div id="tableDescription" hidden>{{ 'historyUser.tableDescription' | translate }}</div>

    <div class="mat-elevation-z8 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="button" style="  width: 110px;">
                    <div>
                        <button matTooltip="view" (click)="onView(element)" mat-icon-button class="iconoTabla">
                            <i class="bi bi-eye"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- idVm Column -->
            <ng-container matColumnDef="idVM">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.idVM' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.idVM}} </td>
            </ng-container>
            <!-- idUser Column -->
            <ng-container matColumnDef="idUser">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.idUser' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.idUser}} </td>
            </ng-container>

            <!-- operation Column -->
            <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.operation' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element">  {{element.operation | operationPipe}} </td>
            </ng-container>

            <!-- stringLog Column -->
            <ng-container matColumnDef="stringLog">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.stringLog' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.stringLog}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <button id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', {fileName:'HistoryUser'})"><i class="bi bi-file-earmark-arrow-down"></i>
        </button>

        <mat-paginator [length]="pageLength" 
            [pageSize]="pageSize" 
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" 
            showFirstLastButtons 
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</main>