<main id="main" class="main">
    <div class="pagetitle">
        <h1 i18n> {{ 'aplications.aplications' | translate }} </h1>
        <div>
            <button mat-raised-button class="custom-white-button" (click)="refrescar()">
                <i class="bi bi-arrow-clockwise"></i>  {{'common.refresh' | translate }}
            </button>
            <button mat-raised-button class="custom-add-button" (click)="onNew()">
                <i class="bi bi-plus"></i>{{ 'aplications.newAplication' | translate }}
            </button>
        </div>
    </div>

    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <!-- Filtro (oculto inicialmente) -->
    <div class="filterCustos" *ngIf="isFilterVisible">
        <div class="filterCard">
            <mat-form-field ngDefaultControl>
                <mat-label>Date</mat-label>
                <mat-date-range-input [formGroup]="dateStartFilter" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start">
                    <input matEndDate formControlName="end" placeholder="End">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>

    <div class="mat-elevation-z8 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">
            <!-- Columna Checkbox -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>

                <td mat-cell *matCellDef="let row" class="tamano_columna_max">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleRow(row)"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'aplications.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'aplications.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
            </ng-container>
            <!-- dateUpdate Column -->
            <ng-container matColumnDef="dateUpdate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'aplications.dateUpdate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateUpdate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- Version Column -->
            <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'aplications.version' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.version}} </td>
            </ng-container>
            <!-- description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'aplications.description' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="button" style=" width: 90px;">
                    <div>

                        <button *ngIf="!element.dateDelete" matTooltip="Edit" (click)="onEdit(element)" mat-icon-button
                            class="iconoTabla">
                            <i class="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Este es el panel lateral derecho -->
        <div *ngIf="isEditing" class="edit-panel">
            <!-- Botón "X" para cerrar el panel -->
            <button class="close-button" (click)="closeEditPanel()">&#x2715;</button>

            <!-- Contenido del panel lateral -->
            <app-app-param [parameters]="selectedParam" (close)="closeEditPanel()"></app-app-param>
        </div>

        <!-- Botón para exportar -->
        <button *ngIf="isDownloadButtonVisible" id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', { fileName: 'Pendientes' })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>
        <!-- Paginador -->
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>


    </div>
</main>