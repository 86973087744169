<div class="pagetitle">
    <h2>{{ 'relatedExtra.title' | translate }}</h2>
</div>

<app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
<div class="table-containter">
  <div id="tableDescription" style="display:none;">{{ 'relatedExtra.tableDescription' | translate }}</div>
  <table class="table related-extra-table" aria-labelledby="tableDescription">
      <thead>
        <tr>
          <th scope="col">{{ 'relatedExtra.numSerie' | translate }}</th>
          <th scope="col" *ngFor="let column of dynamicColumns | slice:1">{{ column }}</th>
          <th scope="col">{{ 'relatedExtra.used' | translate }}</th>
        </tr>
      </thead>
      <tbody class="related-extra-tbody">
        <tr *ngFor="let paramExtra of listRelatedExtra">
          <td class="related-extra-cells">{{ paramExtra.numSerie }}</td>
          <td class="related-extra-cells" *ngFor="let column of dynamicColumns | slice:1">{{ paramExtra[column] }}</td>
          <td class="related-extra-cells">{{ paramExtra.usado }} </td>
        </tr>
      </tbody>
  </table>

  <!-- Paginador -->
  <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
  (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</div>

<div class="w-100 d-flex justify-content-end mt-3">
    <button mat-stroked-button (click)="cancelChanges()">
        {{ 'common.cancel' | translate }}
    </button>
</div>