<main id="main" class="main">
  <div class="pagetitle">
      <h1>{{ 'operators.title' | translate }}</h1>
      <div>
        <button mat-raised-button class="custom-add-button"  (click)="onNew()">
          <i class="bi bi-plus"></i> {{ 'operators.newOperator' | translate }}
        </button>
      </div>
  </div>
  
  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" style="display:none;">{{ 'operators.tableDescription' | translate }}</div>
  <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription"  
      matSort (matSortChange)="announceSortChange($event)"
      matTableExporter #exporter="matTableExporter">
      <!-- id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'operators.id' | translate }}</div></th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'operators.name' | translate }}</div></th>
        <td mat-cell *matCellDef="let element" > {{element.name}} </td>
      </ng-container>
      <!-- cod Column -->
      <ng-container matColumnDef="cod">
        <th mat-header-cell *matHeaderCellDef><div>{{ 'operators.cod' | translate }}</div></th>
        <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', {fileName:'Terminal'})"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent()" 
      showFirstLastButtons 
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>