<div class="pagetitle">
    <h1>{{ 'maintenace.title' | translate }}</h1>
</div>

<mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">  
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'maintenace.tabs.sendFile' | translate }}</span>
      </ng-template>
        <app-send-file-maintenace [data]="data.obj"></app-send-file-maintenace>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'maintenace.tabs.getFile' | translate }}</span>
      </ng-template>
        <app-get-file [data]="data.obj"></app-get-file>
    </mat-tab>  
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'maintenace.tabs.listFiles' | translate }}</span>
      </ng-template>
      <ng-template matTabContent>
        <app-list-files [data]="data.obj"></app-list-files>
      </ng-template>
    </mat-tab>  

</mat-tab-group>