import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AlertCongif } from 'src/app/models/alertConfig.model';
import { CustomAlertsService } from './custom-alert.service';
import { UtilService } from 'src/app/services/util.service';
import { FormNewCustomAlertComponent } from './form-new-custom-alert/form-new-custom-alert.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { environment } from 'src/environments/environment';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { Operator } from 'src/app/models/operator.model';
import { MatSort, Sort } from '@angular/material/sort';

const BaseColumns = {
  view: 'view',
  typeAlert: 'typeAlert',
  priority: 'priority',
  operator: 'operator',
  description: 'description',
  dateCreate: 'dateCreate'
}

const { operator, ...Columns } = BaseColumns;
const ColumnsAdmin = { ...BaseColumns };

const TYPE_ALERT_OPTIONS:FilterOption[] = [
  { label:'battery', value:20 },
  { label:'hour', value:21 },
  { label:'key', value:22 },
  { label:'print', value:23 },
]

const PRIORITY_OPTIONS:FilterOption[] = [
  { label:'high', value:3 },
  { label:'medium', value:2 },
  { label:'low', value:1 },
]

const FILTER_COLUMNS: { field: string, type: FieldType, options?: FilterOption[] }[] = [
  { field: 'typeAlert', type: 'string', options:TYPE_ALERT_OPTIONS },
  { field: 'priority', type: 'string', options:PRIORITY_OPTIONS },
  { field: 'description', type: 'string' },
  { field: 'dateCreate', type: 'date' },
]

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.css']
})
export class CustomAlertComponent implements OnInit, AfterViewInit {

  displayedColumns = Object.keys(Columns);
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    const fielterField = new FilterField(column.field, `customizableAlerts.filters.${column.field}`, column.type, column.options);
    return fielterField;
  })
  dataSource = new MatTableDataSource<AlertCongif>();
  isAdmin: boolean = true;

  sortId: string | null = Columns.dateCreate + "";
  pageSize = environment.defaultPaginacion;
  direccion: string | null = "ASC";
  inactiveTerminals = 0;
  pageLength = 0;
  pageSizeOptions = environment.paginacion;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  filterList: FilterableValue[] = [];

  constructor(
    private servicios: CustomAlertsService, private notifier: NotifierService,
    private utilServicios: UtilService,
    public dialog: MatDialog) {}

  private getFilterableValue(column: string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  private removeFilterableValue(column: string) {
    const toRemoveIndex = this.filterList.findIndex((filter) => filter.column === column);
    this.filterList.splice(toRemoveIndex, 1);
  }
  async lanzarLLamada() {
    const request = new UsersConsoleRequest;
    const idOperatorFilter = this.getFilterableValue('idOperator');

    if (idOperatorFilter) {
      request.idOperator = idOperatorFilter.value as number;
      this.removeFilterableValue('idOperator');
    }

    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    request.filterAndPagBean = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    if (value) {
      this.dataSource = new MatTableDataSource<AlertCongif>(value.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
        this.inactiveTerminals = this.pageLength - (pagCount.data.active !== undefined ? pagCount.data.active : 0);
      }
    }
  }

  async cargar(){   
    //operadores
    this.utilServicios.findOperators().subscribe({
      next: (operators) => {
        const operationsOptions = operators.data.map((op:Operator)=>{ return { label:op.name, value:op.id }});
        this.filterColumns.push(new FilterField('idOperator', 'stadisticsFunctionally.idOperator', 'number', operationsOptions));
      }
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.utilServicios.isAdmin();
    if (this.isAdmin) {
      this.displayedColumns = Object.keys(ColumnsAdmin);
      this.cargar();
    }
    this.lanzarLLamada();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent() {;
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  resetPaginatorState() {
    this.paginator.firstPage();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    this.sortId=active;
   
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  onNew() {
    const dialogRef = this.dialog.open(FormNewCustomAlertComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/ }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) this.lanzarLLamada();
    });
  }


  async onDelete(row: AlertCongif) {
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize`Delete Customizable`;
    dialogData.textI18n = $localize`Are you sure you want to delete it?`;

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let result = await this.servicios.delete(row.id)

        if (result!.status >= 0) {
          this.notifier.notify('success', 'Customizable deleted successfully')
          this.lanzarLLamada();
        }

      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


}
