
<button mat-raised-button class="custom-white-button" (click)="openWindows()">
    <i class="bi bi-search"></i>
    {{ 'vendingMachine.selector' | translate }}
</button>

<div class="mat-elevation-z8 tablaPersonal" style="margin-top: 20px;">
    <table  aria-describedby="" mat-table [dataSource]="dataSource" >

        <!-- id Column -->
        <ng-container matColumnDef="customId">
            <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                <div> {{ 'vendingMachine.id' | translate }} </div>
            </th>
            <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.customId}} </td>
        </ng-container>

        <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="button" style=" width: 50px;">
                <div>
                    <button  (click)="onDelete(element)" mat-icon-button class="iconoTabla">
                        <i class="bi bi-trash"></i>
                    </button>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   
    </table>


    

</div>