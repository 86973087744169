import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { NotifierService } from 'angular-notifier';
import { Client } from 'src/app/models/client.model';
import { isStringBlank } from 'src/app/util/util';
import { Operator } from 'src/app/models/operator.model';
import { OperatorService } from '../../operator/operator.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'app-form-new-operator',
  templateUrl: './form-new-operator.component.html',
  styleUrls: ['./form-new-operator.component.css']
})
export class FormNewOperatorComponent implements OnInit {
  registerForm!: FormGroup;
  private notifier: NotifierService;
  listCommerce: string[] = [];
  operators: Operator[] = []; // Lista de operadores como objetos
  isAdmin: boolean = true;  // Control de visibilidad para perfil ADMIN

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private popupDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  private clienteBBDD: Client | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder, notifier: NotifierService,
    private service: OperatorService,
    private operatorService: OperatorService,
    private utilServicios: UtilService, private authService: AuthService,
    public dialogRef: MatDialogRef<FormNewOperatorComponent>,
    public dialog: MatDialog) {
    this.notifier = notifier;
  }

  async ngOnInit(): Promise<void> {
    // Obtener el rol del usuario actual
    const currentUserRoleId = this.authService.currentUserValue().rol?.id;
    this.isAdmin = currentUserRoleId === 1; // Asumiendo que 1 es el ID de Admin

    // Inicializar el formulario
    this.registerForm = this.formBuilder.group({
      nombre: [null, Validators.required],
      cod: [],
      commerce: [],
      operator: []
    });
    

    // Aquí puedes cargar más datos dependiendo de si el usuario es admin
    if (this.isAdmin) {
      await this.loadOperators(); // Cargar operadores si es admin
    }
  }

  async cargar(): Promise<void> {
    let name, cod;

    if (this.data?.obj) {
      this.clienteBBDD = await this.service.getItem(this.data.obj.id);
      if (this.clienteBBDD) {
        name = this.clienteBBDD.name;
        cod = this.clienteBBDD.cod;
        this.listCommerce = this.clienteBBDD.listCommerce;
      }
    }

    this.registerForm = this.formBuilder.group({
      nombre: [name, Validators.compose([Validators.required])],
      cod: [cod],
      commerce: [],
      operator: []
    });

    // Cargar operadores si es ADMIN
    if (this.isAdmin) {
      await this.loadOperators(); // Asegúrate de cargar los operadores correctamente
    }
  }

  // Método que carga operadores reales
  private async loadOperators(): Promise<void> {
    try {
      // Llamada al servicio para obtener los operadores
      this.utilServicios.findOperators().subscribe({
        next: (operators) => {
          this.operators = operators.data; // Almacena la lista de operadores
        },
        error: (err) => {
          console.error('Failed to load operators:', err);
          this.notifier.notify('error', 'Error al cargar los operadores');
        },
        complete: () => console.log('Operator loading completed.')
      });
    } catch (error) {
      console.error('Error al cargar operadores:', error);
      this.notifier.notify('error', 'Error al cargar los operadores');
    }
  }

  delete(text: string): void {
    const index = this.listCommerce.indexOf(text);
    if (index > -1) { // only splice array when item is found
      this.listCommerce.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  addCommerce(): void {
    const data = this.registerForm.value;
    let objNew: string = data['commerce'];
    if (!isStringBlank(objNew)) {
      const index = this.listCommerce.indexOf(objNew);
      if (index > -1) {
        this.notifier.notify('error', 'This commerce already exists');
        return;
      }
      this.listCommerce.push(objNew);
      this.notifier.notify('success', 'Commerce added successfully');
      this.registerForm.controls['commerce'].reset();
    } else {
      this.notifier.notify('error', 'The form is invalid. Please check the errors.');
    }
  }

  onFormSubmit(): void {
    console.log(this.registerForm);
    const userRole = this.data?.userRole || 'operator';  // Ejemplo: Obtener rol del usuario
    this.isAdmin = (userRole === 'admin');  // Define si es admin o no

    if (this.registerForm.invalid) {
      return;
    }
    const data = this.applyFormats(this.registerForm);

    let objNew: Client = new Client();

    objNew.name = data['nombre'];
    objNew.cod = data['cod'];
    objNew.listCommerce = this.listCommerce;

    if (this.isAdmin) {
      objNew.operator = data['operator'];  // Agregar operador seleccionado si es ADMIN
    }

    if (this.clienteBBDD) {
      objNew.id = this.clienteBBDD.id;
    }

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize `Operador`;
    dialogData.textI18n = $localize `Estas seguro de añadir este operador?`;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {

        console.log('Form SAVE action');
        let val: any;

        val = await this.service.addUpdate(objNew);
        console.log('Final Data:', objNew);

        if (val!.status >= 0) {
          this.notifier.notify('success', 'Operador añadido');

          this.dialogRef.close(FormCreateActions.SAVED);
        }
      }
    });
  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
