<div class="pagetitle">
  <h1>{{tituloTerminal}}</h1>
</div>

<mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.info' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-info-terminal></app-info-terminal>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sells' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-ventas></app-ventas>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.alerts' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-alerts></app-alerts>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.incidences' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-incidences></app-incidences>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sensors' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-sensores></app-sensores>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.userHistory' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-history-user></app-history-user>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span >{{'monitoring.tabs.pendingTasks' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-pending-tasks></app-pending-tasks>
    </ng-template>
  </mat-tab>
  
  <mat-tab>
    <ng-template mat-tab-label>
      <span >{{'monitoring.tabs.maintenance' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-tabs-mantenance></app-tabs-mantenance>
    </ng-template>
  </mat-tab>
</mat-tab-group>