
<div class="pagetitle">
    <h1>{{ 'vendingMachine.selector' | translate }}</h1>      
</div>

<app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
  
<app-custos-dual-list [sort]="keepSorted" [source]="source" [key]="key" [display]="display" [filter]="filter"
[destination]="confirmed" height="265px" [format]="format" [disabled]="disabled"></app-custos-dual-list>

<div class="row botonera">
  <div class="col-lg-12">
    <button  class="btn btn-cancelar" (click)="formExit()">{{ 'common.cancel' | translate }}</button>
    <button  class="btn btn-aceptar" (click)="onFormSubmit()">{{ 'common.confirm' | translate }}</button> 
  </div>
</div>
    
      