<main id="main" class="main">
    <div class="pagetitle">
        <h1 i18n>{{ 'profiles.profiles' | translate }}</h1>
        <div class="pagetitle-buttons">
            <button mat-raised-button class="custom-white-button" (click)="refrescar()">
                <i class="bi bi-arrow-clockwise"></i>  {{'common.refresh' | translate }}
            </button>

            <button mat-raised-button class="custom-add-button" (click)="onNew()">
                <i class="bi bi-plus"></i> {{ 'profiles.new' | translate }}
            </button>

        </div>
    </div>

    <!-- Filtro de perfiles -->
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>


    <div class="filterCustos" *ngIf="isFilterVisible">
        <div class="filterCard">
            <mat-form-field>
                <mat-label>{{ 'common.date' | translate}}</mat-label>
                <mat-date-range-input [formGroup]="dateStartFilter" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="{{ 'common.dateStart' | translate }}">
                    <input matEndDate formControlName="end" placeholder="{{ 'common.dateEnd' | translate }}">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>

    <div id="tableDescription" style="display:none;" i18n>
        This table displays organized data about Profiles, which you can sort, filter, and export. Use the paginator to
        navigate through the pages
    </div>

    <!-- Tabla de Perfiles -->
    <div class="mat-elevation-z8 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">

            <!-- Columna Checkbox -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>

                <td mat-cell *matCellDef="let row" class="tamano_columna_max">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleRow(row)"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Columna Nombre -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                    <div i18n>{{ 'profiles.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{ element.name }} </td>
            </ng-container>
            
            <!-- Columna Descripción -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.description' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.description }} </td>
            </ng-container>

            <!-- Columna Fecha Creación -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.dateCreate | dateOnTimeZone:true }}
                </td>
            </ng-container>
            <!-- Columna Fecha Actualización -->
            <ng-container matColumnDef="dateUpdate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.dateUpdate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.dateUpdate | dateOnTimeZone:true }}
                </td>
            </ng-container>

            <!-- Columna Dispositivos Asociados -->
            <ng-container matColumnDef="numMaquinas">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.associated' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.numMaquinas }} </td>
            </ng-container>

            <!-- Columna Código -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.code' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.cod" mat-icon-button (click)="onQrCodeClick(element.cod)">
                        <i class="bi bi-qr-code"></i>
                    </button>
                </td>
            </ng-container>

            <!-- Columna Acciones (Editar) -->
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 110px;">
                </th>

                <td mat-cell *matCellDef="let element" class="button" style="width: 90px;">
                    <div>
                        <button *ngIf="!element.dateDelete" matTooltip="Editar" (click)="onEdit(element)"
                            mat-icon-button class="iconoTabla">
                            <i class="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <!-- Filas de la tabla -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Botón para exportar -->
        <button *ngIf="isDownloadButtonVisible" id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', { fileName: 'Pendientes' })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>

        <!-- Paginador -->
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
</main>