import { Component, Inject, OnInit ,ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PendingTasksComponent } from '../../../comun/pending-tasks/pending-tasks.component';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-tabs-mantenance',
  templateUrl: './tabs-mantenance.component.html',
  styleUrls: ['./tabs-mantenance.component.css']
})
export class TabsMantenanceComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private utilService: UtilService) { }

  tabIndex = 0 ;

  changeTab(event: { index: number; }){
    console.log(event.index)
    this.tabIndex = event.index;      
  }


  ngOnInit(): void {   
  }

}

