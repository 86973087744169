import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { FormNewClientComponent } from './form-new-client/form-new-client.component';
import { ClientService } from './client.service';
import { Client } from 'src/app/models/client.model';
import { environment } from 'src/environments/environment';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { NotifierService } from 'angular-notifier';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';

enum Columns {
  view = 'view',
  id = 'id',
  name = 'name',
  cod = 'cod',
  operator = 'operator',
  //dateCreate = "dateCreate"
}

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'id', type:'number' },
  { field:'name', type:'string'},
  { field:'cod', type:'string' },
]

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {


  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<Client>();

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  direccion: string | null = "ASC";
  isAdmin: boolean = false;
  pageSizeOptions = environment.paginacion;
  sortId:string | null = Columns.name+"" ;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  // Filters config
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `clients.${column.field}`, column.type);
    return fielterField;
  })
  filterList: FilterableValue[] = [];


  private dialogRef!: MatDialogRef<FormNewClientComponent>;


  constructor(
    private client: ClientService,
    public dialog: MatDialog,
    private authService: AuthService,
    public commonService: CommonService
  ) {
  }

  
  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const idOperatorFilter = this.getFilterableValue("idOperator");
    let userSession = this.authService.currentUserValue();
    if (userSession?.operator && !idOperatorFilter) {  
      //this.filterList.push(new FilterableValue("idOperator", `${userSession.operator.id}`, "long", "EQUALS"));
    }
  
    const paginatorState = this.getPaginatorState();
    const filterAndPagBean =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );
    
    // Crear UsersConsoleRequest pasando FilterAndPagBean
    const usersConsoleRequest = new UsersConsoleRequest({
      idUser: userSession?.id,
      idOperator: userSession?.operator?.id,
      filterAndPagBean:filterAndPagBean
    });
  
    let value = await this.client.find(usersConsoleRequest);
    let pagCount = await this.client.countTotal(usersConsoleRequest);
    if(value){
      this.dataSource = new MatTableDataSource<Client>(value.data);
      if(pagCount) this.pageLength = pagCount.data?.total ?? 0;
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }
  
  async ngOnInit(): Promise<void> {
    const userSession = this.authService.currentUserValue();
    this.isAdmin = userSession?.rol?.id === 1; // Consideramos que el rol con ID 1 es admin.
  
    // Definimos las columnas de manera condicional
    this.displayedColumns = [
      'id',
      'name',
      'dateCreate',
      'cod',
      ...(this.isAdmin ? ['operator'] : []), // Si es admin, se incluye la columna de operador.
      'view'
    ];
  
    // Cargar datos al iniciar
    await this.lanzarLLamada();
  }
  

  onNew(){
    this.dialogRef = this.dialog.open(FormNewClientComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/  }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }


  onEdit(row:Client){
    this.dialogRef = this.dialog.open(FormNewClientComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
      data: {obj:row  }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

   
  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

}
