<div class="pagetitle">
    <h1 *ngIf="isEdit">{{ 'users.edit' | translate }}</h1>
    <h1 *ngIf="!isEdit">{{ 'users.add' | translate }}</h1>
</div>
<section class="section">
    <form [formGroup]="registerForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.nickName' | translate }}<span class="obligatorio"></span></mat-label>
                        <input formControlName="username" matInput type="text" required>
                        <mat-error *ngIf="registerForm.get('username')?.hasError('required')">{{ 'error.required.field'
                            | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('username')?.hasError('pattern')">{{ 'error.pattern' |
                            translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('username')?.hasError('maxLength')">{{ 'error.maxLength' |
                            translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.name' | translate }}<span class="obligatorio"></span></mat-label>
                        <input formControlName="name" matInput type="text" required>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('required')">{{ 'error.required.field' |
                            translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')">{{ 'error.pattern' | translate
                            }}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('maxLength')">{{ 'error.maxLength' |
                            translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.email' | translate }}<span class="obligatorio"></span></mat-label>
                        <input formControlName="email" matInput type="text">
                        <mat-error *ngIf="registerForm.get('email')?.hasError('required')">{{ 'error.required.field' |
                            translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('email')?.hasError('pattern')">{{ 'users.title' | translate
                            }}</mat-error>
                        <mat-error *ngIf="registerForm.get('email')?.hasError('maxLength')">{{ 'error.maxLength' |
                            translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('email')?.hasError('email')">{{ 'error.email' | translate
                            }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.identification' | translate }}<span class="obligatorio"></span></mat-label>
                        <input formControlName="identifier" matInput type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.rol' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="rolMarcado">
                            <mat-option *ngFor="let op of listaRol" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('rolMarcado')?.hasError('required')">{{
                            'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6" *ngIf="isAdmin">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.operator' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="opeMarcado">
                            <mat-option *ngFor="let op of listaOpe" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="button-group">
            <button mat-button (click)="formExit()">{{ 'common.cancel' | translate }}</button>
            <button mat-button (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
        </div>

    </form>
</section>