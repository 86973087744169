<div class="edit-panel">
  <div class="header">
    <div class="title">{{ 'editPanel.title' | translate }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button class="save-button" (click)="onSave()" 
        [disabled]="firstFormGroup.pristine || firstFormGroup.invalid">{{ 'editPanel.save' | translate }}</button>
      </ng-container>
      <button class="close-button" (click)="onCancel()">{{ 'editPanel.close' | translate }}</button>
    </div>
  </div>

  <!-- Formulario principal -->
  <form [formGroup]="firstFormGroup">
    <!-- Details Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('details')">
        <label class="title-small">{{ 'editPanel.details' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['details']">
        <div class="form-group">
          <label class="title-xs">{{ 'editPanel.name' | translate }}</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="name" placeholder="{{ 'editPanel.namePlaceholder' | translate }}" required />
            <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">{{ 'editPanel.nameRequired' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <label class="title-xs">{{ 'editPanel.description' | translate }}</label>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="description" rows="4" placeholder="{{ 'editPanel.descriptionPlaceholder' | translate }}"></textarea>
          </mat-form-field>
        </div>

        <div class="form-group">
          <label class="title-xs">{{ 'editPanel.packageName' | translate }}</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="packageName" placeholder="{{ 'editPanel.packagePlaceholder' | translate }}" required />
            <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('required')">{{ 'editPanel.packageNameRequired' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </fieldset>
    <!-- Dates Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('dates')">
        <label class="title-small">{{ 'editPanel.registrationDate' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['dates'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['dates']">
        <div class="form-group">
          <label class="title-xs">{{ 'editPanel.registrationDate' | translate }}</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="dateCreate" placeholder="{{ 'editPanel.registrationDate' | translate }}"
              style="color: lightgray;" />
          </mat-form-field>
        </div>

        <div class="form-group">
          <label class="title-xs">{{ 'editPanel.modificationDate' | translate }}</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="dateUpdate" placeholder="{{ 'editPanel.modificationDate' | translate }}"
              style="color: lightgray;" />
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </form>

  <!-- Segundo Formulario para Archivos y Versiones -->
  <form [formGroup]="secondFormGroup">

    <!-- Versiones Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('versiones')">
        <label class="title-small">{{ 'editPanel.versions' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['versiones'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>

      <div class="edit-content" *ngIf="isOpen['versiones']">
        <!-- Lista de versiones con scroll -->
        <div class="versions-list" style="max-height: 200px; overflow-y: auto;">
          <label class="title-xs">{{ 'editPanel.versionList' | translate }}</label>
          <ul>
            <li *ngFor="let version of versions">
              {{ version.version }}
              <ng-container *ngIf="!readOnly">
                <button mat-icon-button class="delete-button" (click)="removeVersion(version.id)">
                  <mat-icon>delete</mat-icon> <!-- Icono de papelera aquí -->
                </button>
              </ng-container>
            </li>
          </ul>
        </div>

        <!-- Botón para agregar nueva versión -->
        <div *ngIf="!isAddingVersion && !readOnly">
          <button mat-button (click)="startNewVersion()">{{ 'editPanel.addNewVersion' | translate }}</button>
        </div>

        <!-- Zona de carga de archivos -->
        <div *ngIf="isAddingVersion">
          <div class="file-upload-container">
            <div *ngIf="showDropZone" class="drop-zone" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
              <div class="drop-zone-icon">
                <mat-icon>cloud_upload</mat-icon>
                <p>{{ 'editPanel.uploadFileInstruction' | translate }}</p>
              </div>
            </div>

            <!-- Campo oculto para selección de archivo -->
            <input type="file" class="file-upload" (change)="onFileSelected($event)" #fileUpload
              style="display: none;" />

            <!-- Input para la nueva versión -->
            <div class="form-group">
              <label class="title-xs">{{ 'editPanel.version' | translate }}</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="version" placeholder="{{ 'editPanel.version' | translate }}" />
              </mat-form-field>
            </div>

            <!-- Botón para confirmar la nueva versión -->
            <button mat-button (click)="onNewVersion()">{{ 'editPanel.confirmNewVersion' | translate }}</button>
          </div>
        </div>
      </div>
    </fieldset>
  </form>

  <div class="footer" *ngIf="!readOnly">
    <button mat-button (click)="onDelete(data.app.id)">
      <mat-icon class="delete-icon">delete</mat-icon> {{ 'editPanel.deleteVersion' | translate }} <!-- Icono de papelera aquí -->
    </button>
  </div>
</div>
