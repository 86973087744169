import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Users } from 'src/app/models/users.model';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class UsersService {
  
  constructor(
    private merchantRest : RestService, private authService:AuthService,
    public commonService: CommonService) {
    }
    

    async find(_body:UsersConsoleRequest ){

      let userSession = this.authService.currentUserValue();
      _body.idEntorno=userSession!.entorno!.id
      if(userSession.operator){
        _body.idOperator=userSession!.operator!.id
        _body.idClient=userSession!.client!.id
      }

  
      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListUser/',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }
  
  
    async countTotal( _body:UsersConsoleRequest ){
           
      let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession!.entorno!.id
    if(userSession.operator){
      _body.idOperator=userSession!.operator!.id
      _body.idClient=userSession!.client!.id
    }

    _body.filterAndPagBean= new FilterAndPagBean(_body.filterAndPagBean?.orderSentido,_body.filterAndPagBean?.order,_body.filterAndPagBean?.filterGeneric,null,null,null);

      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'countListUser/',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }
    


    async addUpdate(_body:Users){
           
      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addUser/',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }

    async deleteUser(_body:Users){
           
      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'deleteUser/',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }



    
    async resetUserPass(_body:Users){
           
      let value = await this.merchantRest.commonRestCallConsolaRest(_body,'resetUserPass/',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }

}