<main id="main" class="main">


  <div class="pagetitle">
    <h1 i18n>{{ 'sensors.title' | translate }}</h1>
  </div>

  <div>
    <h1 i18n>{{ 'sensors.consum' | translate }}</h1>
    <div id="chart">
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis" [labels]="chartOptions.labels" [stroke]="chartOptions.stroke"
        [plotOptions]="chartOptions.plotOptions" [markers]="chartOptions.markers" [fill]="chartOptions.fill"
        [tooltip]="chartOptions.tooltip"></apx-chart>
    </div>
  </div>

<h1 i18n>{{ 'sensors.coverage' | translate }}</h1>
<div id="chart2">
  <apx-chart
    [series]="chartOptions2.series"
    [chart]="chartOptions2.chart"
    [xaxis]="chartOptions2.xaxis"
    [title]="chartOptions2.title"
    [colors]="chartOptions2.colors"
    [tooltip]="chartOptions2.tooltip"
  ></apx-chart>
</div>
</main>