export class FilterAndPagBean {

  orderSentido: string | null | undefined;//sentido de ordenacion DESC o ASC
  order: string | null | undefined;//columna de bbdd por la que se ordena	

 filterGeneric:FilterableValue[] |undefined;

 page!: number| null; // número de pagina
 limit!: number | null; // numero de elementos 	
 
 pagIndexIni!: number | null;; //primer elemento a buscar;
 pagIndexEnd!: number | null;; //ultimo elemento a buscar;
 
 constructor( orderSentido: string | null | undefined,  order: string | null| undefined, filterString: FilterableValue[] | undefined, pagIndexIni: number | null,  pagIndexEnd: number| null, page: number| null) {
   this.orderSentido = orderSentido;
   this.order = order;
   this.filterGeneric = filterString;
   this.pagIndexIni = pagIndexIni;
   this.pagIndexEnd = pagIndexEnd;
   this.page=page;
   if((pagIndexEnd!=null && pagIndexIni!=null)
       && (pagIndexEnd>=pagIndexIni)){
     this.limit=pagIndexEnd-pagIndexIni;
   }else{
    this.limit=null   
    }
 }

  /**  
   * @deprecated
   * remove this method on new filter component migrations end
   */
  static construirFilter(mapQuery:Map<string, FilterableValue>, idColumn:string, value:FilterValueType | undefined, type:string, operator?:string){
      if(value){
        let query = new FilterableValue(idColumn, value, type, operator);
          mapQuery.set(idColumn,query);
      }else{
          mapQuery.delete(idColumn);
      } 
      return mapQuery;
  }
}

export type FilterValueType = string | number | Date | boolean;

export class FilterableValue {
  value: FilterValueType;
  type: string;
  column:string;
  operation?:string;
  

  constructor( _column:string, _field: FilterValueType , _type: string, _operator?:string) {
    this.column=_column;  
    this.value=_field;
    this.type=this.getTypeCast(_type);
    this.operation =_operator;
  }
  
  private getTypeCast(type:string): TYPE_FILTER {
    switch(type){
        case "date":
            return TYPE_FILTER.Date;
        case "boolean":
            return TYPE_FILTER.Boolean;
        case "number":
            return TYPE_FILTER.Integer;
        case "long":
              return TYPE_FILTER.Long;
        case "string": 
        default:
            return TYPE_FILTER.String;
    }
  }
}

export enum TYPE_FILTER {// valores necesaciores por el back
    Integer = "INTEGER",
    String = "STRING",
    Long = "LONG",
    Boolean = "BOOLEAN",
    Date = "DATE",
    Duoble = "DOUBLE",
    ListLong = "LIST_LONG",
    ListInt = "LIST_INTEGER",
    ListString = "LIST_STRING"
}

// Enumerado que contiene las posibles operaciones de filtrado
export enum OPERATION_FILTER {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  LIKE = "LIKE", // soporte para búsquedas parciales
  IN = "IN", // soporte para filtros con listas
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL"
}