import { Component,ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import {ChartComponent, ApexChart, ApexNonAxisChartSeries, ApexPlotOptions, ApexFill} from "ng-apexcharts";
import { UtilService } from 'src/app/services/util.service';
import { StadisticService } from '../stadistic.service';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { StadisticReportTMS } from 'src/app/models/StadisticReportTMS.model';
import { ChartOptions } from 'chart.js';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { InfoDay } from 'src/app/models/infoDay.model';
import { MAX_HOURS_ACTIVATES, MAX_KEYSTROKES, MAX_LOAD_CYCLES, MAX_PRINTED_LINE } from 'src/environments/environment.dev';
import { FilterableValue } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';

export type chartOptions_loadCycle = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};
export type chartOptions_hourActive = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};
export type chartOptions_key = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};
export type chartOptions_printer = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'date', type:'date' },
]

@Component({
  selector: 'app-stadistic-state',
  templateUrl: './stadistic-state.component.html',
  styleUrls: ['./stadistic-state.component.css']
})
export class StadisticStateComponent implements OnInit {
  ///filtros
  filterColumns = FILTER_COLUMNS.map((filter)=>{
    return new FilterField(filter.field, `stadisticsState.${filter.field}`, filter.type);
  })
  filterList: FilterableValue[] = [];

  listDias = new Map()
  maxBattery=0
  maxHourActivates=0
  maxKeystorkes=0
  maxPrintedLines=0


  @ViewChild("loadCycle")  loadCycle!: ChartComponent; 
  @ViewChild("hourActive")  hourActive!: ChartComponent; 
  @ViewChild("key")  key!: ChartComponent; 
  @ViewChild("printer")  printer!: ChartComponent; 
  public chartOptions_loadCycle: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};
  public chartOptions_hourActive: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};
  public chartOptions_key: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};
  public chartOptions_printer: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};

  constructor(  private utilServicios : UtilService,  
    private terminalService: MonitoringService, private authService:AuthService,
    private stadisticService :StadisticService) { 
  }

  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada(){
    const stadisticBean:StadisticBean = new StadisticBean();
    
    const selectedTerminal = this.getFilterableValue('idVendingMachine');
    if (selectedTerminal) stadisticBean.idVendingMachine = selectedTerminal.value as number;

    stadisticBean.typeReport=51 //valor fijo para graficas del TMS
    let userSession = this.authService.currentUserValue();
    stadisticBean.idEntorno=userSession!.entorno!.id
    
    this.setDateFilter(stadisticBean);

    let value = await this.stadisticService.find(stadisticBean);
    console.log(JSON.stringify(value))
    if(value) this.procesarDatos(value.data, stadisticBean.dateIni, stadisticBean.dateEnd);
  }

  setDateFilter(request:StadisticBean){
    this.filterList.forEach((filter)=>{
      if(filter.column === 'date'){
        if(filter.operation === 'LESS_THAN_OR_EQUAL') request.dateEnd = new Date(filter.value as number);
        if(filter.operation === 'GREATER_THAN_OR_EQUAL') request.dateIni = new Date(filter.value as number);
      }
    });
    request.dateIni = request.dateIni ?? (request.dateEnd ? moment(request.dateEnd).startOf("month") : moment(new Date()).date(1));
    request.dateEnd = request.dateEnd ?? moment(request.dateIni).endOf("month").endOf("day").toDate();
  }

  async procesarDatos(stadisticResult:StadisticReportTMS, dateIni:Date, dateEnd:Date){   
        let fecha1 = moment(dateIni);
        let fecha2 = moment(dateEnd);  
        let diff = fecha2.diff(fecha1, 'days');


        for (let i = 0; i <= diff; i++) {
          let fechaTemp = moment(dateIni);
          fechaTemp.add(i, 'days');

          let punto = fechaTemp.format('DD-MM-YYYY');
          this.listDias.set(punto,new InfoDay)
        }


        if(stadisticResult){

          if(stadisticResult.contadorUso){
              if(stadisticResult.contadorUso.battery){
                this.maxBattery=stadisticResult.contadorUso.battery
              }
              if(stadisticResult.contadorUso.hoursActivated){
                this.maxHourActivates=stadisticResult.contadorUso.hoursActivated
              }
              if(stadisticResult.contadorUso.keystrokes){
                this.maxKeystorkes=stadisticResult.contadorUso.keystrokes
              }
              if(stadisticResult.contadorUso.printedLine){
                this.maxPrintedLines=stadisticResult.contadorUso.printedLine
              }
          }


          if(stadisticResult.ListInfoByHour){
            for (const entry of stadisticResult.ListInfoByHour) {
                let key = entry.period.toString().substring(0,10)
                
                let infoDay = this.listDias.get(key)
                if(!infoDay){infoDay = new InfoDay}

                infoDay.valor = infoDay.valor + entry.timeOp

                this.listDias.set(key,infoDay)
            }
          }
        }


        this.constructorLoadCycle(); 
        this.constructorHpurActive()
        this.constructorKey()
        this.constructorPrinter()
  }


  async cargar(){      
    //Terminal
    let vmsList = await this.terminalService.find(new UsersConsoleRequest);
    const vmOptions = vmsList?.data.map((vm:VendingMachineAtento)=>{ return { label:vm.customId, value:vm.id }});
    this.filterColumns.push(new FilterField('idVendingMachine', 'stadisticsState.idVendingMachine', 'number', vmOptions));
  }


  async ngOnInit(): Promise<void> {
    await this.cargar(); 
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.lanzarLLamada();
  }

  redimensionar(id:string) {
    let big = document.getElementById(id);
    if(big){
      let hasClase2 = big.classList.contains( 'graficaBig' );
    
        if(hasClase2){// si esta expandido lo contraemos

          for (let el of document.querySelectorAll<HTMLElement>('.grafica') ) {
            el.style.display = 'block';
            el.classList.remove("graficaBig");
          }

          for (let el of document.querySelectorAll<HTMLElement>('.expand') ) {
            el.style.display = 'block';
          }
          for (let el of document.querySelectorAll<HTMLElement>('.contract') ) {
            el.style.display = 'none';
          }

        }else{
            //agrandamos la grafica
            for (let el of document.querySelectorAll<HTMLElement>('.grafica') ) {
              el.style.display = 'none';
              el.classList.remove("graficaBig");
            }
            for (let el of document.querySelectorAll<HTMLElement>('.expand') ) {
              el.style.display = 'none';
            }
            for (let el of document.querySelectorAll<HTMLElement>('.contract') ) {
              el.style.display = 'block';
            }
        
            big.style.display = 'block';
            big.classList.add("graficaBig");            
        }
    }

    if(id == "_loadCycle"){
      this.constructorLoadCycle()
    }
    if(id == "_hourActive"){
      this.constructorHpurActive()
    }
    if(id == "_key"){
      this.constructorKey()
    }
    if(id == "_printer"){
      this.constructorPrinter()
    }


  }

  constructorLoadCycle(){
    let colorPercent = this.getColorPercent(this.valueToPercent(this.maxBattery,MAX_LOAD_CYCLES))
   
    this.chartOptions_loadCycle = {
      series: [this.valueToPercent(this.maxBattery,MAX_LOAD_CYCLES)],  
      chart: {
        type: "radialBar",
        offsetY: -2
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 1, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "32px",
              formatter: function(val:any) {
                 //volvemos a calcular el valor original en vez del % que pinta
               return ((val * MAX_LOAD_CYCLES)/100).toFixed(0);
              }
            }
          }
        }
      },
      fill: {
        type: 'solid',
        colors: [colorPercent]
      },
      
    };
  }

  constructorHpurActive(){

    let colorPercent = this.getColorPercent(this.valueToPercent(this.maxHourActivates,MAX_HOURS_ACTIVATES))
    
    this.chartOptions_hourActive = {
      series: [this.valueToPercent(this.maxHourActivates,MAX_HOURS_ACTIVATES)],  
      chart: {
        type: "radialBar",
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 1, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "32px",
              formatter: function(val:any) {
                 //volvemos a calcular el valor original en vez del % que pinta
               return ((val * MAX_HOURS_ACTIVATES)/100).toFixed(0);
              }
            }
          }
        }
      },
      fill: {
        type: 'solid',       
        colors: [colorPercent]
      }
      
    };
  }

  constructorKey(){
    let colorPercent = this.getColorPercent(this.valueToPercent(this.maxKeystorkes,MAX_KEYSTROKES))
    
    this.chartOptions_key = {
      series: [this.valueToPercent(this.maxKeystorkes,MAX_KEYSTROKES)],  
      chart: {
        type: "radialBar",
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 1, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "32px",
              formatter: function(val:any) {
                 //volvemos a calcular el valor original en vez del % que pinta
               return ((val * MAX_KEYSTROKES)/100).toFixed(0);
              }
            }
          }
        }
      },
      fill: {
        type: 'solid',       
        colors: [colorPercent]
      }
      
    };
  }

  constructorPrinter(){
    let colorPercent = this.getColorPercent(this.valueToPercent(this.maxPrintedLines,MAX_PRINTED_LINE))
    
    this.chartOptions_printer = {
      series: [this.valueToPercent(this.maxPrintedLines,MAX_PRINTED_LINE)],  
      chart: {
        type: "radialBar",
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 1, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "32px",
              formatter: function(val:any) {
                 //volvemos a calcular el valor original en vez del % que pinta
               return ((val * MAX_PRINTED_LINE)/100).toFixed(0);
              }
            }
          }
        }
      },
      fill: {
        type: 'solid',       
        colors: [colorPercent]
      }
     
    };
  }

  valueToPercent (value:number, max:number) {
    return (value * 100) / max
  } 

  getColorPercent (val:number) {
    let color='#d52525'
    if (val < 55) {
      color='#30bf3f'
    }else if (val >= 55 && val < 80) {
      color= '#d59c25'
    }
    return color
  } 
}
