export class VendingMachineAtento {
  id: number | undefined;
  idVM: number | undefined;
  name: string | undefined;
  idAtento: string | undefined;
  idEntornoAtento: number | undefined;
  idOperator: number | undefined;
  customId: string | undefined;
  serialNrAtento: string | undefined;
  softwareRevision: string | undefined;

  manufacID: string | undefined;
  modelNumber: string | undefined;
  swRev: string | undefined;
  serialNumber: string | undefined;

  commerce: string | undefined;
  orderCommerce: string | undefined;

  location: string | undefined;
  ip: string | undefined;
  imei: string | undefined;
  sim: string | undefined;
  macBT: string | undefined;
  puntoVenta: string | undefined;  
  
  nameOperator: string | undefined;
  nameClient: string | undefined;
  
  valueVend: number | undefined;      
  lastUpdate: Date | undefined;      
  lastConect: Date | undefined;  
  dateCreate: Date | undefined;
  dateInstall: Date | undefined;

  hasPending:boolean = false;
  coverageLevel: number | undefined; 
  coverageType: string | undefined;

  latitude?:number;
  longitude?:number;
}

