import { Component, OnInit,Inject,ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { Pending } from 'src/app/models/pending.model';
import { environment } from 'src/environments/environment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { NotifierService } from 'angular-notifier';
import { IncidenceService } from './incidence.service';
import { Incidence } from 'src/app/models/incidence.model';
import { AuthService } from 'src/app/auth/auth.service';
import { FormNewIncidenceComponent } from './form-new-incidence/form-new-incidence.component';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';

enum Columns {//en el orden que se quiera mostrar
  view = 'view',
  customID = 'customID',
  type = 'type',
  dateCreate = 'dateCreate', 
  description = 'description',
  userCreate = 'userCreate',
  resolution = 'resolution',
  dateDelete = 'dateDelete',
  userDelete = 'userDelete',
}

const TYPE_OPTIONS:FilterOption[] = [
  { value:"0", label: "Other" },
  { value:"1", label: "Suggestion" },
  { value:"2", label: "Telemetry failure" },
  { value:"3", label: "Terminal Breakdown" },
  { value:"4", label: "Non-active terminal" },
]

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'customID', type:'string' },
  { field:'resolution', type:'string' },
  { field:'userCreate', type:'string' },
  { field:'description', type:'string' },
  { field:'type', type:'string', options:TYPE_OPTIONS},
  { field:'dateCreate', type:'date' },
]

@Component({
  selector: 'app-incidences',
  templateUrl: './incidences.component.html',
  styleUrls: ['./incidences.component.css']
})
export class IncidencesComponent implements OnInit, AfterViewInit  {
 
  displayedColumns =  Object.keys(Columns);
  private notifier: NotifierService;
  
  dataSource = new MatTableDataSource<Pending>();

  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId:string | null = Columns.dateCreate+"" ;
  direccion:string | null ="ASC";
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `incidences.${column.field}`, column.type, column.options);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  private dialogRef!: MatDialogRef<FormNewIncidenceComponent>;
  private dialogMonitoringRef!: MatDialogRef<TabsComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  notifier: NotifierService , 
    private servicios : IncidenceService,
    private monitoringService : MonitoringService,
    public dialog: MatDialog,private authService:AuthService,
    public commonService : CommonService) { 
       this.notifier = notifier;
  }
    
  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const idVMFilter = this.getFilterableValue("idVM");
    const idOperatorFilter = this.getFilterableValue("idOperator");
   
    if(this.data?.obj && !idVMFilter){
      const idVM = this.data.obj.id;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", "EQUALS"));
    }


    if(idOperatorFilter){
     // this.filterList.push(new FilterableValue("operator.id", idOperatorFilter, TYPE_FILTER.Long, 'EQUALS'));
    }
    
    const paginatorState = this.getPaginatorState();
    const request =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    try {
        let value = await this.servicios.find(request);
        let pagCount = await this.servicios.countTotal(request);
        
        if (value) {
            this.dataSource = new MatTableDataSource<Pending>(value.data);
            if (pagCount) {
                this.pageLength = pagCount.data?.total ?? 0;
            }
        } else {
            // Manejo de error si value es undefined o null
            console.error("La respuesta de 'find' es undefined");
        }
    } catch (error) {
        console.error("Error en lanzarLLamada:", error);
        this.notifier.notify('error', 'Error al cargar los datos.');
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }
  
  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.lanzarLLamada();
  }
    
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent(){
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
   
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  async onView(row: any){
    let value = await this.monitoringService.getMachine(row.idVM);
    this.dialogMonitoringRef = this.dialog.open(TabsComponent, {
      width: '90%', height:'95%',panelClass: 'custom-modalbox-big',
      data: {
        obj: value?.data
      }
    });

    this.dialogMonitoringRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');       
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  async onEdit(row:Incidence) {
    console.log('Eddit pressed: ', row);
    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '600px', panelClass: 'custom-modalbox-big',
      data: {incidencia:row,vm:this.data.obj,close:false
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.notifier.notify('success', 'Ticket created successfully') 
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  async onClose(row:Incidence) {
    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '800px', panelClass: 'custom-modalbox-big',
      data: {incidencia:row,vm:this.data.obj,close:true
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.notifier.notify('success', 'Ticket close successfully') 
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  async onNew() {
    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '600px', panelClass: 'custom-modalbox-big',
      data: {vm:this.data.obj
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.notifier.notify('success', 'Ticket created successfully') 
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

}
