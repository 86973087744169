import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { SelectMachineWindowComponent } from 'src/app/pages/comun/select-machine-window/select-machine-window.component';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { AppsService } from '../../aplications/aplications.service';
import { Aplications } from 'src/app/models/aplications.model';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamService } from '../../param/param.service';
import { PerfilApp } from 'src/app/models/perfilApp.model';
import { EditAppsComponent } from '../edit-apps/edit-apps.component';
import { EditMachinesComponent } from '../edit-machines/edit-machines.component';
import { ListRelatedMachinesComponent } from '../list-related-machines/list-related-machines.component';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';

@Component({
  selector: 'app-edit-perfiles',
  templateUrl: './edit-perfiles.component.html',
  styleUrls: ['./edit-perfiles.component.css']
})
export class EditPerfilesComponent implements OnInit {
  isAdmin: boolean = true;
  isLinear = true;
  selectedFile: File | null = null;
  resultListApp: PerfilApp[] = [];
  showDropZone = false;
  dialogRef!: MatDialogRef<SelectMachineWindowComponent>;
  profile: Profile = new Profile;

  @Output() closePanel = new EventEmitter<void>();
  uploadProgress = 0;
  isDetailsOpen = true;
  isDateFieldsOpen = true;

  // Form groups
  form!: FormGroup;
  appsFormGroup!: FormGroup;

  isOpen: { [key: string]: boolean } = {
    details: true,
    dateFields: true,
    terminals: true,
    apps: true,
    distance: true,
  };

  // Variables for apps and parameters
  availableApps: Aplications[] = [];
  parameters: Parameters[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog,
    public dialogNewProfile: MatDialogRef<EditPerfilesComponent>,
    private perfilesService: PerfilesService,
    private appsService: AppsService,
    private paramService: ParamService,
    private profileService: PerfilesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      name: new FormControl("", Validators.required),
      description:new FormControl(""),
      dateCreate: new FormControl({value:new Date, disabled:true}),
      dateUpdate: new FormControl({value:new Date, disabled:true}),
      enableLocation:new FormControl(true),
      distanceLocation:new FormControl(0),
      distanceUnit:new FormControl("km")
    });
    this.loadAvailableApps();
    this.loadParameters();
    this.loadProfile();
  }

  private async loadProfile(){
    const response = await this.profileService.get(this.data.id);
    if(response && response.data){
      this.profile = response.data as Profile;
      this.form.get("name")?.setValue(this.profile.name);
      this.form.get("description")?.setValue(this.profile.description);
      this.form.get("dateCreate")?.setValue(new Date(this.profile.dateCreate));
      this.form.get("dateUpdate")?.setValue(new Date(this.profile.dateUpdate));
      this.form.get("enableLocation")?.setValue(this.profile.enableLocation?? true);
      this.form.get("distanceLocation")?.setValue(this.profile.distanceLocation??0);
      this.form.get("distanceUnit")?.setValue(this.profile.distanceUnit??"m");
    } else {
      this.notifier.notify('error', 'Error al obtener perfil');
      this.onCancel();
    }
  }

  private async loadAvailableApps(): Promise<void> {
    const result = await this.appsService.find({} as any);
    if (result?.status === 0) {
      this.availableApps = result.data;
    }
  }

  private async loadParameters(): Promise<void> {
    const result = await this.paramService.find({} as any);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  openEditTerminalsScreen(){
    const dialogEditProfile: MatDialogRef<ListRelatedMachinesComponent> = this.dialog.open(ListRelatedMachinesComponent, {
      width: '600px',
      panelClass: 'custom-modalbox-big',
      data: { profile:this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) this.loadProfile();
    });
  }

  async openAddTerminalsScreen() {
    const dialogEditProfile: MatDialogRef<EditMachinesComponent> = this.dialog.open(EditMachinesComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data:{ profile:this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) this.loadProfile();
    });
  }

  async openAddAppScreen() {
    const dialogEditProfile: MatDialogRef<EditAppsComponent> = this.dialog.open(EditAppsComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data:{ profile:this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) this.loadProfile();
    });
  }

  deleteApp(appIndex:number){
    const listApp = this.profile.listApp ?? [];
    listApp.splice(appIndex, 1);

    this.profile.listApp = [ ...listApp ];
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  onSave(): void {
    if (!this.profile) return;
    if (this.form.valid) {
      const updatedApp: Profile = {
        ...this.profile,
        ...this.form.value,
      };
      updatedApp.dateCreate = this.profile.dateCreate ? new Date(this.profile.dateCreate) : new Date;
      updatedApp.dateUpdate = this.profile.dateUpdate ? new Date(this.profile.dateUpdate) : new Date;
      this.perfilesService.saveProfile(updatedApp).then(() => {
        this.dialogNewProfile.close(1);
        this.notifier.notify('success', 'Actualizado correctamente');
      }).catch(error => {
        this.notifier.notify('error', `Error al actualizar: ${error.message}`);
      });
    } else {
      this.notifier.notify('warning', 'Por favor, completa todos los campos requeridos');
    }
  }

  onDelete(): void {
    if(!this.profile) return;
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar?');
    if (confirmDelete) {
      this.profileService.delete(this.profile.id).then(() => {
        this.dialogNewProfile.close(1);
        this.notifier.notify('success', 'Eliminada con éxito');
      }).catch(error => {
        this.notifier.notify('error', `Error al eliminar: ${error.message}`);
      });
    }
  }

  onCancel(): void {
    this.dialogNewProfile.close();
  }


}
