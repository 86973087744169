import { Pipe, PipeTransform } from '@angular/core';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';

@Pipe({
  name: 'operatorName'
})
export class OperatorNamePipe implements PipeTransform {
  private operators: Operator[] = [];

  constructor(private utilService: UtilService) {
    this.utilService.findOperators().subscribe({
      next: (operators) => {
        this.operators = operators.data;
      },
      error: (err) => {
        console.error('Failed to load operators in Pipe:', err);
      },
      complete: () => console.log('Operator loading completed.')
    });
  }

  transform(operatorId: number): string {
    const operator = this.operators.find(op => op.id === operatorId);
    return operator ? operator.name : 'Unknown Operator';
  }
}
