import { SERVER_URL_CONSOLA_REST, SERVER_URL_AUTH_TMS} from 'src/environments/environment';
import { NotifierOptions } from 'angular-notifier';

export const RegexpValidators = {
  number: /^\d*$/,
  // Passwords should be at least 8 characters long and should contain one number, one character and one special character.
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
};

export const Feedbacks = {
  position: 'toast-bottom-full-width' as const,
  timeoutShort: 2500 as const,
  timeoutLong: 8000 as const,
};

export const pedingWarnigAlert = {
  text: 'Terminal configuration is pending of actualization' as const,
  type: 'Warning' as const,
  position: 'toast-bottom-full-width' as const,
  timeout: 12000 as const,
};

export const HttpTimeouts = {
  default: 180000,
  short: 20000,
  long: 600000,
};

export const Separator = ","
export const AuthTmsRestUrl = SERVER_URL_AUTH_TMS;
export const ConsoleRest = SERVER_URL_CONSOLA_REST;



export enum LEVEL_ROL {
  ARMIN = 1,
  OPERATOR = 2
}


export enum FormCreateActions {
  SAVED = 1,
  EXIT = 0
}


export const enum AUTHENTICACION {
  AUTH,
  TMS_SERVER
}



/**
 * Angular notifier options
 */
export const NOTIFIER_OPTIONS: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 0,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 3000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
 };


 export const FILE_TYPES = [
  'publicDirectory',
  'filesDir',
  'externalFilesDir'
];