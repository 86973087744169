import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { PerfilesService } from '../perfiles.service';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { environment } from 'src/environments/environment';
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { DynamicColumns, ParamExtra, RelatedExtra } from 'src/app/models/profileParamExtra.model';

const BOOLEAN_OPTIONS:FilterOption[] = [
  { label:'Verdadero', value:true },
  { label:'Falso', value:false }
]

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'numSerie', type:'long' },
  { field:'usado', type:'boolean', options:BOOLEAN_OPTIONS },
]

@Component({
  standalone:true,
  imports:[CommonModule, MatLegacyButtonModule, TranslateModule, FilterComponent, MatPaginatorModule],
  selector: 'app-list-related-extra',
  templateUrl: './list-related-extra.component.html',
  styleUrls: ['./list-related-extra.component.css']
})
export class ListRelatedExtraComponent implements OnInit {
  listRelatedExtra:RelatedExtra[] = [];

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `profile.paramExtra.${column.field}`, column.type, column.options);
    return fielterField;
  });
  filterList: FilterableValue[] = [];
  dynamicColumns:string[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  constructor(
    public dialogRef: MatLegacyDialogRef<ListRelatedExtraComponent>, 
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { idProfile:number, idApp:number },
    private service:PerfilesService){}
    
  public async ngOnInit(): Promise<void> {
    const value = await this.loadParamExtra();
    if(value) {
      this.listRelatedExtra = this.mapDataToRelatedExtra(value.data);
      this.pageLength = value.data.length;
    } 
  }

  private mapDataToRelatedExtra(paramsExtra:ParamExtra[]):RelatedExtra[]{
    return paramsExtra.map((param, index:number)=>{
      const jsonColumns:DynamicColumns = {};
      const jsonList = JSON.parse(param.json);
      jsonList.forEach((jsonData:{ key:string, value:string })=>{
        jsonColumns[jsonData.key] = jsonData.value;
        if(index === 0) this.dynamicColumns.push(jsonData.key);
      })
      return new RelatedExtra(param.numSerie,param.usado,jsonColumns);
    });
  }

  async loadParamExtra(){
    const filterAndPagBean = this.getFilterAndPagBean();
    return await this.service.getExtraParams(this.data.idProfile, this.data.idApp, filterAndPagBean);
  }

  getFilterAndPagBean(): FilterAndPagBean {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return new FilterAndPagBean(null,null,this.filterList,startPage,endPage,pageIndex);
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.loadParamExtra();
  }

  pageEvent(){
    this.loadParamExtra();
  }

  public cancelChanges() {
    this.dialogRef.close();
  }
}
