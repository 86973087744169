<main id="main" class="main">
  <div class="pagetitle">
    <h1> {{ 'maintenace.tabs.getFile' | translate }}</h1>
  </div>

  <section class="row">
    <article class="bloque col-lg-6 form-container">
      <form [formGroup]="firstFormGroup">

        <article class="bloque col-lg-6" *ngIf="!hasVendingMachineSelected">
          <app-select-machine-detail [dataSource]="selectedVendingMachines"></app-select-machine-detail>
        </article>
        <!-- Tipo de Archivo -->
        <div class="form-group" style="margin-top: 10px;">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'common.fileType' | translate }}</mat-label>
            <mat-select formControlName="storageType" required>
              <mat-option *ngFor="let type of fileTypes" [value]="type">{{ type }}</mat-option>
            </mat-select>
            <mat-error *ngIf="firstFormGroup.get('storageType')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
  
        <!-- Nombre del paquete -->
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'maintenace.input.packageName' | translate }}<span class="obligatorio"></span></mat-label>
            <input matInput formControlName="packageName" placeholder="{{ 'maintenace.placeholder.packageName' | translate }}" required>
            <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
  
        <!-- Path -->
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'maintenace.input.path' | translate }} <span class="obligatorio"></span></mat-label>
            <input matInput formControlName="path" placeholder="{{ 'maintenace.placeholder.path' | translate }}" required>
            <mat-icon matSuffix matTooltip="{{ 'maintenace.tooltip.path' | translate }}">info</mat-icon>
            <mat-error *ngIf="firstFormGroup.get('path')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
  
        <!-- Nombre del Fichero -->
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'maintenace.input.fileName' | translate }}<span class="obligatorio"></span></mat-label>
            <input matInput formControlName="fileName" placeholder="{{ 'maintenace.placeholder.fileName' | translate }}" required>
            <mat-error *ngIf="firstFormGroup.get('fileName')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
  
        <!-- Botón de Enviar -->
        <div class="button-group">
          <button button class="btn btn-aceptar" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
        </div>
      </form>
    </article>

  </section>
  

</main>
