import { Client } from "./client.model";
import { Entorno } from "./entorno.model";
import { FilterAndPagBean } from "./FilterAndPagBean";
import { Operator } from "./operator.model";
import { Rol } from "./rol.model";

export class Users {
  id: number | undefined;	
	name: string | undefined;	
	nickName: string | undefined;		
	pass: string | undefined;
	rol:Rol | undefined;
	operator:Operator | undefined;
	client:Client | undefined;
	phone: string | undefined;
	email: string | undefined;
	lang: string | undefined;
	receiveAlert: boolean=false;
	dateDelete: Date | undefined;
	entorno!: Entorno;
	privateUser: boolean=false;
	superUser: boolean=false;
	identification: string | undefined;
	alarmNotification: string | undefined;
	
	tempass: string | undefined;

  
  //-------------------------------  
  token!: string | undefined;

  filterAndPagBean: FilterAndPagBean | undefined;
  isAdmin: any;

   
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}






export class UserPermisos {
  domains!: string[]; 
  roles!: string[];
  groups!: string[];

}


export class UserProfile {
  company!: string; 
  jobTitle!: string;
  country!: string;
  address!: string;
  phone!: string;

}


