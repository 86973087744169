import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotifierService } from 'angular-notifier';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamService } from '../param.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { FormCreateActions } from 'src/app/util/constants';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { parseBase64Data } from 'src/app/util/util';

@Component({
  selector: 'app-edit-param',
  templateUrl: './edit-param.component.html',
  styleUrls: ['./edit-param.component.css']
})
export class EditParamComponent implements OnInit {

  form!: FormGroup;
  @Input() dataSource = new MatTableDataSource<Parameters>();
  isLinear: BooleanInput;
  showDropZone = false;
  uploadProgress = 0;
  selectedFile: File | null = null;
  isOpen: { [key: string]: boolean } = {
    details: true,
    dates: true,
    fileUpload: true,
    
  };

  readOnly:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditParamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { param: Parameters, readOnly:boolean},
    private paramService: ParamService

  ) {
    this.readOnly = !!data.readOnly;
    this.form = this.formBuilder.group({
      name: [{value:data.param.name, disabled:this.readOnly}, Validators.required],
      dateCreate: [{ value: data.param.dateCreate, disabled: true }],
      dateUpdate: [{ value: data.param.dateUpdate, disabled: true }],
      version: [data.param.version],
      description: [{value:data.param.name, disabled:this.readOnly}],
      fileName: [data.param.fileName],
      base64: [data.param.base64]
    });
  }

  ngOnInit(): void {
    // Obtener la fecha actual
    const today = new Date().toISOString().substring(0, 10);

    // Obtener los datos del backend usando el id del parámetro
    this.paramService.get(this.data.param.id).then(response => {
      if (response && response.status === 0 && response.data) {
        // Carga los datos recibidos del backend en el formulario
        const paramData = response.data;
        this.form.patchValue({
          name: paramData.name,
          description: paramData.description,
          version: paramData.version,
          dateCreate: paramData.dateCreate,
          dateUpdate: today,
          fileName:paramData.fileName,
          base64:paramData.base64
        });

        this.form.markAsDirty();
        // Establece el archivo del backend
        if (paramData.base64 && paramData.fileName) {
          this.selectedFile = new File([paramData.base64], paramData.fileName, { type: 'application/octet-stream' });
        }
      } else {
        this.onCancel();
      }
    }).catch(error => {
      this.notifier.notify('error', `Error al cargar datos: ${error.message}`);
    });
  }


  onDelete(): void {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar?');

    if (confirmDelete) {
      this.paramService.delete(this.data.param.id).then(() => {
        this.dialogRef.close(1);
        this.notifier.notify('success', 'Eliminada con éxito');
      }).catch(error => {
        this.notifier.notify('error', `Error al eliminar: ${error.message}`);
      });
    }
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (this.isValidFileType(file)) {
        this.selectedFile = file;
        this.form.patchValue({
          fileName: this.selectedFile.name
        });

        // Convertir el archivo a base64 y actualizar el formulario
        this.convertFileToBase64(this.selectedFile).then(base64File => {
          this.form.patchValue({
            base64: base64File
          });
        }).catch(error => {
          this.notifier.notify('error', `Error al convertir archivo a base64: ${error.message}`);
        });

        this.form.markAsDirty();
        this.showDropZone = false; // Oculta la zona de arrastre
      } else {
        this.notifier.notify('error', 'Por favor, selecciona un archivo CSV');
      }
    }
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.isValidFileType(file)) {
        this.selectedFile = file;
        this.form.patchValue({
          fileName: this.selectedFile.name
        });
        this.form.markAsDirty();

        // Convertir el archivo a base64 y actualizar el formulario
        this.convertFileToBase64(this.selectedFile).then(base64File => {
          this.form.patchValue({
            base64: base64File
          });
        }).catch(error => {
          this.notifier.notify('error', `Error al convertir archivo a base64: ${error.message}`);
        });

        this.showDropZone = false; // Oculta la zona de arrastre
      } else {
        this.notifier.notify('error', 'Por favor, arrastra un archivo CSV');
      }
    }
  }

  isValidFileType(file: File): boolean {
    // Verifica si el archivo tiene la extensión .csv
    return file.type === 'text/csv' || file.name.endsWith('.csv');
  }


  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.form.get('fileName')?.reset();
    this.form.get('base64')?.reset();
    this.uploadProgress = 0;
  }

  uploadFile(file: File): void {
    const uploadInterval = setInterval(() => {
      this.uploadProgress += 10;
      if (this.uploadProgress >= 100) {
        clearInterval(uploadInterval);
      }
    }, 500);
  }

  onSave(): void {
    console.log('Método onSave llamado');
    if (this.form.valid) {

      // Extrae los valores del formulario
      const formData = this.form.value;
      
      // Asigna los valores al objeto Parameters que se enviará
      let updatedParam: Parameters = new Parameters();
      updatedParam.name = formData.name;
      updatedParam.description = formData.description;
      updatedParam.version = formData.version;
      updatedParam.id = this.data.param.id;
      updatedParam.base64 = formData.base64
      updatedParam.fileName = formData.fileName;
      
      // Verifica si hay un archivo seleccionado
      if (this.selectedFile) {
        this.convertFileToBase64(this.selectedFile).then(base64File => {
          updatedParam.base64 = formData.base64;
          updatedParam.fileName = formData.fileName;

          // Llama al servicio para agregar/actualizar el archivo
          return this.paramService.addUpdate(updatedParam);
        }).then(() => {
          this.dialogRef.close(FormCreateActions.SAVED);
        }).catch(error => {
          this.dialogRef.close(FormCreateActions.EXIT);     
          this.notifier.notify('error', `Error al actualizar: ${error.message}`);
        });
      } else {
        this.paramService.addUpdate(updatedParam).then(() => {
          this.dialogRef.close(FormCreateActions.SAVED);
        }).catch(error => {
          this.notifier.notify('error', `Error al actualizar: ${error.message}`);
          this.dialogRef.close(FormCreateActions.EXIT);     
        });
      }
    } else {
      this.notifier.notify('warning', 'Por favor, completa todos los campos requeridos');
    }
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const base64String = parseBase64Data(result);
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }


  onCancel(): void {
    this.dialogRef.close();
  }
}
