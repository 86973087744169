<div class="parameter-file-row">
    <div class="selected-info-row">

        <!-- Delete button -->
        <button mat-icon-button color="warn" class="delete-button-apps" (click)="removeApp(selectedApp)">
            <mat-icon>delete</mat-icon>
        </button>
        <p>{{ selectedApp.appName }} - Version: {{ selectedApp.version }}</p>
        
    </div>
    <mat-form-field appearance="outline" class="select-parameter-field" *ngIf="selectedApp">
        <mat-label>Select Parameter</mat-label>
        <mat-select (selectionChange)="onParameterSelect($event, selectedApp)">
            <mat-option *ngFor="let param of parameters" [value]="param">{{ param.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- File upload -->
    <div *ngIf="selectedApp.paramExtra" class="selected-file-info">
        <p>Selected File: {{ selectedApp.fileName}}</p>
        <button mat-icon-button color="warn" (click)="removeFileApp()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <div *ngIf="!selectedApp.paramExtra">
        <label class="upload-label">Upload CSV file:</label>
        <div class="drop-zone" (drop)="onFilesDrop($event, selectedApp)" (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
            <div class="drop-zone-icon">
                <mat-icon>cloud_upload</mat-icon>
                <p>Drag your files or click to upload</p>
            </div>
            <input type="file" (change)="onAppFileSelected($event, selectedApp)" #fileUpload accept=".csv"
                style="display:none;">
        </div>
    </div>
</div>