import { Component,ViewChild, OnInit } from '@angular/core';
import {ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexTitleSubtitle,
  ApexStroke, ApexGrid, ApexNonAxisChartSeries,ApexResponsive, ApexPlotOptions, ApexYAxis, ApexFill, ApexLegend} from "ng-apexcharts";
import { Groups } from 'src/app/models/groups.model';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { StadisticService } from '../stadistic.service';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { StadisticReportTMS } from 'src/app/models/StadisticReportTMS.model';
import { COLOR_TIPE_CARD, COMM_REPORT, TICKET, TIPE_CARD } from 'src/app/util/StadisticUtil';
import { ChartOptions } from 'chart.js';
import { DataPeriod } from 'src/app/models/dataPeriod.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { NotifierService } from 'angular-notifier';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue } from 'src/app/models/FilterAndPagBean';

export type chartOptions_reader = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  stroke: ApexStroke;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  colors: string[];
  fill: ApexFill;
  legend: ApexLegend;
};

export type chartOptions_aveTime = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
};


export type chartOptions_transaction = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  colors: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};
export type chartOptions_consuption = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  colors: string[];
  legend: ApexLegend;
  fill: ApexFill;
};
export type chartOptions_hostComm = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  colors: string[];
  legend: ApexLegend;
  fill: ApexFill;
};
export type chartOptions_ticketPinting = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
export type chartOptions_accepted = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  colors: string[];
  responsive: ApexResponsive[];
  labels: any;
};

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'commerce', type:'string' },
  { field:'date', type:'date' },
]

@Component({
  selector: 'app-stadistic-functionally',
  templateUrl: './stadistic-functionally.component.html',
  styleUrls: ['./stadistic-functionally.component.css']
})
export class StadisticFunctionallyComponent implements OnInit {
  @ViewChild("reader")  reader!: ChartComponent; 
  @ViewChild("ticketPinting")  ticketPinting!: ChartComponent; 
  @ViewChild("hostComm")  hostComm!: ChartComponent; 
  @ViewChild("consuption")  consuption!: ChartComponent; 
  @ViewChild("aveTime")  aveTime!: ChartComponent; 
  @ViewChild("accepted")  accepted!: ChartComponent; 
  @ViewChild("transaction")  transaction!: ChartComponent; 
  public chartOptions_reader: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};
  public chartOptions_ticketPinting: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_hostComm: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_consuption: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_aveTime: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_accepted: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_transaction: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};

  
  ///filtros
  filterColumns = FILTER_COLUMNS.map((filter)=>{
    return new FilterField(filter.field, `stadisticsFunctionally.${filter.field}`, filter.type);
  })
  filterList: FilterableValue[] = [];

  isAdmin:boolean = true;
  hashLecturas = new Map()
  hashComunication = new Map()
  hashTiempoMedio = new Map()
  hashTicket = new Map()
  hashOperationOK = new Map()
  hashOperation = new Map()
  hashData = new Map()

  constructor(  private utilServicios : UtilService,  
    private terminalService: MonitoringService, private authService:AuthService, private notifier: NotifierService,
    private stadisticService :StadisticService) { 
  }

  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada(){
    const request = new StadisticBean;
    const selectedGro = this.getFilterableValue('idGroup');
    if (selectedGro) request.idGroup = selectedGro.value as number;
    
    const selectedOpe = this.getFilterableValue('idOperator');
    if (selectedOpe) request.idOperator = selectedOpe.value as number;

    const selectedTerminal = this.getFilterableValue('idVendingMachine');
    if (selectedTerminal) request.idVendingMachine = selectedTerminal.value as number;

    const commerceFilter = this.getFilterableValue('commerce');
    if (commerceFilter) request.commerce = commerceFilter.value as string;

    request.typeReport=50//valor fijo para graficas del TMS
    let userSession = this.authService.currentUserValue();
    request.idEntorno = userSession!.entorno!.id

    this.setDateFilter(request);

    let value = await this.stadisticService.find(request);
    console.log(JSON.stringify(value))
    if(value){
      this.procesarDatos(value.data, request.dateIni, request.dateEnd)
    }
  }

  setDateFilter(request:StadisticBean){
    this.filterList.forEach((filter)=>{
      if(filter.column === 'date'){
        if(filter.operation === 'LESS_THAN_OR_EQUAL') request.dateEnd = new Date(filter.value as number);
        if(filter.operation === 'GREATER_THAN_OR_EQUAL') request.dateIni = new Date(filter.value as number);
      }
    });
    request.dateIni = request.dateIni ?? (request.dateEnd ? moment(request.dateEnd).startOf("month") : moment(new Date()).date(1));
    request.dateEnd = request.dateEnd ?? moment(request.dateIni).endOf("month").endOf("day").toDate();
  }

  async procesarDatos(stadisticResult:StadisticReportTMS, dateIni:Date, dateEnd:Date){   
    let fecha1 = moment(dateIni);
    let fecha2 = moment(dateEnd);  
    let diff = fecha2.diff(fecha1, 'days');

    let ticketNO=0;
    let ticketSMS=0;
    let ticketMail=0;
    let ticketPrint=0;
    
    let opChip=0;
    let opCless=0;
    let opClick=0;
    let opBand=0;
    

    this.hashLecturas = new Map()
    this.hashComunication = new Map()
    this.hashTiempoMedio = new Map()
    this.hashTicket = new Map()
    this.hashOperationOK = new Map()
    this.hashOperation = new Map()  
    this.hashData = new Map()

    for (let i = 0; i <= diff; i++) {
      let fechaTemp = moment(dateIni);
        fechaTemp.add(i, 'days');

        let punto = fechaTemp.format('DD-MM-YYYY');
        let valueLectura = new Map()
        valueLectura.set(TIPE_CARD.TIPE_BAND_OK, 0);
        valueLectura.set(TIPE_CARD.TIPE_CHIP_OK, 0);
        valueLectura.set(TIPE_CARD.TIPE_CLESS_OK, 0);
        valueLectura.set(TIPE_CARD.TIPE_BAND_KO, 0);
        valueLectura.set(TIPE_CARD.TIPE_CHIP_KO, 0);
        valueLectura.set(TIPE_CARD.TIPE_CLESS_KO, 0);
        this.hashLecturas.set(punto,valueLectura)

        let valueOperation = new Map()
        valueOperation.set(TIPE_CARD.TIPE_BAND_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_CHIP_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_CLESS_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_BAND_KO, 0);
        valueOperation.set(TIPE_CARD.TIPE_CHIP_KO, 0);
        valueOperation.set(TIPE_CARD.TIPE_CLESS_KO, 0);
        valueOperation.set(TIPE_CARD.TIPE_KEY_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_KEY_OK, 0);
        this.hashOperation.set(punto,valueOperation)

        this.hashData.set(punto,new DataPeriod())

        let valueComunicaciones = new Map()
        valueComunicaciones.set(COMM_REPORT.COMM_OK, null);
        valueComunicaciones.set(COMM_REPORT.COMM_KO, null);
        valueComunicaciones.set(COMM_REPORT.SEC_COMM_OK, null);
        valueComunicaciones.set(COMM_REPORT.SEC_COMM_KO, null);
        this.hashComunication.set(punto,valueComunicaciones)



        this.hashTiempoMedio.set(punto,0);
    }

    if(stadisticResult){
      let txData=0, rxData=0, dataTotal=0
      if(stadisticResult.ListInfoByDate){
        for (const entry of stadisticResult.ListInfoByDate) {
          ticketNO+=entry.ticketNo;
          ticketSMS+=entry.ticketSms;
          ticketMail+=entry.ticketMail;
          ticketPrint+=entry.ticketPrint;
          
          let key = entry.period.toString().substring(0,10)
          
  /////////////////////////////////////////////////////////////////////

          let valueLectura = new Map()
          let valueLecturaTEMP = this.hashLecturas.get(key)
              
          valueLectura.set(TIPE_CARD.TIPE_BAND_OK, entry.readBandaOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_OK));
          valueLectura.set(TIPE_CARD.TIPE_BAND_KO, entry.readBandaKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_KO));
          valueLectura.set(TIPE_CARD.TIPE_CHIP_OK, entry.readChipOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_OK));
          valueLectura.set(TIPE_CARD.TIPE_CHIP_KO, entry.readChipKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_KO));
          valueLectura.set(TIPE_CARD.TIPE_CLESS_OK, entry.readClessKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_OK));
          valueLectura.set(TIPE_CARD.TIPE_CLESS_KO, entry.readClessOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_KO));			
                

          this.hashLecturas.set(key, valueLectura);

  /////////////////////////////////////////////////////////////////////
          let valueComunicaciones = new Map()
          let valueComunicacionesTEMP = this.hashComunication.get(key)
          

          valueComunicaciones.set(COMM_REPORT.COMM_OK, entry.hostCommOk + valueComunicacionesTEMP.get(COMM_REPORT.COMM_OK));
          valueComunicaciones.set(COMM_REPORT.COMM_KO, entry.hostCommKo + valueComunicacionesTEMP.get(COMM_REPORT.COMM_KO));
          valueComunicaciones.set(COMM_REPORT.SEC_COMM_OK, entry.hostCommSecOk + valueComunicacionesTEMP.get(COMM_REPORT.SEC_COMM_OK));
          valueComunicaciones.set(COMM_REPORT.SEC_COMM_KO, entry.hostCommSecKo + valueComunicacionesTEMP.get(COMM_REPORT.SEC_COMM_KO));

          this.hashComunication.set(key, valueComunicaciones);

  /////////////////////////////////////////////////////////////////////

          let data = new DataPeriod()
          let dataTEMP = this.hashData.get(key)
        
          if(dataTEMP.txData){       txData=dataTEMP.txData         }
          if(dataTEMP.rxData){       rxData=dataTEMP.rxData         }
          if(dataTEMP.dataTotal){    dataTotal=dataTEMP.dataTotal   }

          data.period=entry.period;
          data.txData=entry.byteSend + txData;
          data.rxData=entry.byteRecive + rxData;
          data.dataTotal=entry.byteSend + entry.byteRecive + dataTotal;
          this.hashData.set(key,data);

  /////////////////////////////////////////////////////////////////////

          let avgTEMP = this.hashTiempoMedio.get(key)
          let avgNow = entry.averageOp
          if(!avgTEMP){ avgTEMP = 0 }
          if(!avgNow){ avgNow = 0 }
          let media=avgTEMP 
          let valTemp=0
          if(avgNow>0){ valTemp=valTemp+avgNow }
          if(avgTEMP>0){ valTemp=valTemp+avgTEMP }
          if(avgTEMP>0 && avgNow>0){
            media= (valTemp)/2
            media = Number(media.toFixed(2))
          }else{
            media =valTemp
          }
          console.log("key="+key+"->"+entry.period+" avgTEMP="+avgTEMP+" avgNow="+avgNow+" media="+media+"")
          this.hashTiempoMedio.set(key,media);

        }

        this.hashTicket.set(TICKET.NO, ticketNO);
        this.hashTicket.set(TICKET.SMS, ticketSMS);
        this.hashTicket.set(TICKET.MAIL, ticketMail);
        this.hashTicket.set(TICKET.PRINT, ticketPrint);
      }


      if(stadisticResult.ListInfoByHour){
        for (const entry of stadisticResult.ListInfoByHour) {
          
            let key = entry.period.toString().substring(0,10)

            opChip+=entry.opChipOk;
            opBand+=entry.opBandaOk;
            opCless+=entry.opClessOk;
            opClick+=entry.opKeyOk;    
            
            
            let valueLectura = new Map()
            let valueLecturaTEMP = this.hashOperation.get(key)
                
            valueLectura.set(TIPE_CARD.TIPE_BAND_OK, entry.opBandaOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_OK));
            valueLectura.set(TIPE_CARD.TIPE_BAND_KO, entry.opBandaKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_KO));
            valueLectura.set(TIPE_CARD.TIPE_CHIP_OK, entry.opChipOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_OK));
            valueLectura.set(TIPE_CARD.TIPE_CHIP_KO, entry.opChipKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_KO));
            valueLectura.set(TIPE_CARD.TIPE_CLESS_OK, entry.opClessOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_OK));
            valueLectura.set(TIPE_CARD.TIPE_CLESS_KO, entry.opClessKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_KO));			
            valueLectura.set(TIPE_CARD.TIPE_KEY_OK, entry.opKeyOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_KEY_OK));
            valueLectura.set(TIPE_CARD.TIPE_KEY_KO, entry.opKeyKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_KEY_KO));			
                      
            this.hashOperation.set(key, valueLectura);
        }

        this.hashOperationOK.set(TIPE_CARD.TIPE_CHIP_OK, opChip);
        this.hashOperationOK.set(TIPE_CARD.TIPE_BAND_OK, opBand);
        this.hashOperationOK.set(TIPE_CARD.TIPE_CLESS_OK, opCless);
        this. hashOperationOK.set(TIPE_CARD.TIPE_KEY_OK, opClick);  
        
        





      }


      this.constructorTicketPrinting();
      this.constructorReader();
      this.constructorHostComm();
      this.constructorConsuption();
      this.constructorAverageTime();
      this.constructorAccepted();
      this.constructorTrasanction();
    }

  }


  public filteredList1:any
  async cargar(){   
    //operadores
    this.utilServicios.findOperators().subscribe({
      next: (operators) => {
        const operationsOptions = operators.data.map((op:Operator)=>{ return { label:op.name, value:op.id }});
        this.filterColumns.push(new FilterField('idOperator', 'stadisticsFunctionally.idOperator', 'number', operationsOptions));
      }
    });
  }



  async ngOnInit(): Promise<void> {
    //Terminal
    let vmsList = await this.terminalService.find(new UsersConsoleRequest);
    const vmOptions = vmsList?.data.map((vm:VendingMachineAtento)=>{ return { label:vm.customId, value:vm.id }});
    this.filterColumns.push(new FilterField('idVendingMachine', 'stadisticsFunctionally.idVendingMachine', 'number', vmOptions));
  
    this.isAdmin = await this.utilServicios.isAdmin();
    if(this.isAdmin) await this.cargar();
    this.lanzarLLamada();
  }

  redimensionar(id:string) {
    let big = document.getElementById(id);
    if(big){
      let hasClase2 = big.classList.contains( 'graficaBig' );
    
        if(hasClase2){// si esta expandido lo contraemos

          for (let el of document.querySelectorAll<HTMLElement>('.grafica') ) {
            el.style.display = 'block';
            el.classList.remove("graficaBig");
          }

          for (let el of document.querySelectorAll<HTMLElement>('.expand') ) {
            el.style.display = 'block';
          }
          for (let el of document.querySelectorAll<HTMLElement>('.contract') ) {
            el.style.display = 'none';
          }

        }else{
            //agrandamos la grafica
            for (let el of document.querySelectorAll<HTMLElement>('.grafica') ) {
              el.style.display = 'none';
              el.classList.remove("graficaBig");
            }
            for (let el of document.querySelectorAll<HTMLElement>('.expand') ) {
              el.style.display = 'none';
            }
            for (let el of document.querySelectorAll<HTMLElement>('.contract') ) {
              el.style.display = 'block';
            }
        
            big.style.display = 'block';
            big.classList.add("graficaBig");            
        }
    }

    if(id == "_ticketPinting"){
      this.constructorTicketPrinting() 
    }else if(id == "_reader"){
      this.constructorReader() 
    }else if(id == "_hostComm"){
      this.constructorHostComm()
    }else if(id == "_consuption"){
      this.constructorConsuption()
    }else if(id == "_aveTime"){
      this.constructorAverageTime()
    }else if(id == "_accepted"){
      this.constructorAccepted()
    }else if(id == "_transaction"){
      this.constructorTrasanction()
    }
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.lanzarLLamada();
  }

  constructorAverageTime(){
    this.chartOptions_aveTime = {
      series: [
        {
          name: "Average time",
          data: Array.from(this.hashTiempoMedio.values())
        }
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },     
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      }, 
      yaxis: {
        labels: {
          formatter: (val:any) => {
            return val+"sec.";
          }
        }
      },
      xaxis: {
        categories: Array.from(this.hashTiempoMedio.keys())
      }
    };
  }

  constructorTrasanction(){
    let seriesTemp_TIPE_BAND_OK:any=[]
    let seriesTemp_TIPE_BAND_KO:any=[]
    let seriesTemp_TIPE_CHIP_OK:any=[]
    let seriesTemp_TIPE_CHIP_KO:any=[]
    let seriesTemp_TIPE_CLESS_OK:any=[]
    let seriesTemp_TIPE_CLESS_KO:any=[]
    let seriesTemp_TIPE_KEY_OK:any=[]
    let seriesTemp_TIPE_KEY_KO:any=[]

    for (let [_, valueLectura] of this.hashOperation) {
      
      let temp = valueLectura

        seriesTemp_TIPE_BAND_OK.push(temp.get(TIPE_CARD.TIPE_BAND_OK));
        seriesTemp_TIPE_BAND_KO.push(temp.get(TIPE_CARD.TIPE_BAND_KO));
        seriesTemp_TIPE_CHIP_OK.push(temp.get(TIPE_CARD.TIPE_CHIP_OK));
        seriesTemp_TIPE_CHIP_KO.push(temp.get(TIPE_CARD.TIPE_CHIP_KO));
        seriesTemp_TIPE_CLESS_OK.push(temp.get(TIPE_CARD.TIPE_CLESS_OK));
        seriesTemp_TIPE_CLESS_KO.push(temp.get(TIPE_CARD.TIPE_CLESS_KO));
        seriesTemp_TIPE_KEY_OK.push(temp.get(TIPE_CARD.TIPE_KEY_OK));
        seriesTemp_TIPE_KEY_KO.push(temp.get(TIPE_CARD.TIPE_KEY_KO));

    }

    this.chartOptions_transaction = {
      series: [
        {
          name: TIPE_CARD.TIPE_BAND_OK,
          data: seriesTemp_TIPE_BAND_OK
        },
        {
          name: TIPE_CARD.TIPE_BAND_KO,
          data: seriesTemp_TIPE_BAND_KO
        },
        {
          name: TIPE_CARD.TIPE_CHIP_OK,
          data: seriesTemp_TIPE_CHIP_OK
        },
        {
          name: TIPE_CARD.TIPE_CHIP_KO,
          data: seriesTemp_TIPE_CHIP_KO
        },
        {
          name: TIPE_CARD.TIPE_CLESS_OK,
          data: seriesTemp_TIPE_CLESS_OK
        },
        {
          name: TIPE_CARD.TIPE_CLESS_KO,
          data: seriesTemp_TIPE_CLESS_KO
        },
        {
          name: TIPE_CARD.TIPE_KEY_OK,
          data: seriesTemp_TIPE_KEY_OK
        },
        {
          name: TIPE_CARD.TIPE_KEY_KO,
          data: seriesTemp_TIPE_KEY_KO
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      }, 
      colors: [COLOR_TIPE_CARD.TIPE_BAND_OK,COLOR_TIPE_CARD.TIPE_BAND_KO,COLOR_TIPE_CARD.TIPE_CHIP_OK,COLOR_TIPE_CARD.TIPE_CHIP_KO,COLOR_TIPE_CARD.TIPE_CLESS_OK,COLOR_TIPE_CARD.TIPE_CLESS_KO,COLOR_TIPE_CARD.TIPE_KEY_OK,COLOR_TIPE_CARD.TIPE_KEY_KO],
          
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      }, 
      yaxis: {
        labels: {
          formatter: (val:any) => {
            return val;
          }
        }
      },
      xaxis: {
        categories: Array.from(this.hashOperation.keys())
      }
    };
  }

  constructorReader() {

    let seriesTemp_TIPE_BAND_OK:any=[]
    let seriesTemp_TIPE_BAND_KO:any=[]
    let seriesTemp_TIPE_CHIP_OK:any=[]
    let seriesTemp_TIPE_CHIP_KO:any=[]
    let seriesTemp_TIPE_CLESS_OK:any=[]
    let seriesTemp_TIPE_CLESS_KO:any=[]

    for (let [_, valueLectura] of this.hashLecturas) {
      
      let temp = valueLectura

        seriesTemp_TIPE_BAND_OK.push(temp.get(TIPE_CARD.TIPE_BAND_OK));
        seriesTemp_TIPE_BAND_KO.push(temp.get(TIPE_CARD.TIPE_BAND_KO));
        seriesTemp_TIPE_CHIP_OK.push(temp.get(TIPE_CARD.TIPE_CHIP_OK));
        seriesTemp_TIPE_CHIP_KO.push(temp.get(TIPE_CARD.TIPE_CHIP_KO));
        seriesTemp_TIPE_CLESS_OK.push(temp.get(TIPE_CARD.TIPE_CLESS_OK));
        seriesTemp_TIPE_CLESS_KO.push(temp.get(TIPE_CARD.TIPE_CLESS_KO));

    }

   

    this.chartOptions_reader = {
      series: [
        {
          name: TIPE_CARD.TIPE_BAND_OK,
          group: "1",
          data: seriesTemp_TIPE_BAND_OK
        },
        {
          name: TIPE_CARD.TIPE_BAND_KO,
          group: "1",
          data: seriesTemp_TIPE_BAND_KO
        },
        {
          name: TIPE_CARD.TIPE_CHIP_OK,
          group: "2",
          data: seriesTemp_TIPE_CHIP_OK
        },
        {
          name: TIPE_CARD.TIPE_CHIP_KO,
          group: "2",
          data: seriesTemp_TIPE_CHIP_KO
        },
        {
          name: TIPE_CARD.TIPE_CLESS_OK,
          group: "3",
          data: seriesTemp_TIPE_CLESS_OK
        },
        {
          name: TIPE_CARD.TIPE_CLESS_KO,
          group: "3",
          data: seriesTemp_TIPE_CLESS_KO
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true
      },
      stroke: {
        width: 2,
            },
      dataLabels: {
        enabled:false
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        categories: Array.from(this.hashLecturas.keys())
      },
      fill: {
        opacity: 1
      },
      colors: [COLOR_TIPE_CARD.TIPE_BAND_OK,COLOR_TIPE_CARD.TIPE_BAND_KO,COLOR_TIPE_CARD.TIPE_CHIP_OK,COLOR_TIPE_CARD.TIPE_CHIP_KO,COLOR_TIPE_CARD.TIPE_CLESS_OK,COLOR_TIPE_CARD.TIPE_CLESS_KO],
      yaxis: {
        labels: {
          formatter: (val:any) => {
            return val;
          }
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }
    };
  }

  constructorHostComm(){

    let seriesTemp_COMM_OK:any=[]
    let seriesTemp_COMM_KO:any=[]
    let seriesTemp_SEC_COMM_OK:any=[]
    let seriesTemp_SEC_COMM_KO:any=[]

    for (let [key, valueComunicaciones] of this.hashComunication) {
      let temp = new Map<String, Number>()
        temp = valueComunicaciones
        seriesTemp_COMM_OK.push(temp.get(COMM_REPORT.COMM_OK));
        seriesTemp_COMM_KO.push(temp.get(COMM_REPORT.COMM_KO));
        seriesTemp_SEC_COMM_OK.push(temp.get(COMM_REPORT.SEC_COMM_OK));
        seriesTemp_SEC_COMM_KO.push(temp.get(COMM_REPORT.SEC_COMM_KO));
    }
    


    this.chartOptions_hostComm = {
      series: [
        {
          name: COMM_REPORT.COMM_OK,
          data: seriesTemp_COMM_OK
        },
        {
          name: COMM_REPORT.SEC_COMM_OK,
          data: seriesTemp_SEC_COMM_OK
        },
        {
          name: COMM_REPORT.COMM_KO,
          data: seriesTemp_COMM_KO
        },
        {
          name: COMM_REPORT.SEC_COMM_KO,
          data: seriesTemp_SEC_COMM_KO
        }
      ],
      chart: {
        type: "area",
        height: 350,
        stacked: true,    
        zoom: {
          enabled: false
        }    
      },
      xaxis: {
        categories: Array.from(this.hashComunication.keys())
      },
     // colors: ["#008FFB", "#00E396", "#CED4DC"],
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }     
    };
  }

  constructorConsuption(){
    let seriesTemp_total:any=[]
    let seriesTemp_rd:any=[]
    let seriesTemp_wr:any=[]
    let seriesTemp_Eje:any=[]


    let dataTotalAnterior=0, rxDataAnterior=0, txDataAnterior=0

    for (let [key, valueCosumo] of this.hashData) {
      //console.log(JSON.stringify(valueCosumo))
      let temp = valueCosumo

        let dataTotal=0, rxData=0, txData=0
        if(temp.dataTotal){dataTotal=temp.dataTotal/1024/1024}
        if(temp.rxData){rxData=temp.rxData/1024/1024}
        if(temp.txData){txData=temp.txData/1024/1024}

        if(dataTotal==0){  dataTotal=dataTotalAnterior    }
        if(rxData==0){  rxData=rxDataAnterior    }
        if(txData==0){  txData=txDataAnterior    }

        seriesTemp_total.push(dataTotal.toFixed(2));
        seriesTemp_rd.push(rxData.toFixed(2));
        seriesTemp_wr.push(txData.toFixed(2));
   
        seriesTemp_Eje.push(key);


        dataTotalAnterior=dataTotal
        rxDataAnterior=rxData
        txDataAnterior=txData
    }
    

    this.chartOptions_consuption = {
      series: [
        {
          name: "Total (MB)",
          data: seriesTemp_total
        },
        {
          name: "Read",
          data: seriesTemp_rd
        },
        {
          name: "Sent",
          data: seriesTemp_wr
        },
      ],
      chart: {
        type: "area",
        height: 350,
        stacked: false,    
        zoom: {
          enabled: false
        }    
      },
      xaxis: {
        categories: seriesTemp_Eje
      },
     // colors: ["#008FFB", "#00E396", "#CED4DC"],
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (val:any) => {
            return val+"Mb";
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }     
    };
  }

  constructorTicketPrinting() {
    this.chartOptions_ticketPinting = {
      series: Array.from(this.hashTicket.values()),
      chart: {
        
        height: 380,
        type: "pie"
      },
      labels: Array.from(this.hashTicket.keys()),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  constructorAccepted() {
    this.chartOptions_accepted = {
      series: Array.from(this.hashOperationOK.values()),
      chart: {
        
        height: 380,
        type: "pie"
      },
      labels: Array.from(this.hashOperationOK.keys()),
      colors: [COLOR_TIPE_CARD.TIPE_CHIP_OK,COLOR_TIPE_CARD.TIPE_BAND_OK,COLOR_TIPE_CARD.TIPE_CLESS_OK,COLOR_TIPE_CARD.TIPE_KEY_OK],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

}




