import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UserHistory } from 'src/app/models/userHistory.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { HistoryUserService } from './historyUser.service';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';

const BaseColumns = {
  view: 'view',
  dateCreate: 'dateCreate',
  idVM: 'idVM',
  idUser: 'idUser',
  operation: 'operation',
  stringLog: 'stringLog'
};

const Columns = { ...BaseColumns };

const FILTER_COLUMNS:{ field:string, type: FieldType}[] = [
  { field:'operation', type:'string' },
  { field:'idVM', type:'number' },
  { field:'idUser', type:'number' },
  { field:'stringLog', type:'string' },
  { field:'dateCreate', type:'date' },
]

@Component({
  selector: 'app-history-user',
  templateUrl: './history-user.component.html',
  styleUrls: ['./history-user.component.css']
})
export class HistoryUserComponent implements OnInit, AfterViewInit {
  private dialogRef!: MatDialogRef<TabsComponent>;
  displayedColumns = Object.keys(Columns);
  dataSource = new MatTableDataSource<UserHistory>();
  searchTerminal = "";
  isAdmin: boolean = true;
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.dateCreate + "";
  direccion: string | null = "DESC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `historyUser.${column.field}`, column.type);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private servicios: HistoryUserService,
    public dialog: MatDialog,  
    public commonService: CommonService,
    private monitoringService: MonitoringService) {

  }

  async ngOnInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    await this.lanzarLLamada();
  }

      
  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const idVMFilter = this.getFilterableValue("idVM");
    if (this.data?.obj && !idVMFilter) {
      const idVM = this.data.obj.id;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", "EQUALS"));
    }
   
    const paginatorState = this.getPaginatorState();
    const request =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    if (value) {
      this.dataSource = new MatTableDataSource<UserHistory>(value.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".");
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase();
    } else {
      this.direccion = null;
      this.sortId = null;
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  async getMachine(row: any): Promise<any> {
    return await this.monitoringService.getMachine(row.idVM);
  }

  onView(row: any): void {
    this.getMachine(row).then(data => {
      this.dialogRef = this.dialog.open(TabsComponent, {
        width: '90%',
        height: '95%',
        panelClass: 'custom-modalbox-big',
        data: { obj: data.data }
      });

      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          console.log('CONFIRM received from dialog window');
        } else if (result === 0) {
          console.log('CANCEL received from dialog window');
        }
      });
    }).catch(error => {
      console.error('Error fetching data: ', error);
    });
  }
}
