
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { Incidence } from 'src/app/models/incidence.model';
import { IncidenceService } from '../incidence.service';
import moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { UtilService } from 'src/app/services/util.service';
import { Operator } from 'src/app/models/operator.model';

@Component({
  selector: 'app-form-new-incidence',
  templateUrl: './form-new-incidence.component.html',
  styleUrls: ['./form-new-incidence.component.css']
})
export class FormNewIncidenceComponent implements OnInit {

  isAdmin: boolean = true;
  registerForm!: FormGroup;
  disabledTerminal:boolean = false;
  isCloseIncidence:boolean = false;
  typeSelect!:string
  operatorList:Operator[] = [];

  private notifier: NotifierService;
  
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private popupDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,notifier: NotifierService ,
    private service: IncidenceService,
    private utilService : UtilService,
    public dialogRef: MatDialogRef<FormNewIncidenceComponent>,
    public dialog: MatDialog) { 
     this.notifier=notifier
  }

  async ngOnInit(): Promise<void> {


    let terminaID, type, commet, operator;
    if(this.data){
      if(this.data.close){
        this.isCloseIncidence=this.data.close
      }

      if(this.data.vm){
        terminaID=this.data.vm.customId
      }
      if(this.data.incidencia){
        type=this.data.incidencia.type+""
        commet=this.data.incidencia.description
        terminaID=this.data.incidencia.customID
        operator=this.data.incidencia.idOperator
      }
    }
    
    if(terminaID){
      this.disabledTerminal=true
    }
    this.isAdmin = this.utilService.isAdmin();
    if(this.isAdmin){
      this.registerForm = this.formBuilder.group({
        type: [{value:type, disabled:this.isCloseIncidence}, Validators.compose([Validators.required])] ,
        numTer:[{value: terminaID, disabled:this.disabledTerminal}, Validators.compose([Validators.required])], 
        commet:[{value:commet, disabled:this.isCloseIncidence}, Validators.compose([Validators.required])],
        resolution:[],
        operator:[{value:operator, disabled:this.isCloseIncidence},Validators.required]    });
    }else{
        this.registerForm = this.formBuilder.group({
          type: [{value:type, disabled:this.isCloseIncidence}, Validators.compose([Validators.required])] ,
          numTer:[{value: terminaID, disabled:this.disabledTerminal}, Validators.compose([Validators.required])], 
          commet:[{value:commet, disabled:this.isCloseIncidence}, Validators.compose([Validators.required])],
          resolution:[],
          operator:[{value:this.utilService.getOperatorId(), disabled: true},Validators.required]    });
    }

  
    
    if(this.data.close){// si se quiere cerrar la incidencia hay que escribir un comentario
      this.registerForm.get('resolution')?.addValidators(Validators.required);  
    }

    if(type){
       this.registerForm.get('type')?.setValue(type)
    }
    this.utilService.findOperators().subscribe({
      next: (operators) => {
        this.operatorList = operators.data;
      },
      error: (err) => {
        console.error('Failed to load operators:', err);
      },
      complete: () => console.log('Operator loading completed.')
    });
      }
    




  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }  
    const data = this.applyFormats(this.registerForm);
    let objNew : Incidence = new Incidence();
    
    if(this.data){
        if(this.data.incidencia){
          objNew=this.data.incidencia
            //hay que mapear las fechas para que el BACK las entienda  
            if(this.data.incidencia.dateCreate){  objNew.dateCreate = moment(this.data.incidencia.dateCreate, "DD-MM-YYYY HH:mm:ss").toDate();  }
            if(this.data.incidencia.dateDelete){  objNew.dateDelete = moment(this.data.incidencia.dateDelete, "DD-MM-YYYY HH:mm:ss").toDate();  }
        }
        if(this.data.vm){
          objNew.idOperator =this.data.vm.idOperator
        }
    }

    if(this.isCloseIncidence){
        objNew.resolution=data['resolution']        
    }else{
        objNew.description=data['commet']  
        objNew.customID=this.registerForm.controls['numTer'].value
        objNew.type=data['type']   
    }

    if(this.data.vm){
        objNew.customID=this.data.vm.customId
    }


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Do Ticket`;
    dialogData.textI18n = $localize `Are you sure you want to do this ticket?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val:any;

          if(this.isCloseIncidence){
            val = await this.service.closeIncidence(objNew)
          }else{
            objNew.idOperator = this.registerForm.controls['operator'].value
            val = await this.service.addUpdate(objNew)
          }

          if(val!.status>=0){
            this.dialogRef.close(FormCreateActions.SAVED);
          }

      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }


  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }



}
